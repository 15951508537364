import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/facilityMember/User';
import NotFound from './pages/Page404';
import UpdateUser from './pages/UpdateUser';
import Stories from './pages/Stories';
import StoryDetail from './components/_dashboard/stories/StoryDetail';
import NoticeBoard from './pages/noticeboard/NoticeBoard';
import Forgot from './pages/Forgot';
import ChangePassword from './pages/ChangePassword';
import Facilities, { addFacility } from './pages/Facilities';
import AddFacilityMember from './pages/facilityMember/AddFacilityMember';
import Residents from './pages/residents/Residents';
import Rooms from './pages/room/Rooms';
import AddRoom from './pages/room/AddRoom';
import AddResidents from './pages/residents/AddResidents';
import AddNotice from './pages/noticeboard/AddNotice';
import UpdateFacilityMember from './pages/facilityMember/UpdateFacilityMember';
import UpdateRoom from './pages/room/UpdateRoom';
import UpdateResidents from './pages/residents/UpdateResidents';
import UpdateNotice from './pages/noticeboard/UpdateNotice';
import Events from './pages/events/Events';
import AddEvent from './pages/events/AddEvent';
import UpdateEvent from './pages/events/UpdateEvent';
import EventDetail from './pages/events/EventDetail';
import AddStory from './pages/AddStory';
import UpdateStory from './pages/UpdateStory';
import FamilyMembers from './pages/familyMembers/FamilyMembers';
import AddFamilyMember from './pages/familyMembers/AddFamilyMember';
import UpdateFamilyMembers from './pages/familyMembers/UpdateFamilyMembers';
import ChatApp from './pages/chat/Chat';
import Profile from './pages/Profile';
import UpdateProfile from './pages/UpdateProfile';
import MoreInfoPage from './pages/facilities/MoreInfoPage';
import Drafts from './pages/Drafts/Drafts';
import DeletedEvents from './pages/events/DeletedEvents';
import DeletedEventDetail from './pages/events/DeletedEventDetail';
import DeletedStories from './pages/DeletedStories';
import NoticeDetail from './pages/noticeboard/NoticeDetail';
import DeletedNotice from './pages/noticeboard/DeletedNotice';
import DeletedResidents from './pages/residents/DeletedResidents';
import DeletedRooms from './pages/room/DeletedRooms';
import DeletedFacilities from './pages/DeletedFacilities';
import DeletedDrafts from './pages/Drafts/DeletedDrafts';
import DeletedUsers from './pages/facilityMember/DeletedUsers';
import Wellness from './pages/Wellbeing/Wellness';
import AddWellness from './pages/Wellbeing/AddWellness';
import UpdateWellness from './pages/Wellbeing/UpdateWellness';
import DeletedWellness from './pages/Wellbeing/DeletedWellness';
import WellnessDetail from './pages/Wellbeing/WellnessDetail';
import FamilyInfoPage from './pages/familyMembers/FamilyInfoPage';
import FacilityInfoPage from './pages/facilityMember/FacilityInfoPage';
import ResidentInfoPage from './pages/residents/ResidentInfoPage';
import DeletedStoryDetail from './components/_dashboard/stories/DeletedStoryDetail';
import DeletedNoticeDetail from './pages/noticeboard/DeletedNoticeDetail';
import DeletedWellnessDetail from './pages/Wellbeing/DeletedWellnessDetail';
import Organisation from './pages/organisation/Organisation';
import AddOrganisation from './pages/organisation/AddOrganisation';
import MoreInfoPageOrganization from './pages/organisation/MoreInfoPageOrganization';
import AddDocument from './pages/ClientDocuments/AddDocument';
import Documents from './pages/ClientDocuments/Documents';
import UpdateDocument from './pages/ClientDocuments/UpdateDocument';
import DocumentDetails from './pages/ClientDocuments/DocumentDetails';
import DeletedDocuments from './pages/ClientDocuments/DeletedDocuments';
import DeletedDocumentDetails from './pages/ClientDocuments/DeletedDocumentDetails';

// ----------------------------------------------------------------------
const user = JSON.parse(localStorage.getItem('Rel8User'));
const loggedUser = user?.id;
const isLoggedIn = true;
export default function Router() {
  return useRoutes([
    {
      path: '/home',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/home/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'staff-members', element: <User /> },
        { path: 'update-facility-member', element: <UpdateFacilityMember /> },
        { path: 'family-members', element: <FamilyMembers /> },
        { path: 'add-family-member', element: <AddFamilyMember /> },
        { path: 'update-family-member', element: <UpdateFamilyMembers /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'groups/register', element: <Register /> },
        { path: 'groups/update', element: <UpdateUser /> },
        { path: 'stories', element: <Stories /> },
        { path: 'stories/add-story', element: <AddStory /> },
        { path: 'stories/story-detail', element: <StoryDetail /> },
        { path: 'stories/update-story', element: <UpdateStory /> },
        { path: 'noticeboard', element: <NoticeBoard /> },
        { path: 'notice-board/add-notice-board', element: <AddNotice /> },
        { path: 'notice-board/update-notice', element: <UpdateNotice /> },
        { path: 'notice-board/notice-detail', element: <NoticeDetail /> },
        { path: 'change-password', element: <ChangePassword /> },
        { path: 'groups', element: <Facilities /> },
        { path: 'add-facility-member', element: <AddFacilityMember /> },
        // loggedUser &&
        //   loggedUser === '5dMp1STFqHbaqD2L2N37pWVTAcL2' && {
        //     path: 'facilities',
        //     element: <Facilities />
        //   },
        // loggedUser &&
        //   loggedUser === '5dMp1STFqHbaqD2L2N37pWVTAcL2' && {
        //     path: 'add-facility-member',
        //     element: <AddFacilityMember />
        //   },
        { path: 'locations', element: <Rooms /> },
        { path: 'locations/add-room', element: <AddRoom /> },
        { path: 'locations/update-room', element: <UpdateRoom /> },
        { path: 'clients', element: <Residents /> },
        { path: 'add-residents', element: <AddResidents /> },
        { path: 'update-residents', element: <UpdateResidents /> },
        { path: 'events', element: <Events /> },
        { path: 'events/add-event', element: <AddEvent /> },
        { path: 'events/update-event', element: <UpdateEvent /> },
        { path: 'events/event-detail', element: <EventDetail /> },
        { path: 'messages', element: <ChatApp /> },
        { path: 'profile', element: <Profile /> },
        { path: 'profile/update', element: <UpdateProfile /> },
        { path: 'groups/facility-more-info', element: <MoreInfoPage /> },
        { path: 'family-more-info', element: <FamilyInfoPage /> },
        { path: 'facility-member-more-info', element: <FacilityInfoPage /> },
        { path: 'resident-more-info', element: <ResidentInfoPage /> },
        { path: 'drafts', element: <Drafts /> },
        { path: 'wellness', element: <Wellness /> },
        { path: 'wellness/add-wellness', element: <AddWellness /> },
        { path: 'wellness/update-wellness', element: <UpdateWellness /> },
        { path: 'wellness/wellness-detail', element: <WellnessDetail /> },
        { path: 'organisation', element: <Organisation /> },
        { path: 'organisation/add_organisation', element: <AddOrganisation /> },
        { path: 'organisation/more_detail', element: <MoreInfoPageOrganization /> },
        // { path: 'add-documents', element: <AddDocument /> }
      ]
    },

    // -----------------------------------------------
    //               Administration routes
    // -----------------------------------------------
    {
      path: '/administration',
      element: <DashboardLayout />,
      children: [
        { path: 'staff-members', element: <User /> },
        { path: 'staff-members/update-facility-member', element: <UpdateFacilityMember /> },
        { path: 'staff-members/add-facility-member', element: <AddFacilityMember /> },
        { path: 'staff-members/facility-member-more-info', element: <FacilityInfoPage /> },


        { path: 'family-members', element: <FamilyMembers /> },
        { path: 'family-members/add-family-member', element: <AddFamilyMember /> },
        { path: 'family-members/update-family-member', element: <UpdateFamilyMembers /> },
        { path: 'family-members/family-more-info', element: <FamilyInfoPage /> },

        { path: 'clients', element: <Residents /> },
        { path: 'clients/add-residents', element: <AddResidents /> },
        { path: 'clients/update-residents', element: <UpdateResidents /> },
        { path: 'clients/resident-more-info', element: <ResidentInfoPage /> },

      ]
    },

    // -----------------------------------------------
    //               Organisation routes
    // -----------------------------------------------
    {
      path: '/organisation',
      element: <DashboardLayout />,
      children: [
        { path: 'organisation', element: <Organisation /> },
        { path: 'organisation/add_organisation', element: <AddOrganisation /> },
        { path: 'organisation/more_detail', element: <MoreInfoPageOrganization /> },

        { path: 'groups', element: <Facilities /> },
        { path: 'groups/register', element: <Register /> },
        { path: 'groups/update', element: <UpdateUser /> },
        { path: 'groups/facility-more-info', element: <MoreInfoPage /> },


        { path: 'locations', element: <Rooms /> },
        { path: 'locations/add-room', element: <AddRoom /> },
        { path: 'locations/update-room', element: <UpdateRoom /> },
      ]
    },

    // -----------------------------------------------
    //               Organisation routes
    // -----------------------------------------------
    {
      path: '/content',
      element: <DashboardLayout />,
      children: [
        { path: 'stories', element: <Stories /> },
        { path: 'stories/add-story', element: <AddStory /> },
        { path: 'stories/story-detail', element: <StoryDetail /> },
        { path: 'stories/update-story', element: <UpdateStory /> },

        { path: 'noticeboard', element: <NoticeBoard /> },
        { path: 'noticeboard/add-notice-board', element: <AddNotice /> },
        { path: 'noticeboard/update-notice', element: <UpdateNotice /> },
        { path: 'noticeboard/notice-detail', element: <NoticeDetail /> },

        { path: 'events', element: <Events /> },
        { path: 'events/add-event', element: <AddEvent /> },
        { path: 'events/update-event', element: <UpdateEvent /> },
        { path: 'events/event-detail', element: <EventDetail /> },

        { path: 'wellness', element: <Wellness /> },
        { path: 'wellness/add-wellness', element: <AddWellness /> },
        { path: 'wellness/update-wellness', element: <UpdateWellness /> },
        { path: 'wellness/wellness-detail', element: <WellnessDetail /> },
        { path: 'documents', element: <Documents /> },
        { path: 'document/add-document', element: <AddDocument /> },
        { path: 'document/update-document', element: <UpdateDocument /> },
        { path: 'document/document-details', element: <DocumentDetails /> }
      ]
    },
    // -----------------------------------------------
    //               recycle bin routes
    // -----------------------------------------------
    {
      path: '/recycleBin',
      element: <DashboardLayout />,
      children: [
        { path: 'deleted-events', element: <DeletedEvents /> },
        { path: 'deleted-events-details', element: <DeletedEventDetail /> },
        { path: 'deleted-stories', element: <DeletedStories /> },
        { path: 'deleted-stories-details', element: <DeletedStoryDetail /> },
        { path: 'deleted-notice', element: <DeletedNotice /> },
        { path: 'deleted-notice-details', element: <DeletedNoticeDetail /> },
        { path: 'deleted-Residents', element: <DeletedResidents /> },
        // { path: 'deleted-rooms', element: <DeletedRooms /> },
        { path: 'deleted-groups', element: <DeletedFacilities /> },
        { path: 'deleted-drafts', element: <DeletedDrafts /> },
        { path: 'deleted-users', element: <DeletedUsers /> },
        { path: 'deleted-wellness', element: <DeletedWellness /> },
        { path: 'deleted-wellness-details', element: <DeletedWellnessDetail /> },
        { path: 'deleted-documents', element: <DeletedDocuments /> },
        { path: 'deleted-document-details', element: <DeletedDocumentDetails /> }
      ]
    },
    // -----------------------------------------------
    //               recycle bin routes
    // -----------------------------------------------

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgot', element: <Forgot /> },
        { path: 'email-sent', element: <Forgot /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/home" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// for Facility Routes
// for Facility Routes
// for Facility Routes
// for Facility Routes
// for Facility Routes
export function RouterFacility() {
  return useRoutes([
    {
      path: '/home',
      element: <DashboardLayout />,

      children: [
        { path: '', element: <Navigate to="/home/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'staff-members', element: <User /> },
        { path: 'update-facility-member', element: <UpdateFacilityMember /> },
        { path: 'family-members', element: <FamilyMembers /> },
        { path: 'add-family-member', element: <AddFamilyMember /> },
        { path: 'update-family-member', element: <UpdateFamilyMembers /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        // { path: 'register', element: <Register /> },
        // { path: 'update', element: <UpdateUser /> },
        { path: 'stories', element: <Stories /> },
        { path: 'stories/add-story', element: <AddStory /> },
        { path: 'stories/story-detail', element: <StoryDetail /> },
        { path: 'stories/update-story', element: <UpdateStory /> },
        { path: 'noticeboard', element: <NoticeBoard /> },
        { path: 'notice-board/add-notice-board', element: <AddNotice /> },
        { path: 'notice-board/update-notice', element: <UpdateNotice /> },
        { path: 'notice-board/notice-detail', element: <NoticeDetail /> },
        { path: 'change-password', element: <ChangePassword /> },
        { path: 'groups', element: <Facilities /> },
        { path: 'add-facility-member', element: <AddFacilityMember /> },
        { path: 'locations', element: <Rooms /> },
        { path: 'add-room', element: <AddRoom /> },
        { path: 'update-room', element: <UpdateRoom /> },
        { path: 'clients', element: <Residents /> },
        { path: 'add-residents', element: <AddResidents /> },
        { path: 'update-residents', element: <UpdateResidents /> },
        { path: 'events', element: <Events /> },
        { path: 'events/add-event', element: <AddEvent /> },
        { path: 'events/update-event', element: <UpdateEvent /> },
        { path: 'events/event-detail', element: <EventDetail /> },
        { path: 'messages', element: <ChatApp /> },
        { path: 'profile', element: <Profile /> },
        { path: 'profile/update', element: <UpdateProfile /> },
        { path: 'facility-more-info', element: <MoreInfoPage /> },
        { path: 'drafts', element: <Drafts /> },
        // { path: 'deleted-events', element: <DeletedEvents /> },
        // { path: 'deleted-stories', element: <DeletedStories /> },
        // { path: 'deleted-notice', element: <DeletedNotice /> },
        // { path: 'deleted-Residents', element: <DeletedResidents /> },
        // // { path: 'deleted-rooms', element: <DeletedRooms /> },
        // { path: 'deleted-groups', element: <DeletedFacilities /> },
        // { path: 'deleted-drafts', element: <DeletedDrafts /> },
        // { path: 'deleted-users', element: <DeletedUsers /> },
        { path: 'wellness', element: <Wellness /> },
        { path: 'wellness/add-wellness', element: <AddWellness /> },
        { path: 'wellness/update-wellness', element: <UpdateWellness /> },
        { path: 'wellness/wellness-detail', element: <WellnessDetail /> },
        // { path: 'deleted-wellness', element: <DeletedWellness /> },
        { path: 'family-more-info', element: <FamilyInfoPage /> },
        { path: 'facility-member-more-info', element: <FacilityInfoPage /> },
        { path: 'resident-more-info', element: <ResidentInfoPage /> },
        { path: 'organisation', element: <Organisation /> },
      ]
    },



    // -----------------------------------------------
    //               Administration routes
    // -----------------------------------------------
    {
      path: '/administration',
      element: <DashboardLayout />,
      children: [
        { path: 'staff-members', element: <User /> },
        { path: 'staff-members/update-facility-member', element: <UpdateFacilityMember /> },
        { path: 'staff-members/add-facility-member', element: <AddFacilityMember /> },
        { path: 'staff-members/facility-member-more-info', element: <FacilityInfoPage /> },


        { path: 'family-members', element: <FamilyMembers /> },
        { path: 'family-members/add-family-member', element: <AddFamilyMember /> },
        { path: 'family-members/update-family-member', element: <UpdateFamilyMembers /> },
        { path: 'family-members/family-more-info', element: <FamilyInfoPage /> },

        { path: 'clients', element: <Residents /> },
        { path: 'clients/add-residents', element: <AddResidents /> },
        { path: 'clients/update-residents', element: <UpdateResidents /> },
        { path: 'clients/resident-more-info', element: <ResidentInfoPage /> },

      ]
    },

    // -----------------------------------------------
    //               Organisation routes
    // -----------------------------------------------
    {
      path: '/organisation',
      element: <DashboardLayout />,
      children: [
        { path: 'organisation', element: <Organisation /> },
        { path: 'organisation/add_organisation', element: <AddOrganisation /> },
        { path: 'organisation/more_detail', element: <MoreInfoPageOrganization /> },

        { path: 'groups', element: <Facilities /> },
        { path: 'groups/register', element: <Register /> },
        { path: 'groups/update', element: <UpdateUser /> },
        { path: 'groups/facility-more-info', element: <MoreInfoPage /> },


        { path: 'locations', element: <Rooms /> },
        { path: 'locations/add-room', element: <AddRoom /> },
        { path: 'locations/update-room', element: <UpdateRoom /> },
      ]
    },

    // -----------------------------------------------
    //               Content routes
    // -----------------------------------------------
    {
      path: '/content',
      element: <DashboardLayout />,
      children: [
        { path: 'stories', element: <Stories /> },
        { path: 'stories/add-story', element: <AddStory /> },
        { path: 'stories/story-detail', element: <StoryDetail /> },
        { path: 'stories/update-story', element: <UpdateStory /> },


        { path: 'noticeboard', element: <NoticeBoard /> },
        { path: 'noticeboard/add-notice-board', element: <AddNotice /> },
        { path: 'noticeboard/update-notice', element: <UpdateNotice /> },
        { path: 'noticeboard/notice-detail', element: <NoticeDetail /> },


        { path: 'events', element: <Events /> },
        { path: 'events/add-event', element: <AddEvent /> },
        { path: 'events/update-event', element: <UpdateEvent /> },
        { path: 'events/event-detail', element: <EventDetail /> },

        { path: 'wellness', element: <Wellness /> },
        { path: 'wellness/add-wellness', element: <AddWellness /> },
        { path: 'wellness/update-wellness', element: <UpdateWellness /> },
        { path: 'wellness/wellness-detail', element: <WellnessDetail /> },
        { path: 'documents', element: <Documents /> },
        { path: 'document/add-document', element: <AddDocument /> },
        { path: 'document/update-document', element: <UpdateDocument /> },
        { path: 'document/document-details', element: <DocumentDetails /> }
      ]
    },
    // -----------------------------------------------
    //               recycle bin routes
    // -----------------------------------------------
    {
      path: '/recycleBin',
      element: <DashboardLayout />,
      children: [
        { path: 'deleted-events', element: <DeletedEvents /> },
        { path: 'deleted-events-details', element: <DeletedEventDetail /> },
        { path: 'deleted-stories', element: <DeletedStories /> },
        { path: 'deleted-stories-details', element: <DeletedStoryDetail /> },
        { path: 'deleted-notice', element: <DeletedNotice /> },
        { path: 'deleted-notice-details', element: <DeletedNoticeDetail /> },
        { path: 'deleted-Residents', element: <DeletedResidents /> },
        // { path: 'deleted-rooms', element: <DeletedRooms /> },
        { path: 'deleted-groups', element: <DeletedFacilities /> },
        { path: 'deleted-drafts', element: <DeletedDrafts /> },
        { path: 'deleted-users', element: <DeletedUsers /> },
        { path: 'deleted-wellness', element: <DeletedWellness /> },
        { path: 'deleted-wellness-details', element: <DeletedWellnessDetail /> },
        { path: 'deleted-documents', element: <DeletedDocuments /> },
        { path: 'deleted-document-details', element: <DeletedDocumentDetails /> }
      ]
    },
    // -----------------------------------------------
    //               recycle bin routes
    // -----------------------------------------------
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgot', element: <Forgot /> },
        { path: 'email-sent', element: <Forgot /> },
        // { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/home" /> },
        // { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    // { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// for family member
// for family member
// for family member
// for family member
// for family member
// for family member
// for family member
export function RouterFamily() {
  return useRoutes([
    {
      path: '/home',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/home/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'staff-members', element: <User /> },
        // { path: 'update-facility-member', element: <UpdateFacilityMember /> },
        { path: 'family-members', element: <FamilyMembers /> },
        { path: 'family-more-info', element: <FamilyInfoPage /> },
        // { path: 'add-family-member', element: <AddFamilyMember /> },
        // { path: 'update-family-member', element: <UpdateFamilyMembers /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        // { path: 'register', element: <Register /> },
        // { path: 'update', element: <UpdateUser /> },
        { path: 'stories', element: <Stories /> },
        // { path: 'add-story', element: <AddStory /> },
        { path: 'stories/story-detail', element: <StoryDetail /> },
        // { path: 'update-story', element: <UpdateStory /> },
        { path: 'noticeboard', element: <NoticeBoard /> },
        // { path: 'add-notice-board', element: <AddNotice /> },
        // { path: 'update-notice', element: <UpdateNotice /> },
        { path: 'notice-board/notice-detail', element: <NoticeDetail /> },
        { path: 'change-password', element: <ChangePassword /> },
        { path: 'groups', element: <Facilities /> },
        // { path: 'add-facility-member', element: <AddFacilityMember /> },
        { path: 'locations', element: <Rooms /> },
        // { path: 'add-room', element: <AddRoom /> },
        // { path: 'update-room', element: <UpdateRoom /> },
        { path: 'clients', element: <Residents /> },
        // { path: 'add-residents', element: <AddResidents /> },
        // { path: 'update-residents', element: <UpdateResidents /> },
        { path: 'events', element: <Events /> },
        // { path: 'add-event', element: <AddEvent /> },
        // { path: 'update-event', element: <UpdateEvent /> },
        { path: 'events/event-detail', element: <EventDetail /> },
        { path: 'messages', element: <ChatApp /> },
        { path: 'profile', element: <Profile /> },
        { path: 'facility-more-info', element: <MoreInfoPage /> },
        // { path: 'drafts', element: <Drafts /> },
        // { path: 'deleted-events', element: <DeletedEvents /> },
        // { path: 'deleted-stories', element: <DeletedStories /> },
        // { path: 'deleted-notice', element: <DeletedNotice /> },
        // { path: 'deleted-Residents', element: <DeletedResidents /> },
        // { path: 'deleted-rooms', element: <DeletedRooms /> },
        // { path: 'deleted-groups', element: <DeletedFacilities /> },
        // { path: 'deleted-drafts', element: <DeletedDrafts /> },
        // { path: 'deleted-users', element: <DeletedUsers /> },
        { path: 'wellness', element: <Wellness /> },
        // { path: 'add-wellness', element: <AddWellness /> },
        // { path: 'update-wellness', element: <UpdateWellness /> },
        // { path: 'deleted-wellness', element: <DeletedWellness /> },
        { path: 'wellness/wellness-detail', element: <WellnessDetail /> },
        { path: 'family-more-info', element: <FamilyInfoPage /> },
        { path: 'facility-member-more-info', element: <FacilityInfoPage /> },
        { path: 'resident-more-info', element: <ResidentInfoPage /> },

      ]
    },



    // -----------------------------------------------
    //               Administration routes
    // -----------------------------------------------
    {
      path: '/administration',
      element: <DashboardLayout />,
      children: [
        { path: 'staff-members', element: <User /> },
        { path: 'staff-members/facility-member-more-info', element: <FacilityInfoPage /> },

        { path: 'family-members', element: <FamilyMembers /> },
        { path: 'family-members/family-more-info', element: <FamilyInfoPage /> },

        { path: 'clients', element: <Residents /> },
        { path: 'clients/resident-more-info', element: <ResidentInfoPage /> },

      ]
    },



    // -----------------------------------------------
    //               Content routes
    // -----------------------------------------------
    {
      path: '/content',
      element: <DashboardLayout />,
      children: [
        { path: 'stories', element: <Stories /> },
        { path: 'stories/story-detail', element: <StoryDetail /> },

        { path: 'noticeboard', element: <NoticeBoard /> },
        { path: 'noticeboard/notice-detail', element: <NoticeDetail /> },

        { path: 'events', element: <Events /> },
        { path: 'events/event-detail', element: <EventDetail /> },

        { path: 'wellness', element: <Wellness /> },
        { path: 'wellness/wellness-detail', element: <WellnessDetail /> },

        { path: 'documents', element: <Documents /> },
        { path: 'document/document-details', element: <DocumentDetails /> }

      ]
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgot', element: <Forgot /> },
        { path: 'email-sent', element: <Forgot /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/home" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

export function RouterFamilyAdmin() {
  return useRoutes([
    {
      path: '/home',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/home/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'staff-members', element: <User /> },
        // { path: 'update-facility-member', element: <UpdateFacilityMember /> },
        { path: 'family-members', element: <FamilyMembers /> },
        { path: 'family-more-info', element: <FamilyInfoPage /> },
        // { path: 'add-family-member', element: <AddFamilyMember /> },
        // { path: 'update-family-member', element: <UpdateFamilyMembers /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        // { path: 'register', element: <Register /> },
        // { path: 'update', element: <UpdateUser /> },
        { path: 'stories', element: <Stories /> },
        // { path: 'add-story', element: <AddStory /> },
        { path: 'stories/story-detail', element: <StoryDetail /> },
        // { path: 'update-story', element: <UpdateStory /> },
        { path: 'noticeboard', element: <NoticeBoard /> },
        // { path: 'add-notice-board', element: <AddNotice /> },
        // { path: 'update-notice', element: <UpdateNotice /> },
        { path: 'notice-board/notice-detail', element: <NoticeDetail /> },
        { path: 'change-password', element: <ChangePassword /> },
        { path: 'groups', element: <Facilities /> },
        // { path: 'add-facility-member', element: <AddFacilityMember /> },
        { path: 'locations', element: <Rooms /> },
        // { path: 'add-room', element: <AddRoom /> },
        // { path: 'update-room', element: <UpdateRoom /> },
        { path: 'clients', element: <Residents /> },
        // { path: 'add-residents', element: <AddResidents /> },
        // { path: 'update-residents', element: <UpdateResidents /> },
        { path: 'events', element: <Events /> },
        // { path: 'add-event', element: <AddEvent /> },
        // { path: 'update-event', element: <UpdateEvent /> },
        { path: 'events/event-detail', element: <EventDetail /> },
        { path: 'messages', element: <ChatApp /> },
        { path: 'profile', element: <Profile /> },
        { path: 'facility-more-info', element: <MoreInfoPage /> },
        // { path: 'drafts', element: <Drafts /> },
        // { path: 'deleted-events', element: <DeletedEvents /> },
        // { path: 'deleted-stories', element: <DeletedStories /> },
        // { path: 'deleted-notice', element: <DeletedNotice /> },
        // { path: 'deleted-Residents', element: <DeletedResidents /> },
        // { path: 'deleted-rooms', element: <DeletedRooms /> },
        // { path: 'deleted-groups', element: <DeletedFacilities /> },
        // { path: 'deleted-drafts', element: <DeletedDrafts /> },
        // { path: 'deleted-users', element: <DeletedUsers /> },
        { path: 'wellness', element: <Wellness /> },
        // { path: 'add-wellness', element: <AddWellness /> },
        // { path: 'update-wellness', element: <UpdateWellness /> },
        // { path: 'deleted-wellness', element: <DeletedWellness /> },
        { path: 'wellness/wellness-detail', element: <WellnessDetail /> },
        { path: 'family-more-info', element: <FamilyInfoPage /> },
        { path: 'facility-member-more-info', element: <FacilityInfoPage /> },
        { path: 'resident-more-info', element: <ResidentInfoPage /> }
      ]
    },



    // -----------------------------------------------
    //               Administration routes
    // -----------------------------------------------
    {
      path: '/administration',
      element: <DashboardLayout />,
      children: [
        { path: 'staff-members', element: <User /> },
        { path: 'staff-members/facility-member-more-info', element: <FacilityInfoPage /> },

        { path: 'family-members', element: <FamilyMembers /> },
        { path: 'family-members/family-more-info', element: <FamilyInfoPage /> },
        { path: 'family-members/add-family-member', element: <AddFamilyMember /> },
        { path: 'family-members/update-family-member', element: <UpdateFamilyMembers /> },

        { path: 'clients', element: <Residents /> },
        { path: 'clients/resident-more-info', element: <ResidentInfoPage /> },

      ]
    },



    // -----------------------------------------------
    //               Content routes
    // -----------------------------------------------
    {
      path: '/content',
      element: <DashboardLayout />,
      children: [
        { path: 'stories', element: <Stories /> },
        { path: 'stories/story-detail', element: <StoryDetail /> },

        { path: 'noticeboard', element: <NoticeBoard /> },
        { path: 'noticeboard/notice-detail', element: <NoticeDetail /> },

        { path: 'events', element: <Events /> },
        { path: 'events/event-detail', element: <EventDetail /> },

        { path: 'wellness', element: <Wellness /> },
        { path: 'wellness/wellness-detail', element: <WellnessDetail /> },

        { path: 'documents', element: <Documents /> },
        { path: 'document/document-details', element: <DocumentDetails /> }
      ]
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgot', element: <Forgot /> },
        { path: 'email-sent', element: <Forgot /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/home" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
