import { filter, forEach, result } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import Happyhdpi from '../../../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../../../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../../../assest/Wellness-Sadhdpi.png';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination
} from '@mui/material';
// components

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import {
    collection,
    getDocs,
    doc,
    deleteDoc,
    setDoc,
    addDocm,
    where,
    query,
    onSnapshot,
    limit,
    orderBy as docOrder
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import UserNotFound from '../../UserNotFound';
import Page from '../../Page';
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { UserListHead, UserListToolbar } from '../user';
//
import { db } from '../../../App';
// import { LightBox } from '../user/LightBox';
import ResidentMoreMenu from '../user/ResidentMoreMenu';
import DeletedTimeResi from '../resident/DeletedTimeResi';
// import { Lightbox } from './Lightbox';

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
    { id: 'Name', label: 'Name', alignRight: false },
    { id: 'mood', label: 'Mood', alignRight: false },
    { id: 'DOB', label: 'D.O.B', alignRight: false },
    { id: 'facilityName', label: 'Group', alignRight: false },
    { id: 'roomId', label: 'Location', alignRight: false },
    // { id: 'createdAt', label: 'Date Added', alignRight: false },
    // { id: 'adminStory', label: 'Group Story', alignRight: false },
    { id: 'deletedAt', label: 'Status', alignRight: false }
    //
    // { id: 'firstName', label: 'First Name', alignRight: false },
    // { id: 'lastName', label: 'Last Name', alignRight: false },
    // { id: 'DOB', label: 'DOB', alignRight: false },
    // { id: 'createdAt', label: 'Date', alignRight: false },
    // { id: 'facilityName', label: 'Facilty', alignRight: false },
    // { id: 'roomId', label: 'Room', alignRight: false },
    // { id: 'adminStory', label: 'Admin Story', alignRight: false },
    // { id: 'status', label: 'Status', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            array,
            (_user) => _user?.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function MoreInfoResidents({ id, name, deleted }) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('createdAt');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isLoading, setisLoading] = useState(true);
    const [NotFound, setNotFound] = useState(false);
    const [Search, setSearch] = useState(false);
    const [SearchSet, setSearchSet] = useState(false);
    const [USERLIST, setUSERLIST] = useState([]);
    const [moodsData, setMoodsData] = useState([])
    const location = useLocation();
    // ------for lightbox
    const [image, setImage] = useState('');

    //* **************Get Facilites From Firebase**********************

    // console.log('id.splice(0, 10)', id.splice(0, 10));

    // ---------------------------------------------------------
    // let users = [];
    const getUsers = async (ids) => {
        let userCollection;
        if (typeof ids === 'object') {
            userCollection = query(
                collection(db, 'residents'),
                where('id', 'in', ids.length === 0 ? [''] : ids)
            );
        } else {
            userCollection = query(collection(db, 'residents'), where('facilityId', '==', id));
        }
        // getDocs(userCollection).then((res) => {
        // onSnapshot(userCollection, (snapshot) => {
        // const snapshot = await getDocs(userCollection);
        const residentsIds = []
        onSnapshot(userCollection, (snapshot) => {
            const users = [];
            snapshot?.forEach((result) => {
                residentsIds.push(result.data()?.id)
                users.push({
                    id: result.data().id,
                    profileImgUrl: result.data().profileImgUrl,
                    firstName: result.data().firstName,
                    lastName: result.data().lastName,
                    Name: result.data()?.firstName + ' ' + result.data()?.lastName,
                    dateOfBirth: result.data().dateOfBirth,
                    createdAt: result.data().createdAt,
                    includedInAdminStories: result.data().includedInAdminStories,
                    capacity: result.data().capacity,
                    facilityId: result.data().facilityId,
                    facilityName: result.data().facilityName,
                    roomId: result.data().roomId,
                    roomName: result.data().roomName,
                    deletedAt: result.data()?.deletedAt,
                    like: result.data()?.likes,
                    dislike: result.data()?.dislikes,
                    aboutMe: result.data()?.aboutMe
                });
            });
            if (!users.length) {
                return setNotFound(true);
            }
            // console.log([...USERLIST, ...users])
            setUSERLIST([...USERLIST, ...users]);

            // console.log("ids are : ", residentsIds)
            if (residentsIds.length > 0) {
                const moodCollection = query(
                    collection(db, 'wellness'),
                    where('residentsId', 'array-contains-any', residentsIds),
                    docOrder('date', 'desc'), // Order by date in descending order to get the latest first
                    limit(1)
                );

                onSnapshot(moodCollection, (snapshot) => {
                    const moodss = [];
                    snapshot?.forEach((result) => {
                        const data = result.data();
                        console.log(data);
                        moodss.push({
                            mood: data.mood,
                            id: data?.residentsId[0],
                        });
                    });

                    // Update the state with the new moods
                    setMoodsData((prev) => [...prev, ...moodss]);

                    console.log("moods are: ", moodss);
                });

                // onSnapshot(moodCollection, (snapshot) => {
                //   const moodss = []; // Define within the snapshot scope
                //   snapshot?.forEach((result) => {
                //     const data = result.data();
                //     console.log(data)
                //     moodss.push({
                //       mood: data.mood,
                //       id: data?.residentsId[0],
                //     });
                //   });
                //   setMoodsData([...moodss])
                //   console.log("moods are: ", moodss);

                //   // Handle mood data as needed
                //   // For example, update state or call another function
                // });
            } else {
                console.log("No valid resident IDs to query moods.");
            }
            // console.log("moods are : ", moodss)

        });

        // });
    };

    //* *****************UseEffect for users get********************
    useEffect(() => {
        if (!id) {
            return setNotFound(true);
        }
        const chunkSize = 10;
        if (typeof id === 'object') {
            for (let i = 0; i < id?.length; i += chunkSize) {
                getUsers(id?.splice(i, i + chunkSize) ?? []);
            }
        } else {
            getUsers();
        }
        setisLoading(false);
    }, [typeof id === 'object' && USERLIST]);
    // ---------------------------------------------------------

    // let userCollection;
    // if (typeof id === 'object') {
    //   userCollection = query(
    //     collection(db, 'residents'),
    //     where('id', 'in', id.length === 0 ? [''] : id)
    //   );
    // } else {
    //   userCollection = query(collection(db, 'residents'), where('facilityId', '==', id));
    // }

    // let users = [];
    // const getUsers = async () => {
    //   // getDocs(userCollection).then((res) => {
    //   onSnapshot(userCollection, (snapshot) => {
    //     users = [];
    //     snapshot.docs.forEach((result) => {
    //       users.push({
    //         id: result.data().id,
    //         profileImgUrl: result.data().profileImgUrl,
    //         firstName: result.data().firstName,
    //         lastName: result.data().lastName,
    //         dateOfBirth: result.data().dateOfBirth,
    //         createdAt: result.data().createdAt,
    //         includedInAdminStories: result.data().includedInAdminStories,
    //         capacity: result.data().capacity,
    //         facilityId: result.data().facilityId,
    //         facilityName: result.data().facilityName,
    //         roomId: result.data().roomId,
    //         roomName: result.data().roomName,
    //         deletedAt: result.data()?.deletedAt,
    //         like: result.data()?.likes,
    //         dislike: result.data()?.dislikes,
    //         aboutMe: result.data()?.aboutMe
    //       });
    //     });
    //     if (!users.length) {
    //       return setNotFound(true);
    //     }
    //     setUSERLIST(users);
    //   });
    // };

    // //* *****************UseEffect for users get********************
    // useEffect(() => {
    //   getUsers();
    //   setisLoading(false);
    // }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        if (Search) {
            setSearch(false);
        } else {
            setSearch(true);
        }
        setSearchSet(true);
        setFilterName(event.target.value);
    };

    useEffect(() => {
        if (filteredUsers.length === 0 && SearchSet) {
            setNotFound(true);
        }
    }, [Search]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;
    if (isLoading)
        return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
                <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
            </div>
        );
    return (
        <Page title="Residents | Rel8">
            <Container style={{ padding: "0px" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} m={2}>
                    <Typography variant="h4" style={{ textTransform: "initial", color: "#9A76F5", paddingTop: "25px" }} gutterBottom>
                        Family members
                    </Typography>
                    {/* {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' &&
                        typeof id !== 'object' && (
                            <Button
                                variant="contained"
                                disabled={deleted === 'yes' && true}
                                component={RouterLink}
                                to="/administration/clients/add-residents"
                                startIcon={<Icon icon={plusFill} />}
                                state={{ data: { id, name } }}
                            >
                                Add Client
                            </Button>
                        )} */}
                </Stack>

                <Card>
                    {/* <UserListToolbar
                        USERLIST={[]}
                        localuser={{}}
                        selected={selected}
                        numSelected={selected.length}
                        filterName={filterName}
                        onFilterName={handleFilterByName}
                    /> */}

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={USERLIST.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                    deletedTab={true}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            const {
                                                id,
                                                firstName,
                                                lastName,
                                                facilityId,
                                                facilityName,
                                                includedInAdminStories,
                                                dateOfBirth,
                                                profileImgUrl,
                                                // capacity,
                                                createdAt,
                                                roomId,
                                                roomName,
                                                deletedAt,
                                                like,
                                                dislike,
                                                aboutMe,
                                                Name
                                            } = row;
                                            const isItemSelected = selected.indexOf(id) !== -1;
                                            const mood = moodsData?.filter(item => item.id == id)[0]?.mood
                                            console.log(moodsData)
                                            console.log("id is : ", id, 'mood is : ', mood)
                                            return (
                                                <TableRow
                                                    hover
                                                    key={id}
                                                    tabIndex={-1}
                                                    facilityName="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            /> */}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="2px">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {profileImgUrl ? (
                                                                // <Lightbox src={profileImgUrl} alt={firstName}>
                                                                <Avatar
                                                                    alt={firstName}
                                                                    src={profileImgUrl}
                                                                    style={{ cursor: 'pointer', backgroundColor: '#9A76F5' }}
                                                                    onClick={() => {
                                                                        setImage(profileImgUrl);
                                                                    }}
                                                                />
                                                            ) : (
                                                                // </Lightbox>
                                                                <Avatar
                                                                    alt={firstName}
                                                                    src={profileImgUrl}
                                                                    style={{ backgroundColor: '#9A76F5' }}
                                                                />
                                                            )}
                                                            <Typography variant="subtitle2">{Name ?? ''}</Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    {/* <TableCell align="left">{lastName}</TableCell> */}
                                                    <TableCell align="left" >
                                                        {mood && mood?.toLowerCase() === 'happy' && (
                                                            <img src={Happyhdpi} alt="mood" width="30" height="30" />
                                                        )}
                                                        {mood && mood?.toLowerCase() === 'neutral' && (
                                                            <img src={Neutralhdpi} alt="mood" width="30" height="30" />
                                                        )}
                                                        {mood && mood?.toLowerCase() === 'sad' && (
                                                            <img src={Sadhdpi} alt="mood" width="30" height="30" />
                                                        )}
                                                        {!mood && <p style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "30%" }}>—</p>}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {moment(dateOfBirth?.toDate()).format('DD/MM/YYYY')}
                                                    </TableCell>
                                                    <TableCell width="12%">
                                                        {facilityName || (
                                                            <Label variant="ghost" color="error">
                                                                Unassigned
                                                            </Label>
                                                        )}
                                                    </TableCell>
                                                    <TableCell width="12%">
                                                        {roomName || (
                                                            <Label variant="ghost" color="error">
                                                                Unassigned
                                                            </Label>
                                                        )}
                                                    </TableCell>
                                                    {/* <TableCell align="left">
                            {moment(dateOfBirth?.toDate()).format('DD/MM/YYYY')}
                          </TableCell> */}

                                                    {/* <TableCell align="left">{createdAt.toDate().toDateString()}</TableCell> */}
                                                    {/* <TableCell align="left">
                            {moment(createdAt?.toDate()).format('DD/MM/YYYY')}
                          </TableCell> */}
                                                    {/* <TableCell>
                            {facilityName || (
                              <Label variant="ghost" color="error">
                                Unassigned
                              </Label>
                            )}
                          </TableCell>
                          <TableCell>
                            {roomName || (
                              <Label variant="ghost" color="error">
                                Unassigned
                              </Label>
                            )}
                          </TableCell> */}
                                                    {/* <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={includedInAdminStories ? 'success' : 'error'}
                            >
                              {sentenceCase(includedInAdminStories ? 'True' : 'False')}
                            </Label>
                          </TableCell> */}
                                                    <TableCell align="left">
                                                        {deletedAt != null ? (
                                                            // <Label variant="ghost" color="error">
                                                            //     Deleted
                                                            // </Label>
                                                            <Label variant="ghost" color="error">
                                                                {DeletedTimeResi(deletedAt)}-remaining
                                                            </Label>
                                                        ) : (
                                                            <Label variant="ghost" color="success">
                                                                Active
                                                            </Label>
                                                        )}
                                                    </TableCell>
                                                    {/* {JSON.parse(localStorage.getItem('Rel8User')).role !==
                                                        'Family Member' && (
                                                            <TableCell align="right">
                                                                {deleted !== 'yes' && (
                                                                    <ResidentMoreMenu
                                                                        id={id}
                                                                        firstName={firstName}
                                                                        lastName={lastName}
                                                                        dateOfBirth={dateOfBirth}
                                                                        facilityId={facilityId}
                                                                        facilityName={facilityName}
                                                                        roomId={roomId}
                                                                        roomName={roomName}
                                                                        // capacity={capacity}
                                                                        createdAt={createdAt}
                                                                        includedInAdminStories={includedInAdminStories}
                                                                        profileImgUrl={profileImgUrl}
                                                                        deletedAt={deletedAt ?? null}
                                                                        like={like}
                                                                        dislike={dislike}
                                                                        aboutMe={aboutMe}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        )} */}
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound &&
                                    (NotFound ? (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                    <UserNotFound />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={USERLIST.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
        </Page>
    );
}
