import { Box, Card, Divider, Grid, Stack, Typography, Avatar, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Icon } from '@iconify/react';
import messageSquareOutline from '@iconify/icons-eva/message-square-outline';
import heartOutline from '@iconify/icons-eva/heart-outline';
import Linkify from 'react-linkify';
import BackButtonImage from '../../../assest/backButton.svg';
import { localUser } from '../../../App';
import ShopProductCard from '../products/ProductCard';
import { LightBox } from '../user/LightBox';
import Happyhdpi from '../../../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../../../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../../../assest/Wellness-Sadhdpi.png';
import { styled } from '@mui/material/styles';
import CalendarIcon from "../../../assest/calenderIcon.svg"
import ClockIcon from "../../../assest/clock.svg"
import LocationIcon from "../../../assest/location.svg"
import { AccountCircle } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
function DocumentsPreview({
    file,
    AddSubmitFunc,
    isLoading,
    head1,
    type,
    setSelectOption,
    residentName,
    visibleFlag,
    date,
    section,
    fileName
}) {
    const ProductFileStyle = styled('div')({
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        cursor: 'pointer',
        backgroundColor: '#E4E4E4'
    });
    const location = useLocation()

    const fullName = file?.[0]?.name
    const fileExtension = fullName?.substring(fullName.lastIndexOf(".") + 1);

    const images =
        file?.length != 0
            ? file.filter((image) => {
                console.log('image :: ', image);
                const file =
                    (image.type != 'image' && image.type != 'video') ||
                    (image.name && image.name.endsWith('.avi')) ||
                    (image.name && image.name.endsWith('.mpeg')) ||
                    (image.name && image.name.endsWith('.heic'));
                const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
                if (type != 'file') {
                    return image;
                }
            })
            : [];
    const docs =
        file?.length != 0
            ? file.filter((image) => {
                console.log('image :: ', image);
                const file =
                    (image.type != 'image' && image.type != 'video') ||
                    (image.name && image.name.endsWith('.avi')) ||
                    (image.name && image.name.endsWith('.mpeg')) ||
                    (image.name && image.name.endsWith('.heic'));
                const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
                if (type == 'file') {
                    return image;
                }
            })
            : [];

    const residentsArray = Object.values(residentName);
    console.log("visible flag is : ", visibleFlag)
    return (
        <Card
            sx={{
                paddingY: '25px',
                paddingX: '15px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px'
            }}
        >
            <div
                onClick={() => {
                    setSelectOption('schedule');
                }}
            >
                <img src={BackButtonImage} style={{ width: '35px', cursor: 'pointer' }} />
            </div>
            <p style={{ color: '#9A76F5', fontSize: "30px", fontWeight: "600" }}>Document Detail</p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: '',
                    justifyContent: '',
                    marginRight: '',
                    gap: '6px'
                }}
            >
                <Box sx={{ fontSize: '16px', fontWeight: 400, marginLeft: '', color: '#9A76F5' }}>
                    {moment(date).format('DD MMMM YYYY')}
                </Box>
            </div>

            <div>
                {residentsArray?.map((resident) => {
                    console.log(resident)
                    return (<div key={resident.value} style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
                        {resident.image ?
                            <img style={{ width: "28px", height: "28px", borderRadius: "50%" }} src={resident.image} />
                            :
                            <Avatar src="" sx={{ backgroundColor: '#9A76F5' }}>
                                {resident.title?.split(' ')[0]?.slice(0, 1)?.toUpperCase()}
                                {resident.title?.split(' ')[1]?.slice(0, 1)?.toUpperCase()}
                            </Avatar>
                            //  <AccountCircle style={{ width: "24px", height: "24px", borderRadius: "50%" }} />
                        }
                        {resident.title}
                    </div>)
                })}
            </div>  <div>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={visibleFlag.value} disabled />} label={'Visible to next of kin only'} />
                </FormGroup>
            </div>

            <div className="grid">
                {images.map((image, index) => {
                    const fileType =
                        (image.type != 'image' && image.type != 'video') ||
                        (image.name && image.name.endsWith('.avi')) ||
                        (image.name && image.name.endsWith('.mpeg')) ||
                        (image.name && image.name.endsWith('.heic'));
                    const type = fileType ? 'file' : image.type === 'image' ? 'image' : 'video';

                    return (
                        <>
                            {type == 'image' && (
                                <div
                                    key={image.id}
                                    className={index == 0 && images?.length > 7 ? 'firstIndex' : 'allIndex'}
                                >
                                    <ShopProductCard img={image} Index={index} images={images} />
                                </div>
                            )}
                            {type == 'video' && (
                                <div
                                    key={image.id}
                                    className={index == 0 && images?.length > 7 ? 'firstIndex' : 'allIndex'}
                                >
                                    <ShopProductCard img={image} Index={index} images={file} />
                                </div>
                            )}
                        </>
                    );
                })}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                {docs &&
                    docs.map((item) => {
                        return (
                            <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: '50px',
                                        minWidth: '50px',
                                        maxWidth: '50px',
                                        height: '60px',
                                        minHeight: '60px',
                                        maxHeight: '60px',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <img src={item.thumbnail} style={{ objectFit: 'contain', maxHeight: '100%' }} />
                                </div>
                                <p>
                                    {fileName?.length > 35 ? fileName.slice(0, 30) + '...' + fileExtension : fileName + '.' + fileExtension} {' ('}
                                    {(location.state?.props?.medias[0]?.id && location.state?.props?.medias[0]?.id == file[0]?.id) ? item?.size : Number(item.size / 1024).toFixed(2) ?? ''}
                                    {' KB)'}
                                </p>
                            </div>
                        );
                    })}
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'start',
                    gap: '1px',
                    marginTop: '20px'
                }}
            >
                <Box variant="h6" sx={{ color: '#9A76F5', fontWeight: 500 }} style={{ paddingLeft: '0%' }}>
                    Shared By : {localUser?.firstname} {localUser?.lastname}
                </Box>
                <Box variant="h6" sx={{ color: '#9A76F5', fontWeight: 500 }} style={{ paddingLeft: '0%' }}>
                    {localUser?.organization}
                </Box>
            </div>
            <div
                style={{ width: '100%', height: '2px', borderRadius: '2px', backgroundColor: '#9a76f5' }}
            ></div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '15px',
                    marginTop: '15px',
                    width: '100%'
                }}
            >

                <LoadingButton
                    fullWidth
                    size="large"
                    // type="submit"
                    variant="contained"
                    // loading={isLoading}
                    sx={{}}
                    onClick={() => {
                        setSelectOption('add');
                    }}
                >
                    Edit
                </LoadingButton>
                <LoadingButton
                    fullWidth
                    size="large"
                    // type="submit"
                    variant="contained"
                    loading={isLoading}
                    onClick={() => {
                        AddSubmitFunc(0);
                    }}
                >
                    {head1}
                </LoadingButton>
            </div>


        </Card>
    );
}

export default DocumentsPreview;
