// material
import { styled } from '@mui/material/styles';
import { Box, Card } from '@mui/material';
// layouts
// components
import Page from '../../components/Page';
import { useState } from 'react';
import AddDocumentsForm from 'src/components/_dashboard/profileDocuments/AddDocumentsForm';
import UpdateDocumentForm from 'src/components/_dashboard/profileDocuments/UpdateDocumentForm';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  padding: theme.spacing(0, 0)
}));

// ----------------------------------------------------------------------

export default function UpdateDocument() {
  const [selectOption, setSelectOption] = useState('add')
  const location=useLocation()
  const {props}=location.state
  console.log("props are : ",props)
  return (
    <RootStyle title="Update Documents | Rel8">
      <ContentStyle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%'
          }}
        >
          <div
            onClick={() => {
              setSelectOption('add');
            }}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'add' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'add' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Add Document
          </div>
          <div
            onClick={() => {
              if (selectOption == 'preview') {
                setSelectOption('schedule');
              }
            }}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'schedule' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'schedule' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Schedule
          </div>
          <div
            onClick={() => { }}
            style={{
              fontSize: '20px',
              fontWeight: 500,
              color: selectOption == 'preview' ? '#9A76F5' : 'black',
              textDecoration: selectOption === 'preview' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer'
            }}
          >
            Preview
          </div>
        </Box>
        <UpdateDocumentForm selectOption={selectOption} setSetSelectOption={setSelectOption} props={props} />
      </ContentStyle>

    </RootStyle>
  );
}
