import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
// components
import Page from '../../components/Page';
import FacilityMoreInfoDetail from '../../components/_dashboard/FaciltiyMembers/MoreInfo/FacilityMoreInfoDetail';
import FamilyMoreInfo from '../../components/_dashboard/FamilyMember/FamilyMoreInfo';
import ProfileIcon from "../../assest/profile-icon.svg"
import { MHidden } from 'src/components/@material-extend';
import { localUser } from 'src/App';
import OtherStaffProfile from '../OtherStaffProfile';
import MemberMoreMenu from 'src/components/_dashboard/user/MemberMoreMenu';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
  //   padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function FacilityInfoPage() {
  const location = useLocation();
  const { row } = location.state;

  const user = JSON.parse(localStorage.getItem('Rel8User'));

  console.log("staff member location state is : ", location.state);
  // { title: 'Administrator', value: 0 },
  // { title: 'Coordinator', value: 1 },
  // { title: 'Team Member', value: 2 }

  return (
    <RootStyle title="Facility Details | Rel8">
      <Container>
        <ContentStyle>
          <Stack direction="row" alignItems="center" justifyContent="space-between" px={2} mb={2}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                color: '#9A76F5'
              }}
            >
              <img
                src={ProfileIcon}
                style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
              />
              <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500, textTransform: "capitalize" }}>
                {row.firstname}'s profile
              </p>
            </Box>
            {localUser?.role === 'admin' && (
              <MemberMoreMenu
                id={row?.id}
                facilityName={row?.facilityName}
                facilityId={row?.facilityId}
                email={row?.email}
                firstname={row?.firstname}
                lastname={row?.lastname}
                createdAt={row?.date}
                avatarUrl={row?.avatarUrl ?? null}
                role={row?.role}
                status={row?.status ?? null}
                restrictAt={row?.restrictAt ?? 0}
                jobTitle={row?.jobTitle ?? ''}
                organisation={row?.organisation ?? ''}
                organisationId={row?.organisationId ?? ''}
                deletedAt={row?.deletedAt ?? null}
                subRole={row?.subRole}
                restrictTime={row?.restrictTime}
                residentIds={row?.residentIds ?? location?.state?.residents ?? []}
                allFacilities={row?.getFacility ?? []}
              />
            )}
            {localUser?.role === 'Facility Member' && localUser?.subRole === 0 && (
              <MemberMoreMenu
                id={row?.id}
                facilityName={row?.facilityName}
                facilityId={row?.facilityId}
                email={row?.email}
                firstname={row?.firstname}
                lastname={row?.lastname}
                createdAt={row?.date}
                avatarUrl={row?.avatarUrl ?? null}
                role={row?.role}
                status={row?.status ?? null}
                restrictAt={row?.restrictAt ?? 0}
                jobTitle={row?.jobTitle ?? ''}
                organisation={row?.organisation ?? ''}
                organisationId={row?.organisationId ?? ''}
                deletedAt={row?.deletedAt ?? null}
                subRole={row?.subRole}
                restrictTime={row?.restrictTime}
                residentIds={row?.residentIds ?? []}
              />
            )}

          </Stack>
          <MHidden width="mdDown">
            <Card
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
                display: 'flex',
                width: 'full',
                paddingY: '25px',
                paddingX: '40px',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: "20px"
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', width: '50%' }}>
                {row?.avatarUrl ? (
                  <Avatar
                    src={row.avatarUrl}
                    alt="photoURL"
                    sx={{ width: '120px', height: '120px' }}
                  />
                ) : (
                  <Avatar
                    src=""
                    sx={{
                      backgroundColor: '#9A76F5',
                      fontWeight: 400,
                      width: '120px',
                      height: '120px',
                      fontSize: '60px'
                    }}
                  >
                    {row?.firstname?.slice(0, 1)?.toUpperCase()}
                    {row?.lastname?.slice(0, 1)?.toUpperCase()}
                  </Avatar>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
                  <Box sx={{ fontSize: '24px' }}>
                    {row?.firstname} {row?.lastname}
                  </Box>
                  <Box sx={{ fontSize: '18px', color: '#9A76F5', fontWeight: "500" }}>
                    {/* {row?.role} */}
                    {row?.subRole === 0 ? (
                      'Administrator'
                    ) : row?.subRole === 1 ? 'Coordinator' : 'Team Member'}
                  </Box>
                </Box>
              </Box>

              {(user?.role == 'admin' || user?.role == 'Facility Member') && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                    gap: '15px'
                  }}
                >
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Organisation</Box>
                    <Box sx={{ fontSize: '16px' }}>{row?.organisation ? row?.organisation : 'N/A'}</Box>
                  </Box>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Job Title</Box>
                    <Box sx={{ fontSize: '16px' }}>
                      {row?.jobTitle.length > 0 ? row?.jobTitle : 'N/A'}
                    </Box>
                  </Box>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'space-between',
                  gap: '15px'
                }}
              >
                <Box sx={{ height: '50%' }}>
                  <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Email</Box>
                  <Box sx={{ fontSize: '16px' }}>{row?.email ?? ''}</Box>
                </Box>
                <Box sx={{ height: '50%' }}>
                  <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Account Status</Box>
                  <Box sx={{ fontSize: '16px' }} >
                    {row?.status == 'accepted' ?
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                        <span
                          style={{
                            backgroundColor: "#3ad638", // Parrot green color
                            width: "10px",
                            height: "10px",
                            display: "inline-block", // Ensures the span behaves like a block for dimensions
                            borderRadius: "50%",
                          }}
                        ></span>
                        Active
                      </div>
                      :
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                        <span
                          style={{
                            backgroundColor: "#e84f51", // Parrot green color
                            width: "10px",
                            height: "10px",
                            display: "inline-block", // Ensures the span behaves like a block for dimensions
                            borderRadius: "50%",
                          }}
                        ></span>
                        Pending
                      </div>}
                  </Box>

                </Box>
              </Box>

            </Card>
          </MHidden>

          <MHidden width="mdUp">
            <Card
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
                display: 'flex',
                flexDirection: 'column',
                width: 'full',
                paddingY: '25px',
                paddingX: '15px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '30px'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', width: '100%' }}>
                {row?.avatarUrl ? (
                  <Avatar
                    src={row?.avatarUrl}
                    alt="photoURL"
                    sx={{ width: '60px', height: '60px', fontSize: '40px' }}
                  />
                ) : (
                  <Avatar
                    src=""
                    sx={{
                      backgroundColor: '#9A76F5',
                      fontWeight: 400,
                      width: '60px',
                      height: '60px'
                    }}
                  >
                    {row?.firstname?.slice(0, 1)?.toUpperCase()}
                    {row?.lastname?.slice(0, 1)?.toUpperCase()}
                  </Avatar>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <Box sx={{ fontSize: '18px' }}>
                    {row?.firstname} {row?.lastname}
                  </Box>
                  <Box sx={{ fontSize: '14px', color: '#9A76F5', fontWeight: "500" }}>
                    {row?.role.toUpperCase()}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '30px',
                  width: '100%',
                  justifyContent: 'space-around'
                }}
              >

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '30px',
                    width: '100%',
                    justifyContent: 'space-around'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      justifyContent: 'space-between',
                      gap: '10px'
                    }}
                  >
                    <Box sx={{ height: '50%' }}>
                      <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Email</Box>
                      <Box sx={{ fontSize: '16px' }}>{row?.email.slice(0, 20) ?? ''}</Box>
                    </Box>
                    <Box sx={{ height: '50%' }}>
                      <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Account Status</Box>
                      <Box sx={{ fontSize: '16px' }}>
                        {row?.status !== 'accepted' ?
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                            <span
                              style={{
                                backgroundColor: "#3ad638", // Parrot green color
                                width: "10px",
                                height: "10px",
                                display: "inline-block", // Ensures the span behaves like a block for dimensions
                                borderRadius: "50%",
                              }}
                            ></span>
                            Active
                          </div>
                          :
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                            <span
                              style={{
                                backgroundColor: "#e84f51", // Parrot green color
                                width: "10px",
                                height: "10px",
                                display: "inline-block", // Ensures the span behaves like a block for dimensions
                                borderRadius: "50%",
                              }}
                            ></span>
                            Pending
                          </div>}

                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      justifyContent: 'space-between',
                      gap: '10px'
                    }}
                  >
                    <Box sx={{ height: '50%' }}>
                      <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Organisation</Box>
                      <Box sx={{ fontSize: '16px' }}>{row?.organization ? row?.organization : 'N/A'}</Box>
                    </Box>
                    <Box sx={{ height: '50%' }}>
                      <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Job Title</Box>
                      <Box sx={{ fontSize: '16px' }}>
                        {row?.jobTitle.length > 0 ? row?.jobTitle : 'N/A'}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Card>
          </MHidden>
          {/* <FacilityMoreInfoDetail /> */}
          <OtherStaffProfile data={location.state} />
        </ContentStyle>
      </Container>
    </RootStyle >
  );
}
