import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Grid,
  Box,
  styled,
  Button,
  Card,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import AsyncSelect from 'react-select/async';
import { collection, doc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import axios from 'axios';
import Spinner from 'react-spinner-material';
import { options } from 'numeral';
import { addFacility } from '../../../pages/Facilities';
import { db, localUser } from '../../../App';
import { subRoleFamily } from '../../subRole/SubRole';
import SortByTitle from '../event/SortByTitle';
import { cloudFuncBaseUrl } from 'src/utils/config';

// ----------------------------------------------------------------------
const user = JSON.parse(localStorage.getItem('Rel8User'));
const addFamilyMemberAuth = async (
  email,
  residentIds,
  firstname,
  lastname,
  residentsName,
  image,
  subRole,
  facilityIds
) => {
  try {
    const data = {
      email,
      residentIds,
      firstname,
      lastname,
      adminName: `${user?.firstname} ${user?.lastname}`,
      residentsName,
      status: 'pending',
      subRole,
      facilityIds
    };
    console.log(data);
    const resp = await axios.post(`${cloudFuncBaseUrl}.cloudfunctions.net/addFamilyMember`, data);
    // console.log(resp?.data.data.id);
    console.log(resp);
    if (image) {
      // -----------------
      const storage = getStorage();
      const storageRef = ref(storage, `users/${resp?.data?.data?.id}/profile`);
      const metadata = {
        contentType: 'image/jpeg'
      };
      const uploadTask = await uploadBytesResumable(storageRef, image, metadata);
      const avatarUrl = await getDownloadURL(uploadTask.ref);
      // -----
      await setDoc(
        doc(db, 'users', resp?.data?.data?.id),
        {
          avatarUrl
        },
        { merge: true }
      );
    }
    // --------------
  } catch (err) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: err.message
    });
  }
};

// for image
const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function FamilyMemberForm() {
  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [colourOptions, setColourOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [openRole, setOpenRole] = useState(false)

  const loading = open && options.length === 0;

  const filterColors = (inputValue) =>
    colourOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };
  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    // _this.setState({ inputValue: inputValue });
    return inputValue;
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      const opt = [];
      if (localUser.role === 'admin') {
        facilitesCollection = collection(db, 'residents');
        const facilityPromise = await getDocs(facilitesCollection);
        facilityPromise.forEach((result) => {
          opt.push({
            value: result.data().id,
            title: `${result.data().firstName} ${result.data().lastName}`,
            facility: result.data().facilityId
          });
        });
      } else {


        //   console.log("residentIds :: ",localUser.residentIds)
        //   facilitesCollection = query(
        //     collection(db, 'residents'),
        //     where('id', 'in', localUser.residentIds.slice(0,35))
        //   );
        // }

        // const facilityPromise = await getDocs(facilitesCollection);
        // const opt = [];
        // facilityPromise.forEach((result) => {
        //   opt.push({
        //     value: result.data()?.id,
        //     title: `${result.data()?.firstName} ${result.data()?.lastName}`,
        //     facility: result.data()?.facilityId
        //   });
        // });
        // if (active) {
        //   // console.log(opt);
        //   const optArr = SortByTitle(opt);
        //   setOptions([...options, ...optArr]);
        // }



        // const residentIds = localUser.residentIds;
        // const chunkSize = 30;
        // const chunks = [];

        // // Split residentIds into chunks
        // for (let i = 0; i < residentIds.length; i += chunkSize) {
        //   chunks.push(residentIds.slice(i, i + chunkSize));
        // }

        // Perform queries for each chunk
        // const results =await getDocs(query(
        //   collection(db, 'residents'),
        //   where('facilityId', 'in', localUser?.facilityIds ?? [''])
        // ));
        // chunks.map(chunk => {
        //   return getDocs(query(
        //     collection(db, 'residents'),
        //     where('id', 'in', chunk)
        //   ));
        // });

        // Combine results when all queries are done
        // const results = await Promise.all(promises);


        // results?.forEach(snapshot => {
        //   snapshot.forEach(result => {
        //     opt.push({
        //       value: result.data().id,
        //       title: `${result.data().firstName} ${result.data().lastName}`,
        //       facility: result.data().facilityId
        //     });
        //   });
        // });
        // results?.map(snapshot => {
        // results?.map(result => {
        //   // console.log(result.data)
        //   opt.push({
        //     value: result.data().id,
        //     title: `${result.data().firstName} ${result.data().lastName}`,
        //     facility: result.data().facilityId
        //   });
        // });
        // });
        facilitesCollection = query(collection(db, 'residents'), where('facilityId', 'in', localUser?.facilityIds ?? [''])
          //  where('id', 'in', localUser?.residentIds ?? [''])
        )
        const facilityPromise = await getDocs(facilitesCollection);
        // opt = [];
        facilityPromise.forEach((result) => {
          // console.log(result.data());
          opt.push({
            value: result.data().id,
            title: `${result.data().firstName} ${result.data().lastName}`,
            facility: result.data()?.facilityId
          });
        });
      }
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...options, ...optArr]);
      }


    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Enter valid email address').required('Email is required'),
    residentId: Yup.array().required('Client is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    subRole: Yup.number().required('Role is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      residentId: '',
      photo: '',
      subRole: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      // if (!image) {
      //   setIsLoading(false);
      //   return NotificationManager.error("You didn't select profile");
      // }

      setIsLoading(true);
      const email = getFieldProps('email').value;
      const residentId = getFieldProps('residentId').value;
      // const facilityName = getFieldProps('facilityName').value;
      const firstName = getFieldProps('firstName').value;
      const lastName = getFieldProps('lastName').value;
      const subRole = getFieldProps('subRole').value;

      const residentsIds = residentId.map((item) => item.value);
      const facilityId = residentId.map((item) => item.facility);

      const facilityIds = [...new Set(facilityId)];

      const residentsName = residentId.map((item) => item.title);

      const userQuery = query(collection(db, 'users'), where('email', '==', email))
      // const snapShot= await getDocs(userQuery)
      // onSnapshot(userQuery, (snapshot) => {
      //   if (snapshot.empty) {
      //     console.log("email dosen't exisst")
      //   } else {
      //     setIsLoading(false)
      //     NotificationManager.error('User with this email already exists')
      //     console.log("already exists")
      //     return
      //   }
      // })

      const userData = await getDocs(userQuery)
      if (userData.empty) {
        console.log("email dosen't exisst")
      } else {
        setIsLoading(false)
        NotificationManager.error('User with this email already exists')
        console.log("already exists")
        return
      }


      addFamilyMemberAuth(
        email,
        residentsIds,
        firstName,
        lastName,
        residentsName,
        image,
        subRole,
        facilityIds
      )
        .then((res) => {
          if (res?.data?.error) {
            setIsLoading(false);
            return NotificationManager.error(res?.data?.error);
          }
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Family Member Added Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          window.location.replace('/administration/family-members');
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          return NotificationManager.error(err.message);
        });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                First name
              </div>
              <TextField
                fullWidth
                autoComplete="firstName"
                // label="First Name"
                sx={{
                  "& .MuiInputLabel-outlined": {
                    color: "#9A76F5",
                  },
                }}
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Last name
              </div>
              <TextField
                fullWidth
                autoComplete="username"
                sx={{
                  "& .MuiInputLabel-outlined": {
                    color: "#9A76F5",
                  },
                }}
                type="email"
                // label="Last Name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Email address
              </div>
              <TextField
                fullWidth
                autoComplete="username"
                sx={{
                  "& .MuiInputLabel-outlined": {
                    color: "#9A76F5",
                  },
                }}
                type="email"
                // label=""
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Box>
          </Stack>


          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* -------------- for Sub Role -------------- */}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Role
              </div>
              <Autocomplete
                fullWidth
                id="asynchronous-demo"
                onChange={(e, value) => {
                  console.log(value?.value);
                  setFieldValue('subRole', value?.value);
                }}
                onOpen={() => {
                  setOpenRole(true)
                }
                }
                onClose={() => {
                  setOpenRole(false)
                }
                }
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.title}
                // options={['Administrator', 'Coordinator', 'Team member']}
                options={subRoleFamily}
                renderInput={(params) => (
                  <TextField
                    // variant=''
                    fullWidth
                    {...params}
                    // label={(!getFieldProps('subRole').value && !openRole) ? "Select role" : undefined}
                    label={(!subRoleFamily?.[getFieldProps('subRole').value]?.title && !openRole) ? "Select role" : undefined}
                    {...getFieldProps('subRole')}
                    InputProps={{
                      ...params.InputProps
                    }}
                    error={Boolean(touched.subRole && errors.subRole)}
                    helperText={touched.subRole && errors.subRole}
                  />
                )}
              />
            </Box>

            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Family
              </div>
              <Autocomplete

                fullWidth
                disableCloseOnSelect
                id="asynchronous-demo"
                open={open}
                multiple
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(e, value) => {
                  setFieldValue('residentId', value || '');
                }}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                getOptionLabel={(option) => option.title}
                options={options}
                loading={loading}
                renderOption={(props, option) => {
                  const selectedValues = getFieldProps("residentId").value
                  console.log(selectedValues)
                  const isChecked = selectedValues?.length ? selectedValues?.find((val) => val.value === option.value) : false // Check if option is selected

                  return (
                    <li {...props}>
                      <Checkbox checked={isChecked} title={option.title} />
                      {option.title}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    // label="Family"
                    label={(!open && (getFieldProps('residentId')?.value.length == 0)) ? "Select family" : undefined}
                    {...getFieldProps('resident')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    error={Boolean(touched.residentId && errors.residentId)}
                    helperText={touched.residentId && errors.residentId}
                  />
                )}
              />
            </Box>


          </Stack>
          {/* ----------------For photo ------------------- */}
          <Grid className='grid1' container spacing={2}>
            {image && (
              <Grid sx={{ position: 'relative' }} style={{ paddingLeft: "0px" }} item xs={4} sm={4} md={2}>
                <div style={{ position: "absolute", top: "5px", right: "-8px", backgroundColor: "#9A76F5", borderRadius: "50%", padding: "5px", zIndex: 1000, display: "flex" }}>
                  <Icon
                    color='white'
                    icon="ci:close-big"
                    onClick={() => {
                      setImage('');
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                <Card>
                  <Box sx={{ pt: '100%', }}>
                    <ProductImageStyle alt="Image" src={URL.createObjectURL(image)} />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label" sx={{ fontSize: "16px" }}
              style={{ textTransform: "initial", display: "flex", flexDirection: "row", alignItems: "center", gap: "3px" }}>
              <span style={{ fontSize: "24px", marginRight: "2px" }}>+</span>Add photo
              <input
                fullWidth
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Add Family
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
