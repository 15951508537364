import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  Button,
  Grid,
  Box,
  styled,
  Card,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import InputMask from 'react-input-mask';
import { doc, collection, onSnapshot } from 'firebase/firestore';
import { addFacility } from '../../../pages/Facilities';
import { db } from '../../../App';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_PLACES_API_KEY } from 'src/utils/config';

// ----------------------------------------------------------------------

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
export default function RegisterForm() {
  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openOrg, setOpenOrg] = useState(false);
  const [optionOrg, setOptionOrg] = useState([]);
  const [isLoadingOrg, setisLoadingOrg] = useState(false);

  async function getOrganisation() {
    setisLoadingOrg(true);
    const orgRef = collection(db, 'organizations');
    onSnapshot(orgRef, (snapshot) => {
      const users = [];
      snapshot.docs.forEach((result) =>
        users.push({ title: result.data().organizationName, value: result.data().id })
      );
      setOptionOrg(users);
      setisLoadingOrg(false);
    });
  }

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(200, 'Too Long!').required('Name is required'),
    capacity: Yup.number()
      .positive()
      .required('pls enter')
      .min(1)
      .required('Number of clients is required'),
    contact: Yup.string().required('Phone number is required'),
    Location: Yup.string().required('Address is required'),
    // organisation: Yup.object().nullable().required('Organisation is required'),
    organisation: Yup.object()
      .nullable()
      .test('title', 'Organisation is required', function (value) {
        return value !== null;
      }),
    type: Yup.string().required('Type is required'),
    chatDisable: Yup.bool(),
    videoCallDisable: Yup.bool()
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      contact: '',
      capacity: '',
      photo: '',
      organisation: null,
      Location: '',
      type: 'Residential Aged Care' ?? '',
      chatDisable: false,
      videoCallDisable: false
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setIsLoading(true);
      console.log("workinh inside")
      const name = getFieldProps('name').value;
      const contact = getFieldProps('contact').value;
      const capacity = getFieldProps('capacity').value;
      const storage = getStorage();
      const facilityRef = doc(collection(db, 'facilites'));
      const organization = getFieldProps('organisation').value?.title;
      const organizationId = getFieldProps('organisation').value?.value;
      const location = getFieldProps('Location').value;
      const type = getFieldProps('type').value;
      const chatDisable = getFieldProps('chatDisable').value;
      const videoCallDisable = getFieldProps('videoCallDisable').value;

      if (image) {
        const storageRef = ref(storage, `facilities/${facilityRef.id}/facilityImgUrl`);
        const metadata = {
          contentType: 'image/jpeg'
        };
        const uploadTask = uploadBytesResumable(storageRef, image, metadata);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => NotificationManager.error(error.message),
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((avatarUrl) => {
              addFacility(
                facilityRef,
                name,
                contact,
                location,
                capacity,
                avatarUrl,
                organization,
                type,
                organizationId,
                chatDisable,
                videoCallDisable
              )
                .then((res) => {
                  console.log(res);
                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Facility Added Successfully',
                    showConfirmButton: false,
                    timer: 1500
                  });
                  navigate('/home/groups', { replace: true });
                })
                .catch((err) => console.log(err));
            });
          }
        );
      } else {
        const avatarUrl = null;
        addFacility(
          facilityRef,
          name,
          contact,
          location,
          capacity,
          avatarUrl,
          organization,
          type,
          organizationId,
          chatDisable,
          videoCallDisable
        )
          .then((res) => {
            console.log(res);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Facility Added Successfully',
              showConfirmButton: false,
              timer: 1500
            });
            navigate('/home/groups', { replace: true });
          })
          .catch((err) => console.log(err));
        // });
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  //------------------------------Google places---
  const { ref: materialRef } = usePlacesWidget({
    // apiKey: 'AIzaSyDWeMrcmMOtW-QlR2xJjwpdbWxyXkQamQY',
    apiKey: GOOGLE_PLACES_API_KEY,
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: 'Aus' }
    },
    onPlaceSelected: (place) => {
      setFieldValue('Location', place.formatted_address);
    }
  });
  //------------------------------Google places---

  function handleClearOrg() {
    // setFieldValue('organisation', { title: '', value: '' });
    console.log('clear icon');
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <InputMask
              mask="(+61) 999 999 999"
              {...getFieldProps('contact')}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  fullWidth
                  label="Contact"
                  {...getFieldProps('contact')}
                  error={Boolean(touched.contact && errors.contact)}
                  helperText={touched.contact && errors.contact}
                />
              )}
            </InputMask>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Address"
              placeholder=" "
              {...getFieldProps('Location')}
              error={Boolean(touched.Location && errors.Location)}
              helperText={touched.Location && errors.Location}
              inputRef={materialRef}
            />
            {/* <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                fontSize: '16px',
                color: '#212F3D'
              }}
            >
              {location && <p style={{ marginLeft: '10px', fontSize: '12px' }}>Location</p>}
              <Autocomplete
                apiKey="AIzaSyDWeMrcmMOtW-QlR2xJjwpdbWxyXkQamQY"
                style={{
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '16px',
                  height: '55px',
                  color: '#212F3D',
                  border: '1px solid #BDC3C7',
                  paddingLeft: '10px'
                }}
                onPlaceSelected={(place) => {
                  console.log(place);
                  setLocation(place.formatted_address);
                  setLocationError(false);
                }}
                options={{
                  // types: ['(regions)'],
                  types: ['geocode', 'establishment'],
                  componentRestrictions: { country: 'Aus' }
                }}
                // defaultValue="Australia"
              />
              {locationError && (
                <p style={{ color: 'red', fontSize: '12px', marginLeft: '17px' }}>
                  Location is required
                </p>
              )}
            </span> */}
            {/* {location && (
              <TextField
                fullWidth
                style={{ marginTop: '17px' }}
                type="number"
                label="Resident Capacity"
                {...getFieldProps('capacity')}
                error={Boolean(touched.capacity && errors.capacity)}
                helperText={touched.capacity && errors.capacity}
              />
            )}
            {!location && ( */}
            <TextField
              fullWidth
              type="number"
              label="Number of Clients"
              {...getFieldProps('capacity')}
              error={Boolean(touched.capacity && errors.capacity)}
              helperText={touched.capacity && errors.capacity}
            />
            {/* )} */}
          </Stack>
          {/* -------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* <TextField
              fullWidth
              label="Organisation"
              {...getFieldProps('organisation')}
              error={Boolean(touched.organisation && errors.organisation)}
              helperText={touched.organisation && errors.organisation}
            /> */}
            {/* *********************************************************************************************
             *********************************************************************************************
             ********************************************************************************************* */}
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              open={openOrg}
              onOpen={() => {
                getOrganisation();
                setOpenOrg(true);
              }}
              onClose={() => {
                setOpenOrg(false);
              }}
              onChange={(e, value) => {
                setFieldValue('organisation', value);
              }}
              // defaultValue={{ title: getFieldProps('organisation').value ?? '', value: '0' }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option?.title}
              options={optionOrg}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organisation"
                  // {...getFieldProps('organisation')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingOrg ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={Boolean(touched.organisation && errors.organisation)}
                  helperText={touched.organisation && errors.organisation}
                />
              )}
            />
            {/* *********************************************************************************************
             *********************************************************************************************
             ********************************************************************************************* */}
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              onChange={(e, value) => {
                setFieldValue('type', value ?? '');
              }}
              defaultValue={getFieldProps('type').value ?? ''}
              getOptionLabel={(option) => option}
              options={[
                'Residential Aged Care',
                'Retirement Communities',
                'Home Care',
                'Disability Care'
              ]}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Type"
                  {...getFieldProps('type').value}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                />
              )}
            />
          </Stack>
        <>
        <FormControlLabel
            control={<Checkbox />}
            label="Disable messaging"
            {...getFieldProps('chatDisable')}
            onChange={(e, value) => setFieldValue('chatDisable', value)}
          />
          <FormControlLabel
          style={{marginTop:"2px"}}
            control={<Checkbox />}
            label="Disable video calls"
            {...getFieldProps('videoCallDisable')}
            onChange={(e, value) => setFieldValue('videoCallDisable', value)}
          />
          </>
          {/* -------------------------------------------------------------------------------------------- */}
          <Grid container spacing={2}>
            {image && (
              <Grid item xs={4} sm={4} md={2}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle alt="Image" src={URL.createObjectURL(image)} />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label">
              Upload Image
              <input
                fullWidth
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Add Group
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
