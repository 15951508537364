import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import UpdateForm from '../components/authentication/register/UpdateForm';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 0)
}));

// ----------------------------------------------------------------------

export default function UpdateUser() {
  const locations = useLocation();
  const id = locations.state.userId;
  const { name, contact, location, capacity, avatarUrl, organization, type, organizationId, chatDisableUpdate ,videoCallDisable, object} =
    locations.state;
  return (
    <RootStyle title="Update facility | Rel8">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Update Group
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>Type detail here </Typography> */}
          </Box>

          <UpdateForm
            id={id}
            name={name}
            contact={contact}
            location={location}
            capacity={capacity}
            avatarUrl={avatarUrl}
            organization={organization}
            type={type}
            organizationId={organizationId}
            chatDisableUpdate={chatDisableUpdate}
            videoCallDisableUpdate={videoCallDisable}
          />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
