import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
// components
import Page from '../../components/Page';
import FacilityMoreInfoDetail from '../../components/_dashboard/FaciltiyMembers/MoreInfo/FacilityMoreInfoDetail';
import ResidentMoreInfoDetail from '../../components/_dashboard/resident/MoreInfo/ResidentMoreInfoDetail';
import { localUser } from 'src/App';
import ResidentMoreInfoSF from 'src/components/_dashboard/resident/MoreInfo/ResidentMoreInfoSF';
import ProfileIcon from "src/assest/profile-icon.svg"
import { MHidden } from 'src/components/@material-extend';
import Label from 'src/components/Label';
import moment from 'moment';
import ResidentMoreMenu from 'src/components/_dashboard/user/ResidentMoreMenu';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
  //   padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ResidentInfoPage() {
  const location = useLocation();
  const { row } = location.state;
  console.log(row)

  //   console.log(row);

  return (
    <RootStyle title="Facility Details | Rel8">
      <Container style={{ paddingLeft: "0px" }}>
        <ContentStyle>
          <Stack direction="row" alignItems="center" justifyContent="space-between" px={0} mb={2}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                gap: '5px',
                color: '#9A76F5',
                marginBottom: "20px"
              }}
            >
              <img
                src={ProfileIcon}
                style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
              />
              <MHidden width="mdDown">
                <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500, textTransform: "capitalize" }}>
                  {row.firstName}'s profile
                </p>
              </MHidden>

              <MHidden width="mdUp">
                <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '16px', fontWeight: 500, textTransform: "capitalize" }}>
                  {row.firstName}'s profile
                </p>
              </MHidden>
            </Box>
            <ResidentMoreMenu
              id={row?.id}
              firstName={row?.firstName}
              likes={row?.likes}
              dislikes={row?.dislikes}
              aboutMe={row?.aboutMe}
              lastName={row?.lastName}
              dateOfBirth={row?.dateOfBirth}
              facilityId={row?.facilityId}
              facilityName={row?.facilityName}
              roomId={row?.roomId}
              roomName={row?.roomName}
              // capacity={capacity}
              createdAt={row?.createdAt}
              includedInAdminStories={row?.includedInAdminStories}
              profileImgUrl={row?.profileImgUrl}
              deletedAt={row?.deletedAt ?? null}
              nextOfKin={row?.nextOfKin ?? null}
            />


          </Stack>
          <MHidden width="mdDown">
            <Card
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
                display: 'flex',
                width: 'full',
                paddingY: '25px',
                paddingX: '40px',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', width: '50%' }}>
                {row?.profileImgUrl ? (
                  <Avatar
                    src={row?.profileImgUrl}
                    alt="photoURL"
                    sx={{ width: '120px', height: '120px' }}
                  />
                ) : (
                  <Avatar
                    src=""
                    sx={{
                      backgroundColor: '#9A76F5',
                      fontWeight: 400,
                      width: '120px',
                      height: '120px',
                      fontSize: '24px'
                    }}
                  >
                    {row?.firstName?.slice(0, 1)?.toUpperCase()}
                    {row?.lastame?.slice(0, 1)?.toUpperCase()}
                  </Avatar>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <Box sx={{ fontSize: '16px' }}>
                    {row?.firstName} {row?.lastName}
                  </Box>
                  <Box sx={{ fontSize: '18px', color: '#9A76F5' }}>
                    {row?.facilityName ?? '-'}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '100px',
                  width: '50%',
                  height: '100%'
                }}
              >
                {/* {localUser.role == 'admin' || localUser.role == 'Facility Member' && (
                 
                )} */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                    gap: '15px'
                  }}
                >
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Date of Birth</Box>
                    <Box sx={{ fontSize: '16px' }}>{moment(new Date(row?.dateOfBirth.seconds * 1000)).format('DD/MM/YYYY')}</Box>
                  </Box>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Client Location</Box>
                    <Box sx={{ fontSize: '16px' }}>
                      {row?.roomName}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: "column",
                    gap: '15px',
                    height: '100%'
                  }}
                >
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Group Stories</Box>
                    <Box sx={{ fontSize: '16px' }} >
                      {row?.includedInAdminStories ?
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#3ad638", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          Yes
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#e84f51", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          No
                        </div>}
                    </Box>
                  </Box>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Status</Box>
                    <Box sx={{ fontSize: '16px' }} >
                      {row?.deletedAt == null ?
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#3ad638", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          Active
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#e84f51", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          Pending
                        </div>}
                    </Box>
                  </Box>
                </Box>
              </Box>

            </Card>
          </MHidden>

          <MHidden width="mdUp">
            <Card
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
                display: 'flex',
                flexDirection: 'column',
                width: 'full',
                paddingY: '25px',
                paddingX: '15px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '30px',
                marginBottom: '20px'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', width: '100%' }}>
                {row?.profileImgUrl ? (
                  <Avatar
                    src={row?.profileImgUrl}
                    alt="photoURL"
                    sx={{ width: '60px', height: '60px' }}
                  />
                ) : (
                  <Avatar
                    src=""
                    sx={{
                      backgroundColor: '#9A76F5',
                      fontWeight: 400,
                      width: '60px',
                      height: '60px',
                      fontSize: '16px'
                    }}
                  >
                    {row?.firstName?.slice(0, 1)?.toUpperCase()}
                    {row?.lastame?.slice(0, 1)?.toUpperCase()}
                  </Avatar>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <Box sx={{ fontSize: '16px' }}>
                    {row?.firstName} {row?.lastName}
                  </Box>
                  <Box sx={{ fontSize: '18px', color: '#9A76F5' }}>
                    {row?.facilityName ?? '-'}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  gap: '30px',
                  width: '100%',
                  justifyContent: 'space-around'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                    gap: '10px'
                  }}
                >
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Date of Birth</Box>
                    <Box sx={{ fontSize: '16px' }}>{moment(new Date(row?.dateOfBirth.seconds * 1000)).format('DD/MM/YYYY')}</Box>
                  </Box>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Client Location</Box>
                    <Box sx={{ fontSize: '16px' }}>
                      {row?.roomName}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  justifyContent: 'space-between',
                  gap: '10px'
                }}>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Group Stories</Box>
                    <Box sx={{ fontSize: '16px' }} >
                      {row?.includedInAdminStories ?
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#3ad638", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          Yes
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#e84f51", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          No
                        </div>}
                    </Box>

                  </Box>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5' }}>Status</Box>
                    {/* <Box sx={{ fontSize: '16px' }}>{localUser.status.toUpperCase() ?? ''}</Box> */}
                    <Box sx={{ fontSize: '16px' }} >
                      {row?.deletedAt == null ?
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#3ad638", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          Active
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#e84f51", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          Pending
                        </div>}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Card>
          </MHidden>
          <ResidentMoreInfoSF />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
