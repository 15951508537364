import { useState } from 'react';
import { Avatar, Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import MainCard from './MoreInfo/MainCard';
import Residents from '../facilities/Resident';
import ProfileIcon from '../../../assest/profile-icon.svg';
import { MHidden } from 'src/components/@material-extend';
import { Label } from '@mui/icons-material';
import MoreInfoResidents from '../facilities/MoreInfoResidents';

export default function FamilyMoreInfo() {
  const location = useLocation();
  const { row, residents } = location.state;
  console.log(row)
  // console.log(row?.residentIds[0]);

  return (
    <Grid container spacing={12}>
      <Grid item xs={12} sm={12} md={12}>
        <MoreInfoResidents id={row?.residentIds ?? residents ?? []} name={row.firstname} />
      </Grid>
    </Grid >
  );
}
