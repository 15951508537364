import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import closeOutline from '@iconify/icons-eva/close-outline';
import cloudUploadFill from '@iconify/icons-eva/cloud-upload-fill';
import cloudDownloadFill from '@iconify/icons-eva/cloud-download-fill';
import Tooltip from '@mui/material/Tooltip';

import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Card,
  Box,
  styled
} from '@mui/material';
import moment from 'moment';

import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

import { useCSVReader } from 'react-papaparse';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import { collection, getDocs, doc, setDoc, query, where } from 'firebase/firestore';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { db } from '../../../App';
import ResidentCSV from '../../../assest/Resident_csv_sample.csv';
import CsvUploadFunc from './CsvUploadFunc';
import SortByTitle from '../event/SortByTitle';
import axios from 'axios';
import { cloudFuncBaseUrl } from 'src/utils/config';

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const DefaultLocation = { lat: 10, lng: 106 };
const DefaultZoom = 10;
export default function ResidentForm() {
  const location = useLocation();
  const { data } = location.state ?? { id: '', name: '' };

  const navigate = useNavigate();
  const [openKin, setOpenKin] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const [value, setValue] = useState(new Date());
  // for facility capicity
  const [facilityFC, setFacilityFC] = useState('');
  const [facilityError, setfacilityError] = useState(false);

  const [Rooms, setRooms] = useState({ title: '', value: '' });

  const [openRoom, setOpenRoom] = useState(false);
  const [roomOptions, setRoomOptions] = useState([]);
  const loadingRooms = openRoom && roomOptions.length === 0;

  // Selected facility check capicity
  useEffect(async () => {
    const facilitesCollection = query(collection(db, 'facilites'), where('id', '==', facilityFC));
    const facilitesPromise = await getDocs(facilitesCollection);
    const fac = [];
    facilitesPromise.forEach((e) => {
      fac.push(e.data());
    });

    const residentsCollection = query(
      collection(db, 'residents'),
      where('facilityId', '==', facilityFC)
    );
    const residentsPromise = await getDocs(residentsCollection);
    const opt = [];
    residentsPromise.forEach((result) => {
      opt.push(result.data());
    });
    if (opt.length >= parseInt(fac[0]?.capacity, 10)) {
      setfacilityError(true);
      return Swal.fire({
        allowOutsideClick: false,
        icon: 'error',
        title: 'Oops...',
        text: 'This facility residents capacity full!'
      });
    }
    setfacilityError(false);
  }, [facilityFC]);

  // Facility
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        console.log("residents ids :: ", localUser?.facilityIds)
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = collection(db, 'facilites');
      }
      // const facilitesCollection = collection(db, 'facilites');
      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        // console.log(result.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // -------------Rooms Autocomplete--------------------------//
  useEffect(() => {
    let active = true;

    if (!loadingRooms) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      // const roomsCollection = collection(db, 'rooms');
      const roomsCollection = query(collection(db, 'rooms'), where('facilityId', '==', facilityFC));
      const roomsPromise = await getDocs(roomsCollection);
      const opt = [];
      roomsPromise.forEach((result) => {
        // console.log(result.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setRoomOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingRooms]);

  useEffect(() => {
    if (!openRoom) {
      setOptions([]);
    }
  }, [openRoom]);

  const phoneRegExp = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/;
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    // aboutMe: Yup.string().min(2, 'Too Short!').required('About me required'),
    aboutMe: Yup.string().min(2, 'Too Short!'),
    // like: Yup.string().min(2, 'Too Short!').required('Likes required'),
    like: Yup.string().min(2, 'Too Short!'),
    // dislike: Yup.string().min(2, 'Too Short!').required('Dislikes required'),
    dislike: Yup.string().min(2, 'Too Short!'),
    facility: Yup.string().required('Group is required.').nullable(),
    DOB: Yup.date().required('DOB is required'),
    room: Yup.string().required('Location is required.').nullable(),
    nextOfKin: Yup.string().nullable()
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      DOB: null,
      adminStory: true,
      facility: data?.id ?? '',
      facilityName: data?.name ?? '',
      room: '',
      roomName: '',
      photo: '',
      like: '',
      dislike: '',
      aboutMe: '',
      nextOfKin: null
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      setIsLoading(true);
      // if (!image) {
      //   setIsLoading(false);
      //   return NotificationManager.error("You didn't select image");
      // }
      const firstName = getFieldProps('firstName').value;
      const lastName = getFieldProps('lastName').value;
      const dateOfBirth = getFieldProps('DOB').value;
      const facilityId = getFieldProps('facility').value;
      const facilityName = getFieldProps('facilityName').value;
      const includedInAdminStories = getFieldProps('adminStory').value;
      const roomId = getFieldProps('room').value;
      const roomName = getFieldProps('roomName').value;
      const likes = getFieldProps('like').value;
      const dislikes = getFieldProps('dislike').value;
      const aboutMe = getFieldProps('aboutMe').value;
      const storage = getStorage();
      const residentsRef = doc(collection(db, 'residents'));
      if (facilityError === true) {
        setIsLoading(false);
        return Swal.fire({
          allowOutsideClick: false,
          icon: 'error',
          title: 'Error',
          text: 'Please select another facility!'
        });
      }

      if (image) {
        const storageRef = ref(storage, `residents/${residentsRef.id}/profile`);
        const metadata = {
          contentType: 'image/*'
        };
        const uploadTask = uploadBytesResumable(storageRef, image, metadata);
        setTimeout(() => {
          NotificationManager.warning('Please wait! File is being upload');
        }, 10000);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => NotificationManager.error(error.message),
          () => {
            // Upload completed successfully, now we can get the download URL
            getDownloadURL(uploadTask.snapshot.ref).then((profileImgUrl) => {
              setDoc(residentsRef, {
                id: residentsRef.id,
                firstName,
                likes,
                dislikes,
                aboutMe,
                lastName,
                dateOfBirth,
                facilityId,
                facilityName,
                roomId,
                roomName,
                includedInAdminStories,
                profileImgUrl,
                deletedAt: null,
                createdAt: new Date()
              })
                .then((res) => {
                  axios({
                    method: 'post',
                    url: `${cloudFuncBaseUrl}.cloudfunctions.net/addResidentAndUpdateUsers`,
                    data: {
                      fid: facilityId,
                      rid: residentsRef.id
                    }
                  });

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Resident Added Successfully',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  navigate('/administration/clients', { replace: true });
                })
                .catch((err) => console.log(err));
            });
          }
        );
      } else {
        // For No Image
        setDoc(residentsRef, {
          id: residentsRef.id,
          firstName,
          likes,
          dislikes,
          aboutMe,
          lastName,
          dateOfBirth,
          facilityId,
          facilityName,
          roomId,
          roomName,
          includedInAdminStories,
          profileImgUrl: null,
          deletedAt: null,
          createdAt: new Date()
        })
          .then((res) => {
            axios({
              method: 'post',
              url: `${cloudFuncBaseUrl}.cloudfunctions.net/addResidentAndUpdateUsers`,
              data: {
                fid: facilityId,
                rid: residentsRef.id
              }
            });
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Resident Added Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            navigate('/administration/clients', { replace: true });
          })
          .catch((err) => console.log(err));
      }
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched
  } = formik;

  // ----------------------------------- import csv ---------------------------------------------------
  // ----------------------------------- import csv ---------------------------------------------------
  // ----------------------------------- import csv ---------------------------------------------------
  const [csvResult, setcsvResult] = useState(false);
  const [csvData, setcsvData] = useState([]);
  const [csvFile, setcsvFile] = useState('');

  const BulkUploadFunc = async () => {
    try {
      const data = csvData;
      const a = [''];

      // make header
      const headers = data && {
        firstName: data[0][0],
        lastName: data[0][1],
        facility: data[0][2],
        facilityId: data[0][3],
        room: data[0][4],
        roomId: data[0][5],
        dob: data[0][6],
        likes: data[0][7],
        dislikes: data[0][8],
        aboutMe: data[0][9],
        adminStory: data[0][10]
      };
      console.log(headers);

      // check header same or not
      if (
        headers.firstName !== 'Firstname' ||
        headers.lastName !== 'Lastname' ||
        headers.facility !== 'Facility' ||
        headers.facilityId !== 'Facility Id' ||
        headers.room !== 'Room' ||
        headers.roomId !== 'Room Id' ||
        headers.dob !== 'DOB' ||
        headers.likes !== 'Likes' ||
        headers.dislikes !== 'Dislikes' ||
        headers.aboutMe !== 'Aboutme' ||
        headers.adminStory !== 'Group Story'
      ) {
        console.log('error-1');

        return NotificationManager.error('Please follow or download the sample csv pattern');
      }

      data.shift();
      if (JSON.stringify(data[data.length - 1]) === JSON.stringify(a)) {
        data.pop();
      }

      if (data.length === 0) {
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please fill all require fields'
        });
      }

      // check empty fields and return error
      for (let e = 0; e <= data.length - 1; e += 1) {
        if (data[e]?.length === 0) {
          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please fill all require fields'
          });
        }

        if (
          !data[e][0] ||
          !data[e][1] ||
          !data[e][2] ||
          !data[e][3] ||
          !data[e][4] ||
          !data[e][5] ||
          !data[e][6]
        ) {
          console.log('error-2');
          return Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please fill all require fields'
          });
        }
      }

      // check cloumn name for upload data
      if (
        headers.firstName === 'Firstname' &&
        headers.lastName === 'Lastname' &&
        headers.facility === 'Facility' &&
        headers.facilityId === 'Facility Id' &&
        headers.room === 'Room' &&
        headers.roomId === 'Room Id' &&
        headers.dob === 'DOB' &&
        headers.likes === 'Likes' &&
        headers.dislikes === 'Dislikes' &&
        headers.aboutMe === 'Aboutme' &&
        headers.adminStory === 'Group Story'
      ) {
        // -------------------------loading---------------------
        let timerInterval;
        Swal.fire({
          title: 'Uploading...',
          html: 'I will close in few<b></b> Moments.',
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
            if (b) {
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer');
          }
        });
        // -------------------------loading---------------------
        // upload data for one by one
        data?.forEach(async (e) => {
          const residentsRef = doc(collection(db, 'residents'));

          let includedInAdminStories = false;
          if (e[10] === 'TRUE') {
            includedInAdminStories = true;
          }

          const d = await setDoc(residentsRef, {
            id: residentsRef.id,
            firstName: e[0],
            lastName: e[1],
            facilityName: e[2],
            facilityId: e[3],
            roomName: e[4],
            roomId: e[5],
            dateOfBirth: moment(e[6], 'DD/MM/YYYY').toDate(),
            likes: e[7] ?? '',
            dislikes: e[8] ?? '',
            aboutMe: e[9] ?? '',
            includedInAdminStories,
            profileImgUrl: null,
            deletedAt: null,
            createdAt: new Date()
          });
        });
        // -------------------File upload resident csv------------
        const fileRef = doc(collection(db, 'residentsCsvFiles'));

        const storage = getStorage();
        const storageRef = ref(storage, `residentsCsvFiles/${fileRef?.id}/residentCSV.csv`);
        const metadata = {
          contentType: 'application/*'
        };
        const uploadTask = await uploadBytesResumable(storageRef, csvFile, metadata);
        const downloadURL = await getDownloadURL(uploadTask.ref);

        await setDoc(fileRef, {
          id: fileRef.id,
          downloadURL,
          createdAt: new Date(),
          size: data.length
        });

        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Residents Added Successfully',
          showConfirmButton: false,
          timer: 2000
        });
        navigate('/administration/clients', { replace: true });
      } else {
        console.log('error-4');

        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please follow the sample csv pattern'
        });
        return window.location.reload();
      }
    } catch (err) {
      console.log('catch error');
      setcsvData([]);
      setcsvResult(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.message
      });
    }
  };

  // ----------------------------------- import csv -----------------------------------

  return (
    <FormikProvider value={formik}>
      {/* ----------------------------------------------------------- */}
      {/*                    CSV file reader                          */}
      {/* ----------------------------------------------------------- */}
      <CsvUploadFunc
        setcsvData={setcsvData}
        setcsvResult={setcsvResult}
        csvResult={csvResult}
        BulkUploadFunc={BulkUploadFunc}
        csvData={csvData}
        setcsvFile={setcsvFile}
      />

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NotificationContainer />
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                First name
              </div>
              <TextField
                fullWidth
                // label="First Name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Last name
              </div>
              <TextField
                fullWidth
                // label="Last Name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Box>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Group
              </div>
              <Autocomplete
                fullWidth
                id="asynchronous-demo"
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(e, value, reason) => {
                  setFacilityFC('');
                  setFieldValue('facilityName', value?.title || '');
                  setFieldValue('facility', value?.value || '');
                  setFacilityFC(value?.value);
                  setRoomOptions([]);
                  setFieldValue('roomName', '');
                  setFieldValue('room', '');
                }}
                defaultValue={{ title: data?.name ?? '', value: data?.id ?? '' }}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                getOptionLabel={(option) => option.title}
                options={options}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    label={(!open && (!getFieldProps('facility')?.value)) ? "Select Group" : undefined}
                    // label="Select Group"
                    {...getFieldProps('facility')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    error={Boolean(touched.facility && errors.facility)}
                    helperText={touched.facility && errors.facility}
                  />
                )}
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Location
              </div>
              <Autocomplete
                fullWidth
                id="asynchronous-demo"
                open={openRoom}
                disabled={!facilityFC ?? true}
                onOpen={() => {
                  setOpenRoom(true);
                }}
                onClose={() => {
                  setOpenRoom(false);
                }}
                onChange={(e, value) => {
                  setRooms(value)
                  setFieldValue('roomName', value?.title || '');
                  setFieldValue('room', value?.value || '');
                }}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                getOptionLabel={(option) => option.title}
                options={roomOptions}
                value={Rooms}
                loading={loadingRooms}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Location"
                    label={(!openRoom && (!getFieldProps('room')?.value)) ? "Select Location" : undefined}
                    {...getFieldProps('room')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingRooms ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    error={Boolean(touched.room && errors.room)}
                    helperText={touched.room && errors.room}
                  />
                )}
              />
            </Box>


          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Date Of Birth
              </div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  // label="Date Of Birth"
                  format="dd/MM/yyyy"
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  {...getFieldProps('DOB')}
                  onChange={(e, value) => setFieldValue('DOB', e || '')}
                  onChangeRaw={(e) => {
                    setFieldTouched('DOB', true, true);
                  }}
                  onOpen={() => {
                    setFieldTouched('DOB', true, true);
                  }}
                  onClose={() => {
                    if (getFieldProps('DOB').value == null) {
                      setFieldValue('DOB', '');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={Boolean(touched.DOB && errors.DOB)}
                      helperText={touched.DOB && errors.DOB}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>


            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Next of Kin
              </div>
              <Autocomplete
                fullWidth
                id="asynchronous-demo"
                open={openKin}
                // disabled={!facilityFC ?? true}
                onOpen={() => {
                  setOpenKin(true);
                }}
                onClose={() => {
                  setOpenKin(false);
                }}
                value={null}
                // onChange={(e, value) => {
                //   setFieldValue('roomName', value?.title || '');
                //   setFieldValue('room', value?.value || '');
                // }}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                getOptionLabel={(option) => option.title}
                options={[]}
                // value={Rooms}
                loading={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Select Location"
                    label={(!openKin && (!getFieldProps('nextOfKin')?.value)) ? "Select next of kin" : undefined}
                    {...getFieldProps('nextOfKin')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {false ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    error={Boolean(touched.nextOfKin && errors.nextOfKin)}
                    helperText={touched.nextOfKin && errors.nextOfKin}
                  />
                )}
              />
            </Box>
          </Stack>

          {/* ******************** Like and dislike  */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Likes
              </div>
              <TextField
                fullWidth
                // label="Likes"
                {...getFieldProps('like')}
                error={Boolean(touched.like && errors.like)}
                helperText={touched.like && errors.like}
              />
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
            >
              <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                Dislikes
              </div>
              <TextField
                fullWidth
                // label="Dislikes"
                {...getFieldProps('dislike')}
                error={Boolean(touched.dislike && errors.dislike)}
                helperText={touched.dislike && errors.dislike}
              />
            </Box>
          </Stack>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
          >
            <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
              About Me
            </div>
            <TextField
              fullWidth
              multiline
              minRows={3}
              maxRows={20}
              // label="About Me"
              {...getFieldProps('aboutMe')}
              error={Boolean(touched.aboutMe && errors.aboutMe)}
              helperText={touched.aboutMe && errors.aboutMe}
            />
          </Box>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Permission to include in group stories"
            {...getFieldProps('adminStory')}
            onChange={(e, value) => setFieldValue('adminStory', value)}
          />
          <Grid container spacing={2}>
            {image && (
              <Grid item xs={4} sm={4} md={2}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle alt="Image" src={URL.createObjectURL(image)} />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: 'center' }} variant="contained" component="label">
              <span style={{ fontSize: "28px", lineHeight: "30px", fontWeight: "500" }}>+</span>Add photo
              <input
                fullWidth
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>

          {/* <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          /> */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={() => {
              if (getFieldProps('DOB').value == null) {
                setFieldValue('DOB', '');
              }
            }}
            loading={isLoading}
          >
            Add Client
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
