import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import filePNG from '../../../assest/file.png';
import AviThumbnail from '../../../assest/aviThumbnail.png';
import HEICThumbnail from '../../../assest/heicThumbnail.png';
import MPEGThumbnail from '../../../assest/mpegThumbnail.png';
import AudioThumbnail from '../../../assest/audioThumbnail.png';
import Textthumbnail from '../../../assest/textThumbnail.png';
import PdfThumbnail from '../../../assest/pdfThumbnail.webp';
import ShopProductCard from '../../../components/_dashboard/products/ProductCard';
import BackButtonImage from '../../../assest/backButton.svg';


const ProductDocumentStyle = styled('div')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// material
import {
  Stack,
  TextField,
  Card,
  Grid,
  Button,
  CircularProgress,
  Autocomplete,
  ClickAwayListener,
  AutocompleteCloseReason,
  Popper,
  Divider,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import moment from 'moment';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { DateTimePicker, DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
// import { DesktopDatePicker  } from '@mui/x-date-pickers/DesktopDatePicker ';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box } from '@mui/system';

import { db } from '../../../App';
import { uploadFile } from '../user/UploadFile';
import LocationGoogle from './LocationGoogle';
import EventPreview from './EventPreview';
import FileView from './FileView';
import SortByTitle from './SortByTitle';
import { useLocation } from 'react-router-dom';
import GooglePlacesAutocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_PLACES_API_KEY } from 'src/utils/config';
import { ConsoleSqlOutlined } from '@ant-design/icons';
// ----------------------------------------------------------------------

const localUser = JSON.parse(localStorage.getItem('Rel8User'));

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxOutlinedIcon fontSize="small" />;
const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`
}));

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function EventForm({ setSelectOption, selectOption }) {
  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [file, setFile] = useState([]);

  const [preview, setPreview] = useState(false);
  const [previewError, setpreviewError] = useState(false);

  // Facility
  const [facilityID, setFacilityID] = useState('');
  const [roomId, setRoomIds] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  // for empty change facility
  const [residentIDs, setresidentIDs] = useState([]);
  const [checkAllResi, setCheckAllResi] = useState(false);

  // Location
  // const [locations, setLocation] = useState('');
  // const [locationError, setLocationError] = useState(false);
  const [onceDone, setOnceDone] = useState(false);

  const location = useLocation();

  // ---------------------------for rooms and resident------------------------
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [openRoom, setOpenRoom] = useState(false);
  const [openFacility, setOpenFacility] = useState(false);
  const loadingRooms = openRoom && roomOptions.length === 0;
  const loadingFacility = openFacility && facilityOptions.length === 0;

  const loading = open && options.length === 0;

  // for rooms Id array for resident
  const IDROOM = [];
  roomId.forEach((e) => {
    IDROOM.push(e.value);
  });

  const images =
    file.length != 0
      ? file.filter((image) => {
        console.log('image :: ', image);
        const file =
          (image.type != 'image' && image.type != 'video') ||
          (image.name && image.name.endsWith('.avi')) ||
          (image.name && image.name.endsWith('.mpeg')) ||
          (image.name && image.name.endsWith('.heic'));
        const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
        if (type != 'file') {
          return image;
        }
      })
      : [];

  // -------------------------resident Autocomplete----------------------------
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const residentsCollection = query(collection(db, 'residents'), where('roomId', 'in', IDROOM));
      const residentsPromise = await getDocs(residentsCollection);
      const opt = [];
      residentsPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: `${result.data().firstName} ${result.data().lastName}`
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  ///duplicate
  ///duplicate
  ///duplicate
  ///duplicate

  useEffect(() => {
    if (location.state && !onceDone) {
      console.log('location :: ', location.state);

      if (roomId.length == 0) {
        (async () => {
          // await sleep(1e3); // For demo purposes.
          const roomsCollection = query(
            collection(db, 'rooms'),
            where('id', 'in', location.state.props.roomsId)
          );
          const roomsPromise = await getDocs(roomsCollection);
          const opt = [];
          roomsPromise?.forEach((result) => {
            // console.log(result?.data());
            opt.push({
              value: result.data().id,
              title: result.data().name
            });
          });

          // console.log(opt);
          const optArr = SortByTitle(opt);
          setRoomIds(optArr);
          setFieldValue('roomsId', optArr);
        })();
      }
      if (roomId.length != 0) {
        (async () => {
          // await sleep(1e3); // For demo purposes.
          const residentsCollection = query(
            collection(db, 'residents'),
            where('id', 'in', location.state.props.residentsId)
          );
          const residentsPromise = await getDocs(residentsCollection);
          const opt = [];
          residentsPromise.forEach((result) => {
            opt.push({
              value: result.data().id,
              title: `${result.data().firstName} ${result.data().lastName}`
            });
          });

          const optArr = SortByTitle(opt);

          setOptions([...optArr]);
          setresidentIDs(optArr);
          setFieldValue('residentsId', optArr);
          setOnceDone(true);
        })();
      }

      let startDate = new Date(location.state.props.startDate.seconds * 1000);
      const [startHours, startMinutes] = location.state.props.startTime.split(':').map(Number);
      const adjustedStartHours =
        location.state.props.startTimePeriod === 'pm' ? (startHours % 12) + 12 : startHours % 12;
      startDate.setHours(adjustedStartHours, startMinutes);

      let endDate = new Date(location.state.props.endDate.seconds * 1000);
      const [endHours, endMinutes] = location.state.props.endTime.split(':').map(Number);
      const adjustedEndHours =
        location.state.props.endTimePeriod === 'pm' ? (endHours % 12) + 12 : endHours % 12;
      endDate.setHours(adjustedEndHours, endMinutes);

      setFieldValue('title', location.state.props.title ? location.state.props.title : '');
      setFieldValue('Location', location.state.props.location ? location.state.props.location : '');
      setFieldValue(
        'description',
        location.state.props.description ? location.state.props.description : ''
      );
      setFieldValue('start', location.state.props.startDate.seconds ? startDate : '');
      setFieldValue('end', location.state.props.endDate.seconds ? endDate : '');
      setFieldValue(
        'facilityName',
        location.state.props.facilityName ? location.state.props.facilityName : ''
      );
      setFieldValue(
        'facility',
        location.state.props.facilitiesId ? location.state.props.facilitiesId : ''
      );
      setFacilityID(location.state.props.facilitiesId ? location.state.props.facilitiesId : '');

      // for check all empty
      setCheckAll(false);
      setCheckAllResi(false);
      setFile(location.state.props.medias);
    }
  }, [roomId]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // -------------Rooms Autocomplete--------------------------//
  useEffect(() => {
    let active = true;

    if (!loadingRooms) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      const roomsCollection = query(collection(db, 'rooms'), where('facilityId', '==', facilityID));
      const roomsPromise = await getDocs(roomsCollection);
      const opt = [];
      roomsPromise?.forEach((result) => {
        // console.log(result?.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        // console.log(opt);
        const optArr = SortByTitle(opt);
        setRoomOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingRooms]);

  useEffect(() => {
    if (!openRoom) {
      setRoomOptions([]);
    }
  }, [openRoom]);

  // -----------------------------get facility--------------------------
  useEffect(() => {
    let active = true;

    if (!loadingFacility) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.
      let facilitesCollection;
      if (localUser?.role === 'Facility Member') {
        facilitesCollection = query(
          collection(db, 'facilites'),
          where('id', 'in', localUser?.facilityIds)
        );
      } else {
        facilitesCollection = collection(db, 'facilites');
      }

      const facilityPromise = await getDocs(facilitesCollection);
      const opt = [];
      facilityPromise.forEach((result) => {
        // console.log(result.data());
        opt.push({
          value: result.data().id,
          title: result.data().name
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setFacilityOptions([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingFacility]);

  useEffect(() => {
    if (!openFacility) {
      setFacilityOptions([]);
    }
  }, [openFacility]);

  // ---------------------------for rooms and resident-------------------------------------------

  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  };

  const handleFileUpload = async (e) => {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      arr.push(e.target.files[i]);
    }
    const obj = [];

    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      if (size / 1024 > 20480) {
        e.target.value = "";
        return NotificationManager.error('File size is greater than 20 mb');
      }
      if (arr.length + file.length > 30) {
        e.target.value = "";
        return NotificationManager.error('maximum 30 files to upload');
      }
      // const downloadUrl = Object.entries(arr).map((ei) => URL.createObjectURL(ei[1]));
      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (mediaTypeLocal !== 'application' && !arr[i].name.endsWith('.mpeg')) {
        if (mediaTypeLocal === 'video' && !arr[i].name.endsWith('.avi')) {
          thumbnail = await generateVideoThumbnail(arr[i]);
        }
        if (arr[i].name.endsWith('.heic')) {
          thumbnail = HEICThumbnail;
        }
        if (arr[i].name.endsWith('.avi')) {
          thumbnail = AviThumbnail;
        }
        if (mediaTypeLocal === 'audio') {
          thumbnail = AudioThumbnail;
        }
        if (mediaTypeLocal === 'text') {
          thumbnail = Textthumbnail;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl: downloadUrl,
          thumbnail: thumbnail,
          name: arr[i]?.name,
          size: size,
          type: mediaTypeLocal
        });
      } else {
        if (arr[i].name.endsWith('.pdf')) {
          thumbnail = PdfThumbnail;
        } else if (arr[i].name.endsWith('.mpeg')) {
          thumbnail = MPEGThumbnail;
        } else {
          thumbnail = filePNG;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl: downloadUrl,
          thumbnail: thumbnail,
          size: size,
          name: arr[i]?.name,
          type: 'file'
        });
      }
    }
    setFile([...file, ...obj]);
    e.target.value = "";
    // setFile(obj);
  };

  // Get today's date
  const today = new Date();

  // Get tomorrow's date
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Get the date after tomorrow
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(today.getDate() + 2);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    // location: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Location required'),
    description: Yup.string().required('Description is required.').nullable(),
    start: Yup.date().required('Start event is required'),
    end: Yup.date()
      .min(Yup.ref('start'), "End event can't be before Start date")
      .required('End event is required'),
    facility: Yup.string().required('Group is required').nullable(),
    Location: Yup.string().required('Address is required').nullable(),
    roomsId: Yup.array().min(1, 'at least 1 location is required').required('required'),
    residentsId: Yup.array().min(1, 'at least 1 client is required').required('required'),
    date: Yup.date().required('Date is required')
  });
  const RegisterSchemaDraft = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(60, 'Too Long!').required('Title is required'),
    // location: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Location required'),
    description: Yup.string().nullable(),
    start: Yup.date().nullable(),
    end: Yup.date().nullable(),
    facility: Yup.string().nullable(),
    Location: Yup.string().nullable(),
    roomsId: Yup.array().nullable(),
    residentsId: Yup.array().nullable(),
    date: Yup.date().nullable()
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      // location: '',
      start: null,
      end: null,
      description: '',
      photo: '',
      residentsId: [],
      roomsId: [],
      facility: '',
      Location: '',
      date: new Date()
    },
    validationSchema: draft ? RegisterSchemaDraft : RegisterSchema,
    onSubmit: async () => {
      if (previewError) {
        return setPreview(true);
      }
      await AddEventFunc(draft ? 1 : 0);
    }
  });
  const AddEventFunc = async (drft) => {
    const draft = drft ?? false;

    if (file.length > 30) {
      return NotificationManager.error('Maximum 30 files to upload');
    }
    if (draft) {
      setDraftLoading(true);
    } else {
      setIsLoading(true);
    }

    const title = getFieldProps('title').value;
    // const location = getFieldProps('location').value;
    const description = getFieldProps('description').value;
    const SDate = getFieldProps('start').value ?? new Date();
    const EDate = getFieldProps('end').value ?? new Date();
    const residents = getFieldProps('residentsId').value;
    const facilityName = getFieldProps('facilityName').value;
    const facilitiesId = getFieldProps('facility').value;
    const roomId = getFieldProps('roomsId').value;
    const date = getFieldProps('date').value;
    const locations = getFieldProps('Location').value;

    let eventsRef;
    if (draft) {
      eventsRef = doc(collection(db, 'drafts'));
    } else {
      eventsRef = doc(collection(db, 'events'));
    }

    // --------------resident array---------------
    const residentsId = [];
    residents.forEach((element) => {
      residentsId.push(element.value);
    });

    const roomsId = [];
    roomId.forEach((element) => {
      roomsId.push(element.value);
    });

    // --------------resident array---------------

    // const delimiter = ':';
    // const startTime = SDate.toLocaleTimeString().split(delimiter).slice(0, 2).join(delimiter);
    const startTime = moment(SDate).format('hh:mm');
    // const startTimePeriod = SDate.toLocaleTimeString()
    //   .split(delimiter)
    //   .slice(2)
    //   .join(delimiter)
    //   .slice(-2)
    //   .toLowerCase();
    const startTimePeriod = moment(SDate).format('a');
    // const endTime = EDate.toLocaleTimeString().split(delimiter).slice(0, 2).join(delimiter);
    const endTime = moment(EDate).format('hh:mm');
    const endTimePeriod = moment(EDate).format('a');
    setTimeout(() => {
      NotificationManager.warning('Please wait! File is being upload');
    }, 10000);
    let startDate = moment(SDate).set({ hour: 0, minute: 0, second: 0 });
    let endDate = moment(EDate).set({ hour: 0, minute: 0, second: 0 });
    startDate = startDate.toDate();
    endDate = endDate.toDate();
    const medias = [];
    let itemsProcessed = 0;
    if (file.length > 0) {
      file.forEach(async (element, index, array) => {
        const mediaId = uuidv4();
        if (element.type === 'video') {
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile('events', eventsRef, element.file, element.type, mediaId);
          const thumbnailId = uuidv4();
          const downloadUrlThumbnail = location.state
            ? element.thumbnail
            : await uploadFile('events', eventsRef, element.thumbnail, 'thumbnail', thumbnailId);
          medias.push({
            downloadUrl: downloadUrl ?? null,
            thumbnailId: thumbnailId ?? null,
            thumbnail: downloadUrlThumbnail ?? null,
            name: location.state ? element.name : element.file.name,
            type: element.type,
            size: Math.floor(element.size / 1024, 3) ?? null,
            id: mediaId ?? null
          });
        }

        if (element.type === 'image') {
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile('events', eventsRef, element.file, element.type, mediaId);
          medias.push({
            downloadUrl,
            thumbnailId: null,
            thumbnail: null,
            name: location.state ? element.name : element.file.name,
            type: element.type,
            size: null,
            id: mediaId
          });
        }

        if (element.type != 'image' && element.type != 'video') {
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile('chat', eventsRef, element.file, element.type, mediaId);
          const thumbnailId = uuidv4();
          medias.push({
            downloadUrl,
            thumbnailId: thumbnailId,
            thumbnail: element.thumbnail,
            name: element.name,
            type: element.type,
            size: Math.floor(element.size / 1024, 3),
            id: mediaId
          });
        }

        itemsProcessed += 1;
        if (itemsProcessed === array.length) {
          if (draft) {
            setDoc(eventsRef, {
              title,
              id: eventsRef.id,
              type: 'events',
              createdAt: date,
              creatorId: JSON.parse(localStorage.getItem('Rel8User'))?.id,
              object: {
                id: eventsRef.id,
                title,
                location: locations,
                description,
                startDate,
                startTime,
                startTimePeriod,
                endDate,
                endTime,
                endTimePeriod,
                medias,
                postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
                authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                  }`,
                createdAt: date,
                likes: [],
                roomsId: roomsId ?? [],
                facilitiesId: facilitiesId ?? '',
                facilityName: facilityName ?? '',
                residentsId: residentsId ?? [],
                commentsCount: 0
              }
            })
              .then((res) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Draft saved Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/drafts', { replace: true });
              })
              .catch((err) => console.log(err));
          } else {
            setDoc(eventsRef, {
              id: eventsRef.id,
              title,
              location: locations,
              description,
              startDate,
              startTime,
              startTimePeriod,
              endDate,
              endTime,
              endTimePeriod,
              medias,
              postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
              authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                }`,
              createdAt: date,
              likes: [],
              roomsId,
              facilitiesId,
              facilityName,
              residentsId,
              commentsCount: 0
            })
              .then((res) => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Event Added Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/events', { replace: true });
              })
              .catch((err) => console.log(err));

            const object = {
              id: eventsRef.id,
              title,
              location: locations,
              description,
              startDate,
              startTime,
              startTimePeriod,
              endDate,
              endTime,
              endTimePeriod,
              medias,
              postedById: JSON.parse(localStorage.getItem('Rel8User')).id,
              authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                }`,
              createdAt: date,
              likes: [],
              roomsId,
              facilitiesId,
              facilityName,
              residentsId,
              commentsCount: 0
            };
            // -----------------------------------------Comment Notifications------------------------------------
            const commentNotifiRef = doc(collection(db, 'notifications'));
            await setDoc(commentNotifiRef, {
              id: commentNotifiRef.id,
              body: `${JSON.parse(localStorage.getItem('Rel8User')).firstname
                } has created a new event`,
              commentId: eventsRef.id,
              createdAt: date,
              profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
              title: 'A new event',
              topic: `${facilitiesId}_event`,
              viewedBy: [],
              senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
              receiverId: '',
              object,
              senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
                }`,
              type: 'events'
            });
            // -----------------------------------------Comment Notifications------------------------------------
          }
        }
      });
    } else {
      if (draft) {
        return setDoc(eventsRef, {
          title,
          id: eventsRef.id,
          type: 'events',
          createdAt: date,
          // creatorId: 'admin',
          creatorId: JSON.parse(localStorage.getItem('Rel8User')).id,
          object: {
            id: eventsRef.id,
            title,
            location: locations,
            description,
            startDate,
            startTime,
            startTimePeriod,
            endDate,
            endTime,
            endTimePeriod,
            medias,
            postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
            authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
            createdAt: date,
            likes: [],
            roomsId: roomsId ?? [],
            facilitiesId: facilitiesId ?? '',
            facilityName: facilityName ?? '',
            residentsId: residentsId ?? [],
            commentsCount: 0
          }
        })
          .then((res) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Draft saved Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            setDraft(false);
            setDraftLoading(false);
            navigate('/home/drafts', { replace: true });
          })
          .catch((err) => console.log(err));
      }
      // } else {
      setDoc(eventsRef, {
        id: eventsRef.id,
        title,
        location: locations,
        description,
        startDate,
        startTime,
        startTimePeriod,
        endDate,
        endTime,
        endTimePeriod,
        medias,
        postedById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
        authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
        createdAt: date,
        likes: [],
        roomsId,
        facilitiesId,
        facilityName,
        residentsId,
        commentsCount: 0
      })
        .then((res) => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Event Added Successfully',
            showConfirmButton: false,
            timer: 2000
          });
          navigate('/home/events', { replace: true });
        })
        .catch((err) => console.log(err));
      // console.log(object);
      // console.log(eventsRef.id);
      const object = {
        id: eventsRef.id,
        title,
        location: locations,
        description,
        startDate,
        startTime,
        startTimePeriod,
        endDate,
        endTime,
        endTimePeriod,
        medias,
        postedById: JSON.parse(localStorage.getItem('Rel8User')).id,
        authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
        createdAt: date,
        likes: [],
        roomsId,
        facilitiesId,
        facilityName,
        residentsId,
        commentsCount: 0
      };
      // -----------------------------------------Comment Notifications------------------------------------
      const commentNotifiRef = doc(collection(db, 'notifications'));
      await setDoc(commentNotifiRef, {
        id: commentNotifiRef.id,
        body: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          } has created a new event`,
        commentId: eventsRef.id,
        createdAt: date,
        profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
        title: 'A new event',
        topic: `${facilitiesId}_event`,
        viewedBy: [],
        senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
        receiverId: '',
        object,
        senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
        type: 'events'
      });
      // -----------------------------------------Comment Notifications------------------------------------
    }
  };
  // }
  // });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched
  } = formik;

  // for room
  const handleClickAway = (e) => {
    setOpenRoom(false);
  };
  // for resident
  const handleClickAwayResi = (e) => {
    setOpen(false);
  };

  // -------for room
  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    // setCheckAll(true);
    if (event.target.checked) {
      setRoomIds(roomOptions);
      setFieldValue('roomsId', roomOptions);
    } else {
      setFieldValue('roomsId', []);
      setRoomIds([]);
    }
    setFieldValue('residentsId', []);
    setresidentIDs([]);
  };

  // -----For resident
  const checkAllChangeResi = (event) => {
    setCheckAllResi(event.target.checked);
    // setCheckAllResi(true);
    if (event.target.checked) {
      setresidentIDs(options);
      setFieldValue('residentsId', options);
    } else {
      setresidentIDs([]);
    }
  };

  //------------------------------Google places---
  const { ref: materialRef } = usePlacesWidget({
    apiKey: GOOGLE_PLACES_API_KEY,
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: 'Aus' }
    },
    onPlaceSelected: (place) => {
      setFieldValue('Location', place.formatted_address);
    }
  });

  return (
    <Box>
      {selectOption == 'preview' ? (
        <EventPreview
          file={file}
          title={{ ...getFieldProps('title') } ?? ''}
          description={{ ...getFieldProps('description') }}
          setPreview={setPreview}
          AddSubmitFunc={AddEventFunc}
          draftLoading={draftLoading}
          isLoading={isLoading}
          head1="Add Event"
          head2="Add Event"
          section='Event'
          setDraft={setDraft}
          handleSubmit={handleSubmit}
          date={getFieldProps('date').value}
          type="event"
          location={getFieldProps('Location').value}
          sdate={getFieldProps('start').value}
          edate={getFieldProps('end').value}
          setSelectOption={setSelectOption}
        />
      ) : (
        <Card
          sx={{
            marginTop: '',
            width: '100%',
            minWidth: '100%',
            paddingX: '20px',
            paddingY: '20px'
          }}
        >
          {selectOption != 'add' && (
            <div
              style={{ marginBottom: '12px' }}
              onClick={() => {
                if (selectOption == 'audience') {
                  setSelectOption('add');
                } else if (selectOption == 'schedule') {
                  setSelectOption('audience');
                }
              }}
            >
              <img src={BackButtonImage} style={{ width: '35px', cursor: 'pointer' }} />
            </div>
          )}
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <NotificationContainer />
              <Stack spacing={3}>
                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Title
                      </div>

                      <TextField
                        fullWidth
                        // label="Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Box>
                  </Stack>
                )}
                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Description
                    </div>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={20}
                      // label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Box>
                )}
                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Event Location
                      </div>

                      <TextField
                        fullWidth
                        // label="Address"
                        // placeholder=" "
                        {...getFieldProps('Location')}
                        error={Boolean(touched.Location && errors.Location)}
                        helperText={touched.Location && errors.Location}
                        inputRef={materialRef}
                      />
                    </Box>
                  </Stack>
                )}
                {selectOption == 'add' && (
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    sx={{ width: '100%', '& > *': { flex: 1 } }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          width: '100%'
                        }}
                      >
                        <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                          Start date/time
                        </div>
                        <DateTimePicker
                          format="dd/MM/yyyy hh:mm aa"
                          fullWidth
                          inputFormat="Start Event"
                          // label="Start Event"
                          {...getFieldProps('start')}
                          onChange={(e, value) => {
                            setFieldValue('start', e || '');
                          }}
                          onChangeRaw={(e) => {
                            setFieldTouched('start', true, true);
                          }}
                          onOpen={() => {
                            setFieldTouched('start', true, true);
                          }}
                          onClose={() => {
                            if (getFieldProps('start').value == null) {
                              setFieldValue('start', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={touched.start && errors.start}
                              helperText={touched.start && errors.start}
                            />
                          )}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          width: '100%'
                        }}
                      >
                        <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                          End date/time
                        </div>
                        <DateTimePicker
                          format="dd/MM/yyyy hh:mm aa"
                          // inputFormat="End Event"
                          // label="End Event"
                          {...getFieldProps('end')}
                          onChange={(e, value) => setFieldValue('end', e || '')}
                          onChangeRaw={(e) => {
                            setFieldTouched('end', true, true);
                          }}
                          onOpen={() => {
                            setFieldTouched('start', true, true);
                          }}
                          onClose={() => {
                            if (getFieldProps('end').value == null) {
                              setFieldValue('end', '');
                            }
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                        />
                      </div>
                    </LocalizationProvider>
                  </Stack>
                )}
                {selectOption == 'add' && (
                  <>
                    {true && (
                      <div className="grid">
                        {file.length > 0 &&
                          file.map((item, index) => {
                            return (
                              <>
                                {!(
                                  (item.type != 'image' && item.type != 'video') ||
                                  item.name.endsWith('.avi') ||
                                  item.name.endsWith('.mpeg')
                                ) && (
                                    <div
                                      key={item.id}
                                      style={{ position: 'relative' }}
                                      className={
                                        item === images[0] && images.length > 7
                                          ? 'firstIndex'
                                          : 'allIndex'
                                      }
                                    >
                                      {' '}
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '-3px',
                                          right: '-3px',
                                          zIndex: 100
                                        }}
                                      >
                                        <Icon
                                          icon="ci:close-big"
                                          onClick={() => deleteFile(index)}
                                          style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'white',
                                            borderRadius: '100%',
                                            outline: '1px',
                                            outlineColor: '#9A76F5'
                                          }}
                                        />
                                      </div>
                                      <Card>
                                        {/* <ProductImageStyle
                                          alt="Image"
                                          src={item.thumbnail ?? item.downloadUrl}
                                        /> */}
                                        <ShopProductCard img={item} Index={index} images={images} />
                                      </Card>
                                    </div>
                                  )}
                              </>
                            );
                          })}
                      </div>
                    )}
                    {true && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {file.length > 0 &&
                          file.map((item, index) => {
                            return (
                              <>
                                {((item.type != 'image' && item.type != 'video') ||
                                  item.name.endsWith('.avi') ||
                                  item.name.endsWith('.mpeg')) && (
                                    <Box
                                      sx={{
                                        maxWidth: '250px',
                                        width: 'full',
                                        display: 'flex',
                                        gap: '5px',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Icon
                                        icon="ci:close-big"
                                        onClick={() => deleteFile(index)}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <Box
                                        sx={{
                                          maxWidth: '50px',
                                          maxHeight: '60px',
                                          minWidth: '50px',
                                          minHeight: '60px',
                                          overflow: 'hidden'
                                        }}
                                      >
                                        <img
                                          src={item.thumbnail}
                                          style={{ width: '100%', object: 'contain', height: '100%' }}
                                        />
                                      </Box>
                                      <div style={{ fontSize: '12px', fontWeight: 500 }}>
                                        {item.name && item.name.length > 25
                                          ? item.name.slice(0, 22) + '...'
                                          : item.name}
                                        {' ('}
                                        {item.size ?? ''}
                                        {' KB)'}
                                      </div>
                                    </Box>
                                  )}
                              </>
                            );
                          })}
                      </Box>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Button variant="contained" component="label">
                          Add attachment
                          <input
                            type="file"
                            hidden
                            multiple
                            fullWidth
                            accept="image/*,video/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.mp3, .heic"
                            onChange={handleFileUpload}
                            error={Boolean(touched.photo && errors.photo)}
                            helperText={touched.photo && errors.photo}
                          />
                        </Button>
                      </Stack>
                      {/* {file.length <= 0 && Story && (
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          At least One Image is required
                        </p>
                      )} */}
                    </Box>
                  </>
                )}

                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Group
                      </div>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        open={openFacility}
                        onOpen={() => {
                          setOpenFacility(true);
                        }}
                        onClose={() => {
                          setOpenFacility(false);
                        }}
                        onChange={(e, value) => {
                          setFieldValue('facilityName', value?.title || '');
                          setFieldValue('facility', value?.value || '');
                          setFacilityID(value?.value);
                          // for empty fields
                          setFieldValue('roomsId', []);
                          setFieldValue('residentsId', []);
                          setRoomIds([]);
                          setresidentIDs([]);
                          // for check all empty
                          setCheckAll(false);
                          setCheckAllResi(false);
                        }}
                        defaultValue={{
                          title: location.state
                            ? location.state.props.facilityName
                            : getFieldProps('facilityName').value ?? '',
                          value: location.state
                            ? location.state.props.facilityIds
                            : getFieldProps('facility').value ?? ''
                        }}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        options={facilityOptions}
                        loading={loadingFacility}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            // label="Select Group"
                            // {...getFieldProps('facility')}
                            {...getFieldProps('facility')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loadingFacility ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                            error={Boolean(touched.facility && errors.facility)}
                            helperText={touched.facility && errors.facility}
                          />
                        )}
                      />
                    </div>
                  </Stack>
                )}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Location
                      </div>
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            multiple
                            disableCloseOnSelect
                            disabled={!facilityID ?? false}
                            limitTags={3}
                            id="asynchronous-demo"
                            open={openRoom}
                            onOpen={() => {
                              setOpenRoom(true);
                            }}
                            // onClose={(e, reason) => {
                            //   if (reason === 'escape') {
                            //     setOpenRoom(false);
                            //   }
                            // }}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'selectOption') {
                                setFieldValue('roomsId', newValue ?? '');
                                setRoomIds(newValue);
                              } else if (reason === 'removeOption') {
                                setFieldValue('roomsId', newValue ?? '');
                                setRoomIds(newValue);
                              } else if (reason === 'clear') {
                                setRoomIds([]);
                                setCheckAll(false);
                                setFieldValue('roomsId', []);
                              }
                              setFieldValue('residentsId', []);
                              setresidentIDs([]);
                              setCheckAllResi(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            options={roomOptions}
                            value={roomId}
                            loading={loadingRooms}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAll}
                                    onChange={checkAllChange}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  location.state && roomId.length == 0 && !onceDone && 'Loading...'
                                }
                                {...getFieldProps('roomsId')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingRooms ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.roomsId && errors.roomsId)}
                                helperText={touched.roomsId && errors.roomsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}
                {selectOption == 'audience' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Clients
                      </div>

                      <ClickAwayListener onClickAway={handleClickAwayResi}>
                        <div style={{ width: '100%' }}>
                          <Autocomplete
                            fullWidth
                            disableCloseOnSelect
                            multiple
                            disabled={IDROOM.length === 0 ?? false}
                            limitTags={3}
                            id="asynchronous-demo"
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onClose={() => {
                              setOpen(false);
                            }}
                            // onChange={(e, value) => {
                            //   setFieldValue('residentsId', value ?? '');
                            //   setresidentIDs(value);
                            // }}
                            onChange={(event, newValue, reason) => {
                              if (reason === 'selectOption') {
                                setFieldValue('residentsId', newValue ?? '');
                                setresidentIDs(newValue);
                              } else if (reason === 'removeOption') {
                                setCheckAllResi(false);
                                setFieldValue('residentsId', newValue ?? '');
                                setresidentIDs(newValue);
                              } else if (reason === 'clear') {
                                setresidentIDs([]);
                                setCheckAllResi(false);
                                setFieldValue('residentsId', []);
                              }
                            }}
                            isOptionEqualToValue={(option, value) => option.title === value.title}
                            options={options}
                            value={residentIDs}
                            loading={loading}
                            PopperComponent={(param) => (
                              <PopperStyledComponent {...param}>
                                <Box {...param} />
                                <Divider />
                                <Box
                                  sx={{
                                    backgroundColor: 'white',
                                    height: '45px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  <Checkbox
                                    checked={checkAllResi}
                                    onChange={checkAllChangeResi}
                                    id="check-all"
                                    sx={{ marginRight: '8px' }}
                                    onMouseDown={(e) => e.preventDefault()}
                                  />
                                  Select All
                                </Box>
                              </PopperStyledComponent>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={
                                  location.state &&
                                  residentIDs.length == 0 &&
                                  !onceDone &&
                                  'Loading...'
                                }
                                // {...getFieldProps('resident')}
                                {...getFieldProps('residentsId')}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  )
                                }}
                                error={Boolean(touched.residentsId && errors.residentsId)}
                                helperText={touched.residentsId && errors.residentsId}
                              />
                            )}
                          />
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Stack>
                )}

                {selectOption == 'schedule' && (
                  <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        width: '100%'
                      }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Date
                      </div>
                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          shouldDisableTime
                          format="dd/MM/yyyy"
                          inputFormat="dd/MM/yyyy"
                          // label="Date"
                          {...getFieldProps('date')}
                          onChange={(e, value) => setFieldValue('date', e || '')}
                          onChangeRaw={(e) => {
                            setFieldTouched('date', true, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date}
                            />
                          )}
                          // viewRenderers={{
                          //   hours: renderTimeViewClock,
                          //   minutes: renderTimeViewClock,
                          //   seconds: renderTimeViewClock
                          // }}
                          maxDate={new Date()}
                        />
                      </LocalizationProvider> */}
                       <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          format="dd/MM/yyyy"
                          inputFormat="dd/MM/yyyy"
                          label="Date"
                          {...getFieldProps('date')}
                          onChange={(e, value) => setFieldValue('date', e || '')}
                          onChangeRaw={(e) => {
                            setFieldTouched('date', true, true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date}
                            />
                          )}
                          maxDate={new Date()}
                        />
                      </LocalizationProvider>
                    </div>
                  </Stack>
                )}


                {/* <Grid container spacing={2}>
                  {file.map((item, index) => (
                    <Grid item xs={2} sm={2} md={2}>
                      {
                        <Icon
                          icon="ci:close-big"
                          onClick={() => deleteFile(index)}
                          style={{ cursor: 'pointer' }}
                        />
                      }
                      {(item.type != 'image' && item.type != 'video') ||
                      item.name.endsWith('.avi') ||
                      item.name.endsWith('.mpeg') ? (
                        <Card>
                          <Box
                            sx={{
                              pt: '100%',
                              position: 'relative'
                            }}
                          >
                            <ProductDocumentStyle
                              sx={{
                                opacity: '1',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                wordWrap: 'break-word',
                                height: '100%',
                                padding: '5px'
                              }}
                            >
                              <img src={item.thumbnail} style={{ width: '90%', height: '85%' }} />
                              <p style={{ fontSize: '10px' }}>
                                {item.name && item.name.length > 17
                                  ? item.name.slice(0, 15) + '...'
                                  : item.name}
                              </p>
                            </ProductDocumentStyle>
                          </Box>
                        </Card>
                      ) : (
                        <Card>
                          <Box sx={{ pt: '100%', position: 'relative', opacity: '1' }}>
                            <ProductImageStyle
                              alt="Image"
                              src={item.thumbnail ?? item.downloadUrl}
                            />
                          </Box>
                        </Card>
                      )}
                    </Grid>
                  ))}
                </Grid>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Button variant="contained" component="label">
                    Add Attachment
                    <input
                      type="file"
                      hidden
                      multiple
                      fullWidth
                      accept="image/*,video/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.mp3, .heic"
                      onChange={handleFileUpload}
                      error={Boolean(touched.photo && errors.photo)}
                      helperText={touched.photo && errors.photo}
                    />
                  </Button>
                </Stack> */}

                {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
                {/* <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  // loading={draftLoading}
                  onClick={() => {
                    if (getFieldProps('end').value == null) {
                      setFieldValue('end', '');
                    }
                    if (getFieldProps('start').value == null) {
                      setFieldValue('start', '');
                    }
                    setpreviewError(true);
                  }}
                >
                  Preview
                </LoadingButton> */}
                {/* </Stack> */}

                {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="outlined"
                    loading={draftLoading}
                    onClick={() => {
                      if (getFieldProps('end').value == null) {
                        setFieldValue('end', '');
                      }
                      if (getFieldProps('start').value == null) {
                        setFieldValue('start', '');
                      }
                      setDraft(true);
                    }}
                  >
                    Save Drafts
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      if (getFieldProps('end').value == null) {
                        setFieldValue('end', '');
                      }
                      if (getFieldProps('start').value == null) {
                        setFieldValue('start', '');
                      }
                    }}
                    loading={isLoading}
                  >
                    Add Event
                  </LoadingButton>
                </Stack> */}

                {(selectOption == 'add' ||
                  selectOption == 'audience' ||
                  selectOption == 'schedule') && (
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="outlined"
                        loading={draftLoading}
                        onClick={() => setDraft(true)}
                      >
                        Save drafts
                      </LoadingButton>
                      <LoadingButton
                        fullWidth
                        size="large"
                        // type="submit"
                        onClick={() => {
                          if (selectOption == 'add') {
                            if (!getFieldProps('title').value) {
                              setFieldTouched('title');
                            }
                            if (!getFieldProps('description').value) {
                              setFieldTouched('description');
                            }
                            if (!getFieldProps('Location').value) {
                              setFieldTouched('Location');
                            }
                            if (!getFieldProps('start').value) {
                              setFieldValue('start', '');
                            }
                            if (!getFieldProps('end').value) {
                              setFieldValue('end', '');
                            }
                            // setStory(true);

                            if (
                              getFieldProps('title').value &&
                              getFieldProps('description').value &&
                              getFieldProps('end').value &&
                              getFieldProps('start').value &&
                              getFieldProps('Location').value
                            ) {
                              setSelectOption('audience');
                            }
                          } else if (selectOption == 'audience') {
                            if (!getFieldProps('facility').value) {
                              setFieldTouched('facility');
                            }

                            if (getFieldProps('roomsId').value.length == 0) {
                              setFieldTouched('roomsId');
                            }
                            if (getFieldProps('residentsId').value.length == 0) {
                              setFieldTouched('residentsId');
                            }
                            if (
                              getFieldProps('facility').value &&
                              getFieldProps('roomsId').value.length > 0 &&
                              getFieldProps('residentsId').value.length > 0
                            ) {
                              setSelectOption('schedule');
                            }
                          } else if (selectOption == 'schedule') {
                            setSelectOption('preview');
                          }
                        }}
                        variant="contained"
                        loading={isLoading}
                      >
                        {selectOption == 'add'
                          ? 'Choose audience'
                          : selectOption == 'audience'
                            ? 'Schedule'
                            : 'Preview'}
                      </LoadingButton>
                    </Stack>
                  )}
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}
    </Box>
  );
}
