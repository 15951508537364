import { filter, forEach, result } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper
} from '@mui/material';
// components

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDocm,
  where,
  query,
  onSnapshot
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';
import Draggable from 'react-draggable';
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import DeletedTimeResi from '../../components/_dashboard/resident/DeletedTimeResi';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
//
import { db, localUser } from '../../App';
import ResidentMoreMenu from '../../components/_dashboard/user/ResidentMoreMenu';
import { LightBox } from '../../components/_dashboard/user/LightBox';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
// ------------------------End for popup box-------------------------

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'facilityName', label: 'Group', alignRight: false },
  { id: 'roomId', label: 'Location', alignRight: false },
  { id: 'createdAt', label: 'Date Added', alignRight: false },
  { id: 'adminStory', label: 'Group Story', alignRight: false },
  { id: 'deletedAt', label: 'Account Status', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Residents() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [NotFound, setNotFound] = useState(false);
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [USERLIST, setUSERLIST] = useState([]);

  const [getNotifyWellness, setgetNotifyWellness] = useState([]);

  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [modelRow, setModelRow] = useState('');

  // ------for lightbox
  const [image, setImage] = useState('');

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Facilites From Firebase**********************
  let userCollection;
  if (!location.state) {
    // for user role
    if (user?.role === 'admin') {
      userCollection = collection(db, 'residents');
    } else if (user?.role === 'Facility Member') {
      userCollection = query(
        collection(db, 'residents'),
        where('facilityId', 'in', user?.facilityIds ?? [''])
      );
    } else if (user?.role === 'Family Member') {
      userCollection = query(collection(db, 'residents'), where('id', 'in', user?.residentIds ?? ['']));
    }
    // for user role
  } else if (location.state.facilityId) {
    userCollection = query(
      collection(db, 'residents'),
      where('roomId', '==', location.state.roomId),
      where('facilityId', '==', location.state.facilityId)
    );
  } else {
    userCollection = query(
      collection(db, 'residents'),
      where('roomId', '==', location.state.roomId)
    );
  }

  let users = [];
  const getUsers = async () => {
    onSnapshot(userCollection, (snapshot) => {
      users = [];
      snapshot.docs.forEach((result) => {
        users.push({
          id: result.data().id,
          profileImgUrl: result.data().profileImgUrl,
          firstName: result.data().firstName,
          lastName: result.data().lastName,
          dateOfBirth: result.data().dateOfBirth,
          createdAt: result.data().createdAt,
          includedInAdminStories: result.data().includedInAdminStories,
          capacity: result.data().capacity,
          facilityId: result.data().facilityId,
          facilityName: result.data().facilityName,
          roomId: result.data().roomId,
          roomName: result.data().roomName,
          deletedAt: result.data()?.deletedAt,
          likes: result.data()?.likes,
          dislikes: result.data()?.dislikes,
          aboutMe: result.data()?.aboutMe,
          nextOfKin: result.data()?.nextOfKin ?? null
        });
      });
      if (!users.length) {
        return setNotFound(true);
      }
      setUSERLIST(users);
    });
  };



  // *****************Get all wellness notification******************
  function getWellnessNotifu() {
    const ref = query(
      collection(db, 'notifications'),
      where('type', '==', 'wellness'),
      where('viewedBy', 'array-contains', localUser.id)
    );

    onSnapshot(ref, (snapshot) => {
      const notify = [];
      snapshot.docs.forEach(function (result) {
        notify.push(result.data());
      });
      setgetNotifyWellness(notify);
    });
  }

  //* *****************UseEffect for users get********************
  useEffect(() => {
    // notify
    getWellnessNotifu();

    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );

  // ------------------------for popup box-------------------------
  const handleClickOpen = (description) => {
    setModelRow(description);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // ------------------------End for popup box-------------------------

  // for show dot on resident name when add wellness
  function showDotOnResident(id) {
    let array = getNotifyWellness?.filter((e) => e.object?.residentId === id);
    return array;
  }
  return (
    <>
      <Page title="Residents | Rel8">
        <Container style={{ width: '100%' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>

              {(JSON.parse(localStorage.getItem('Rel8User')).role == 'Family Admin' || JSON.parse(localStorage.getItem('Rel8User')).role == 'Family Member') ? 'My Family' : 'Clients'}

            </Typography>
            {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Admin' && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/administration/clients/add-residents"
                startIcon={<Icon icon={plusFill} />}
              >
                Add Client
              </Button>
            )}
          </Stack>

          <Card>
            <UserListToolbar
              USERLIST={[]}
              localuser={{}}
              selected={selected}
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              collect="residents"
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    deletedTab={localUser.role === 'Family Member' && true}
                  // deletedTab={true}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          firstName,
                          lastName,
                          facilityId,
                          facilityName,
                          includedInAdminStories,
                          dateOfBirth,
                          profileImgUrl,
                          // capacity,
                          createdAt,
                          roomId,
                          roomName,
                          deletedAt,
                          likes,
                          dislikes,
                          aboutMe,
                          nextOfKin
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            facilityName="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              {localUser.role !== 'Family Member' && (
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="2px">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {profileImgUrl ? (
                                  // <LightBox src={profileImgUrl} alt={firstName}>
                                  <Avatar
                                    alt={firstName}
                                    src={profileImgUrl}
                                    style={{ cursor: 'pointer', backgroundColor: '#9A76F5' }}
                                    onClick={() => {
                                      setImage(profileImgUrl);
                                    }}
                                  />
                                ) : (
                                  // </LightBox>
                                  <Avatar
                                    alt={firstName}
                                    src={profileImgUrl}
                                    style={{ backgroundColor: '#9A76F5' }}
                                  />
                                )}
                                <Typography
                                  variant="subtitle2"
                                  component={RouterLink}
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                    color: '#212B36',
                                    fontWeight: 'bold'
                                  }}
                                  to="/administration/clients/resident-more-info"
                                  state={{ row, notifyArray: showDotOnResident(id) }}
                                >
                                  {showDotOnResident(id)?.length > 0 && (
                                    <span style={{ color: 'red', fontWeight: 900 }}>*</span>
                                  )}
                                  {!firstName || firstName?.length < 25 ? (
                                    firstName
                                  ) : (
                                    <>
                                      {firstName?.slice(0, 25)}
                                      <span
                                        style={{
                                          color: 'blue',
                                          cursor: 'pointer',
                                          marginLeft: '5px'
                                        }}
                                        onClick={() => handleClickOpen(firstName)}
                                        role="button"
                                        tabIndex="0"
                                        onKeyDown={handleClick}
                                      >
                                        Read more...
                                      </span>
                                    </>
                                  )}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left" width="12%">
                              {!lastName || lastName?.length < 25 ? (
                                lastName
                              ) : (
                                <>
                                  {lastName?.slice(0, 25)}
                                  <span
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                      marginLeft: '5px'
                                    }}
                                    onClick={() => handleClickOpen(lastName)}
                                    role="button"
                                    tabIndex="0"
                                    onKeyDown={handleClick}
                                  >
                                    Read more...
                                  </span>
                                </>
                              )}
                            </TableCell>
                            <TableCell width="12%">
                              {facilityName || (
                                <Label variant="ghost" color="error">
                                  Unassigned
                                </Label>
                              )}
                            </TableCell>
                            <TableCell width="12%">
                              {roomName || (
                                <Label variant="ghost" color="error">
                                  Unassigned
                                </Label>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {moment(createdAt?.toDate()).format('DD/MM/YYYY')}
                            </TableCell>

                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={includedInAdminStories ? 'success' : 'error'}
                              >
                                {sentenceCase(includedInAdminStories ? 'True' : 'False')}
                              </Label>
                            </TableCell>
                            <TableCell align="left">
                              {deletedAt != null ? (
                                <Label variant="ghost" color="error">
                                  {/* Deleted */}
                                  {DeletedTimeResi(deletedAt)}-remaining
                                </Label>
                              ) : (
                                <Label variant="ghost" color="success">
                                  Active
                                </Label>
                              )}
                            </TableCell>
                            {JSON.parse(localStorage.getItem('Rel8User'))?.role === 'admin' && (
                              <TableCell align="right">
                                {selected.length === 0 && (
                                  <ResidentMoreMenu
                                    id={id}
                                    firstName={firstName}
                                    likes={likes}
                                    dislikes={dislikes}
                                    aboutMe={aboutMe}
                                    lastName={lastName}
                                    dateOfBirth={dateOfBirth}
                                    facilityId={facilityId}
                                    facilityName={facilityName}
                                    roomId={roomId}
                                    roomName={roomName}
                                    // capacity={capacity}
                                    createdAt={createdAt}
                                    includedInAdminStories={includedInAdminStories}
                                    profileImgUrl={profileImgUrl}
                                    deletedAt={deletedAt ?? null}
                                    nextOfKin={nextOfKin ?? null}
                                  />
                                )}
                              </TableCell>
                            )}

                            {JSON.parse(localStorage.getItem('Rel8User'))?.role ===
                              'Facility Member' &&
                              JSON.parse(localStorage.getItem('Rel8User'))?.subRole === 0 && (
                                <TableCell align="right">
                                  {selected.length === 0 && (
                                    <ResidentMoreMenu
                                      id={id}
                                      firstName={firstName}
                                      likes={likes}
                                      dislikes={dislikes}
                                      aboutMe={aboutMe}
                                      lastName={lastName}
                                      dateOfBirth={dateOfBirth}
                                      facilityId={facilityId}
                                      facilityName={facilityName}
                                      roomId={roomId}
                                      roomName={roomName}
                                      // capacity={capacity}
                                      createdAt={createdAt}
                                      includedInAdminStories={includedInAdminStories}
                                      profileImgUrl={profileImgUrl}
                                      deletedAt={deletedAt ?? null}
                                      nextOfKin={nextOfKin ?? null}
                                    />
                                  )}
                                </TableCell>
                              )}
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound &&
                    (NotFound ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <UserNotFound />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>

      {/* ***************************** Start PopUp ************************* */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText sx={{ color: '#34495E' }}>{modelRow}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* ***************************** End PopUp ************************* */}
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </>
  );
}
