import { filter, forEach, result } from 'lodash';
import { getIcon, Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Box,
    Typography,
    TableContainer,
    TablePagination,
    Tooltip
} from '@mui/material';
// components
import {
    collection,
    getDocs,
    doc,
    deleteDoc,
    setDoc,
    addDoc,
    onSnapshot,
    query,
    where,
    Timestamp,
    updateDoc,
    arrayUnion
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
// ------------------------for popup box-------------------------
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import moment from 'moment';
// ------------------------------------------------------------------
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
//
import { db, localUser } from '../../App';
import StoriesMoreMenu from '../../components/_dashboard/stories/StoriesMoreMenu';
import NoticeMoreMenu from '../../components/_dashboard/user/NoticeMoreMenu';
import NoticeIcon from '../../assest/noticeIcon.svg';
import DocumentIcon from "@iconify/icons-ant-design/file-text"
import { DescriptionOutlined } from '@mui/icons-material';
import DocumentsMoreMenu from '../../components/_dashboard/user/DocumentsMoreMenu';
import repeatOutline from '@iconify/icons-eva/repeat-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import DeletedTimeResi from 'src/components/_dashboard/resident/DeletedTimeResi';
import { DeleteEvent } from '../Wellbeing/DeletedWellness';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
// ------------------------End for popup box-------------------------

// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
    //   { id: 'title', label: 'Title', alignRight: false },
    { id: 'shared by', label: 'Shared by', alignRight: false },
    { id: 'documents', label: 'Documents', alignRight: false },
    { id: 'visible to', label: 'Visible to', alignRight: false },
    { id: 'shared with clients', label: 'Clients', alignRight: false },
    { id: 'views', label: 'Views', alignRight: false },
    { id: 'createdAt', label: 'Published Date', alignRight: false },
    { id: 'delete', label: 'Delete', alignRight: false },
    { id: 'actions', label: 'Actions', alignRight: false },

];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
//* **************Add Users From Firebase**********************
export const addUser = (likes, title, comments) => {
    const newCityRef = doc(collection(db, 'users'));
    return setDoc(newCityRef, {
        id: newCityRef.id,
        title,
        comments,
        likes
    });
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id) =>
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            deleteDoc(doc(db, 'users', id))
                .then((res) => window.location.reload(false))
                .catch((err) => console.log(err.message));
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        }
    });

//* **************Delete Users From Firebase**********************
export const listUserDelete = (ids) =>
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            for (let i = 0; i < ids.length; i += 1) {
                deleteDoc(doc(db, 'users', ids[i]))
                    .then((res) => {
                        if (i === ids.length - 1) {
                            window.location.reload(false);
                        }
                    })
                    .catch((err) => console.log(err.message));
            }
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        }
    });

//* **************Update Users From Firebase**********************
export const updateUser = (id, likes, title, comments) => {
    setDoc(doc(db, 'users', id), {
        id,
        title,
        comments,
        likes
    })
        .then(() => console.log('deleted successfully'))
        .catch((err) => console.log(err.message));
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter(array, comparator, query, query2) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    const Query2 = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        console.log("query : ", query)
        console.log("array ", array)
        return filter(
            array,
            (_user) =>
                _user.title && _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    console.log("1")
    const ReturnGroups = query2
        ? stabilizedThis.filter((a) => {
            console.log('a ', a[0].facilityName);
            console.log('query 2 ', query2);
            if (query2 == a[0].facilityName) {
                return a[0];
            }
        })
        : stabilizedThis;

    console.log("2")
    console.log('return group ', ReturnGroups);
    return ReturnGroups.map((el) => {
        return el[0];
    });
}

export default function DeletedDocuments() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('createdAt');
    const [filterName, setFilterName] = useState('');
    const [filterByGrp, setFilterByGrp] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isLoading, setisLoading] = useState(true);
    const [Search, setSearch] = useState(false);
    const [SearchSet, setSearchSet] = useState(false);
    const [NotFound, setNotFound] = useState(false);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [USERLIST, setUSERLIST] = useState([]);
    const [clients, setClients] = useState([])

    const [open, setOpen] = useState(false);
    const [modelRow, setModelRow] = useState('');

    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('Rel8User'));

    let residentsCollection;
    if (user?.role == 'admin') {
        residentsCollection = query(collection(db, 'residents'))
    } else if (user?.role == 'Facility Member') {
        residentsCollection = query(collection(db, 'residents'), where('id', 'in', user?.residentIds.length ? user?.residentIds : ['']))
    }
    let userCollection = collection(db, 'recycleBin', 'ClientDocuments', 'ClientDocuments');
    if (user?.role === 'Facility Member') {
        userCollection = query(collection(db, 'recycleBin', 'ClientDocuments', 'ClientDocuments'), where('postedById', '==', user?.id));
    }


    //* **************Delete Users From Firebase**********************
    const restoreDocument = (data) => {
        const clientsDocumentRefernce = doc(db, 'ClientDocuments', data.id)
        console.log(data.id);
        console.log(data);
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to be restore these Documents?',
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, restore it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // ----------------------------------------------Loading-------------
                let timerInterval;
                Swal.fire({
                    title: 'Processing...',
                    html: 'I will close in few<b></b> Moments.',
                    // timer: 2000,
                    allowOutsideClick: false,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('I was closed by the timer');
                    }
                });
                // ----------------------------------------------Loading---------------------
                await deleteDoc(doc(db, 'recycleBin', 'ClientDocuments', 'ClientDocuments', data.id));
                // .then(async (res) => {
                await setDoc(doc(db, 'ClientDocuments', data.id), {
                    ...data
                }).then(async () => {
                    await Promise.all(
                        data?.residentsId?.map(async (residentId) => {
                            const clientRef = doc(db, 'residents', residentId);
                            await updateDoc(clientRef, {
                                documentsRef: arrayUnion(clientsDocumentRefernce),
                            });
                        })
                    );
                })


                //   window.location.reload(false);
                // })
                // .catch((err) => console.log(err.message));
                await Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Document has been restored.',
                    showConfirmButton: false,
                    timer: 1500
                });
                window.location.reload(false);
            }
        });
    };


    let clientDocuments = [];
    let clientsData = []

    const getUsers = async () => {


        if (user?.residentIds.length !== 0) {
            onSnapshot(residentsCollection, async (snapshot) => {
                clientsData = []
                for (let result of snapshot.docs) {
                    clientsData.push({
                        id: result.data()?.id,
                        name: result.data()?.firstName
                    })
                }
            })
        }

        // ------------------------------------------------------------------------------------
        onSnapshot(userCollection, async (snapshot) => {
            clientDocuments = [];
            // snapshot.docs.forEach((result) => {
            for (let result of snapshot.docs) {
                clientDocuments.push({
                    id: result.data()?.id,
                    createdAt: result.data()?.createdAt,
                    postedById: result.data()?.postedById,
                    medias: result.data()?.medias,
                    residentsId: result.data()?.residentsId,
                    visibileFlag: result.data()?.visibileFlag,
                    authorName: result.data()?.authorName ?? '',
                    views: result.data()?.views ? result.data()?.views.length : 0,
                    deletedAt: result.data()?.deletedAt,
                    views: result.data()?.views ? result.data()?.views.length : 0,
                    documents: result.data()?.medias ? result.data()?.medias.length : 0,
                    clients: result.data()?.residentsId ? result.data()?.residentsId.length : 0,
                    visible: result.data()?.visibileFlag ? result.data().visibileFlag : false,
                    fileName: result.data()?.fileName ?? result.data()?.medias?.[0].name
                });
            }
            if (!clientDocuments.length) {
                return setNotFound(true);
            }
            setUSERLIST(clientDocuments);
            setClients(clientsData)
        });
    };

    //* *****************UseEffect for users get********************
    useEffect(() => {
        getUsers();
        setisLoading(false);
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        if (Search) {
            setSearch(false);
        } else {
            setSearch(true);
        }
        setSearchSet(true);
        setFilterName(event.target.value);
    };

    useEffect(() => {
        if (filteredUsers.length === 0 && SearchSet) {
            setNotFound(true);
        }
    }, [Search]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName ?? '',
        filterByGrp ?? '');

    const isUserNotFound = filteredUsers.length === 0;
    if (isLoading)
        return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
                <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
            </div>
        );

    // ------------------------for popup box-------------------------
    const handleClickOpen = (description, title) => {
        const data = {
            description,
            title
        };
        setModelRow(data);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    // ------------------------End for popup box-------------------------

    return (
        <Box>
            <Page title="Documents | Rel8">
                <Container sx={{ width: '100%', minWidth: '100%' }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                color: '#9A76F5'
                            }}
                        >
                            {/* {getIcon(DocumentIcon)} */}
                            <DescriptionOutlined style={{ width: "30px", height: "30px" }} />
                            <p
                                style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}
                            >
                                Documents
                            </p>
                        </Box>
                        {/* {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/content/noticeboard/add-notice-board"
                startIcon={<Icon icon={plusFill} />}
              >
                Add Notice
              </Button>
            )} */}
                    </Stack>

                    <Card sx={{ boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px' }}>
                        {/* <UserListToolbar
                            USERLIST={[]}
                            localuser={{}}
                            selected={selected}
                            numSelected={selected.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            collect="noticeboards"
                            setFilterByGrp={setFilterByGrp}
                        /> */}

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={USERLIST.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        deletedTab={localUser.role === 'Family Member' && true}
                                    />
                                    <TableBody>
                                        {filteredUsers
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                const {
                                                    id,
                                                    createdAt,
                                                    views,
                                                    medias,
                                                    residentsId,
                                                    authorName,
                                                    visibileFlag,
                                                    deletedAt,
                                                    fileName
                                                } = row;
                                                // console.log(`visibleFlag is : ${visibileFlag ? 'true' : 'false'}`)
                                                const isItemSelected = selected.indexOf(id) !== -1;
                                                const clientDetails = clients?.filter((item) => item.id == residentsId[0])
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={id}
                                                        tabIndex={-1}
                                                        description="checkbox"
                                                        selected={isItemSelected}
                                                        aria-checked={isItemSelected}
                                                        sx={{ textDecoration: 'none' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            {localUser.role !== 'Family Member' && (
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    onChange={(event) => handleClick(event, id)}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" width="20%">
                                                            <Typography
                                                                variant="subtitle2"
                                                                component={RouterLink}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'none',
                                                                    color: '#212B36',
                                                                    fontWeight: 'bold'
                                                                }}
                                                                to="/recycleBin/deleted-document-details"
                                                                state={{
                                                                    id,
                                                                    date: createdAt,
                                                                    views,
                                                                    medias,
                                                                    residentsId,
                                                                    authorName,
                                                                    visibleFlag: visibileFlag,
                                                                    fileName,
                                                                    object: row
                                                                }}
                                                            >
                                                                {authorName?.slice(0, 80)}
                                                            </Typography>
                                                        </TableCell>
                                                        {/* <TableCell align="left">  {medias?.length !== 0 ? medias.length : 0}</TableCell> */}
                                                        <TableCell align="left">  {fileName.slice(0, 25) ?? medias?.[0]?.name}{fileName?.length > 25 && '...'}</TableCell>
                                                        <TableCell align="left">{visibileFlag ? 'Next of kin' : 'none'}</TableCell>
                                                        {/* <TableCell align="left">{residentsId?.length}</TableCell> */}
                                                        <TableCell align='left'>{clientDetails[0] ? clientDetails[0]?.name : 'N/A'}</TableCell>
                                                        <TableCell align="left">{views}</TableCell>
                                                        <TableCell align="left">
                                                            {createdAt ? moment(createdAt.toDate()).format('DD MMM YYYY') : "N/A"}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Label variant="ghost" color="error">
                                                                {DeletedTimeResi(deletedAt)}-remaining
                                                            </Label>
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            <Stack spacing={2} direction={{ xs: 'row', sm: 'row' }}>
                                                                <Tooltip title="Restore Event" placement="top">
                                                                    <LoadingButton
                                                                        size="small"
                                                                        variant="contained"
                                                                        // loading={isLoading}
                                                                        onClick={() => {
                                                                            // restoreEvent(row);
                                                                            restoreDocument(row)
                                                                        }}
                                                                        disabled={
                                                                            JSON.parse(localStorage.getItem('Rel8User')).role ===
                                                                            'Family Member' ?? false
                                                                        }
                                                                    >
                                                                        {/* Restore */}
                                                                        <Icon icon={repeatOutline} style={{ fontSize: '20px' }} />
                                                                    </LoadingButton>
                                                                </Tooltip>
                                                                <Tooltip title="Delete Event" placement="top">
                                                                    <LoadingButton
                                                                        size="small"
                                                                        variant="contained"
                                                                        onClick={() => {
                                                                            DeleteEvent(row?.id, 'ClientDocuments', 'ClientDocuments');
                                                                        }}
                                                                        disabled={
                                                                            JSON.parse(localStorage.getItem('Rel8User')).role ===
                                                                            'Family Member' ?? false
                                                                        }
                                                                    >
                                                                        <Icon icon={trash2Outline} style={{ fontSize: '20px' }} />
                                                                    </LoadingButton>
                                                                </Tooltip>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {isUserNotFound &&
                                        (NotFound ? (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                                        <UserNotFound />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                                        <SearchNotFound searchQuery={filterName} />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        ))}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={USERLIST.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Container>
            </Page>
            {/* ***************************** Start PopUp ************************* */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move', fontWeight: '700' }} id="draggable-dialog-title">
                    {modelRow?.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#34495E' }}>{modelRow?.description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {/* ***************************** End PopUp ************************* */}
        </Box>
    );
}
