import { styled } from '@mui/material/styles';
import { Avatar, Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
// components
import Page from '../../components/Page';
import FamilyMoreInfo from '../../components/_dashboard/FamilyMember/FamilyMoreInfo';
import ProfileIcon from '../../assest/profile-icon.svg'
import { MHidden } from 'src/components/@material-extend';
import Label from 'src/components/Label';
import { localUser } from 'src/App';
import FamilyMoreMenu from 'src/components/_dashboard/user/FamilyMoreMenu';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
  //   padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function FamilyInfoPage() {
  const location = useLocation()
  const { row, residents } = location.state
  console.log("resdients in family info are : ", residents)
  console.log(location.state)
  return (
    <RootStyle title="Facility Details | Rel8">
      <Container className='parentCont' style={{paddingLeft:"0px"}}>
        <ContentStyle>
          <Stack direction="row" alignItems="center" justifyContent="space-between" px={2} mb={"10px"}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                color: '#9A76F5'
              }}
            >
              <img
                src={ProfileIcon}
                style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
              />
              <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500, textTransform: "capitalize" }}>
                {row.firstname}'s profile
              </p>
            </Box>
            {(localUser.role == 'admin' || localUser.role == 'Facility Member') && <FamilyMoreMenu
              id={row?.id}
              firstname={row?.firstname}
              lastname={row?.lastname}
              email={row?.email}
              // residentIds={row?.residentIds?.length == 0 ? [...residents] : row?.residentIds}
              residentIds={location.state?.residents ?? []}
              facilityId={row?.facilityId ?? null}
              createdAt={row?.date}
              avatarUrl={row?.avatarUrl}
              facilityName={row?.facilityName ?? null}
              role={row?.role}
              status={row?.status ?? null}
              restrictAt={row?.restrictAt ?? 0}
              deletedAt={row?.deletedAt}
              subRole={row?.subRole}
              restrictTime={row?.restrictTime}
            />}
          </Stack>
          <MHidden width="mdDown">
            <Card
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
                display: 'flex',
                width: 'full',
                paddingY: '25px',
                paddingX: '40px',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', width: '50%' }}>
                {row?.avatarUrl ? (
                  <Avatar
                    src={row.avatarUrl}
                    alt="photoURL"
                    sx={{ width: '120px', height: '120px' }}
                  />
                ) : (
                  <Avatar
                    src=""
                    sx={{
                      backgroundColor: '#9A76F5',
                      fontWeight: 400,
                      width: '120px',
                      height: '120px',
                      fontSize: '60px'
                    }}
                  >
                    {row?.firstname?.slice(0, 1)?.toUpperCase()}
                    {row?.lastname?.slice(0, 1)?.toUpperCase()}
                  </Avatar>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }} >
                  <Box sx={{ fontSize: '24px' }}>
                    {row?.firstname} {row?.lastname}
                  </Box>
                  <Box sx={{ fontSize: '18px', color: '#9A76F5', fontWeight: "500" }}>
                    {/* {row?.role} */}
                    {row?.subRole === 0 ? (
                      'Administrator'
                    ) : (
                      'Member'
                    )}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ height: '50%' }}>
                <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Email</Box>
                <Box sx={{ fontSize: '16px' }}>{row?.email ?? ''}</Box>
              </Box>
              <Box sx={{ height: '50%' }}>
                <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Account Status</Box>
                <Box sx={{ fontSize: '16px' }} >
                  {row?.status == 'accepted' ?
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                      <span
                        style={{
                          backgroundColor: "#3ad638", // Parrot green color
                          width: "10px",
                          height: "10px",
                          display: "inline-block", // Ensures the span behaves like a block for dimensions
                          borderRadius: "50%",
                        }}
                      ></span>
                      Active
                    </div>
                    :
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                      <span
                        style={{
                          backgroundColor: "#e84f51", // Parrot green color
                          width: "10px",
                          height: "10px",
                          display: "inline-block", // Ensures the span behaves like a block for dimensions
                          borderRadius: "50%",
                        }}
                      ></span>
                      Pending
                    </div>}
                </Box>
              </Box>
            </Card>

          </MHidden>
          <MHidden width="mdUp">
            <Card
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.15) 5px 5px 50px',
                display: 'flex',
                flexDirection: 'column',
                width: 'full',
                paddingY: '25px',
                paddingX: '15px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '30px'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', width: '100%' }}>
                {row?.avatarUrl ? (
                  <Avatar
                    src={row?.avatarUrl}
                    alt="photoURL"
                    sx={{ width: '60px', height: '60px', fontSize: '40px' }}
                  />
                ) : (
                  <Avatar
                    src=""
                    sx={{
                      backgroundColor: '#9A76F5',
                      fontWeight: 400,
                      width: '60px',
                      height: '60px'
                    }}
                  >
                    {row?.firstname?.slice(0, 1)?.toUpperCase()}
                    {row?.lastname?.slice(0, 1)?.toUpperCase()}
                  </Avatar>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                  <Box sx={{ fontSize: '18px' }}>
                    {row?.firstname} {row?.lastname}
                  </Box>
                  <Box sx={{ fontSize: '14px', color: '#9A76F5', fontWeight: "500" }}>
                    {row?.subRole === 0 ? (
                      'Administrator'
                    ) : (
                      'Member'
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '30px',
                  width: '100%',
                  justifyContent: 'space-around'
                }}
              >

                <Box sx={{
                  display: 'flex', flexDirection: localUser.role != 'admin' && localUser.role != 'Facility Member'
                    ? 'row'
                    : 'column',
                  gap:
                    localUser.role != 'admin' && row?.role != 'Facility Member'
                      ? '30px'
                      : '10px', height: '100%'
                }}>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Email</Box>
                    <Box sx={{ fontSize: '16px' }}>{row?.email.slice(0, 20) ?? ''}</Box>
                  </Box>
                  <Box sx={{ height: '50%' }}>
                    <Box sx={{ fontSize: '16px', color: '#9A76F5', fontWeight: "500" }}>Account Status</Box>
                    <Box sx={{ fontSize: '16px' }}>
                      {row?.status !== 'accepted' ?
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#3ad638", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          Active
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "6px" }}>
                          <span
                            style={{
                              backgroundColor: "#e84f51", // Parrot green color
                              width: "10px",
                              height: "10px",
                              display: "inline-block", // Ensures the span behaves like a block for dimensions
                              borderRadius: "50%",
                            }}
                          ></span>
                          Pending
                        </div>}

                    </Box>
                  </Box>
                </Box>
              </Box>
            </Card>
          </MHidden>
          <FamilyMoreInfo />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
