import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components

import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
  deleteField,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import DeletedTimeFamily from '../../components/_dashboard/FamilyMember/DeletedTimeFamily';
import FamilyMoreMenu from '../../components/_dashboard/user/FamilyMoreMenu';
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
//
import { db } from '../../App';
// import { LightBox } from '../../components/_dashboard/user/LightBox';
// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'firstname', label: 'First Name', alignRight: false },
  { id: 'lastname', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'subRole', label: 'User Role', alignRight: false },
  { id: 'date', label: 'Date Added', alignRight: false },
  { id: 'restrictAt', label: 'Account Status', alignRight: false },
  { id: 'client', label: 'Client', alignRight: false }
];

const localUser = JSON.parse(localStorage.getItem('Rel8User'));

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//* **************Add Users From Firebase**********************
export const addUser = (name, email, contact, location, facilityName) => {
  const data = {
    name,
    email,
    contact,
    location,
    facilityName
  };
  return axios.post('https://rel8-cloud-functions.herokuapp.com/addFacility', data);
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id, avatarUrl) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDoc(doc(db, 'facilites', id))
        .then((res) => window.location.reload(false))
        .catch((err) => console.log(err.message));

      const membersRef = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
      const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
      getDocs(membersRef).then((res) => {
        res.forEach((result) => {
          const facilityMemberRef = doc(db, 'facilityMembers', result.data().id);
          updateDoc(facilityMemberRef, {
            facilityId: deleteField(),
            facilityName: deleteField()
          });
        });
      });
      getDocs(residentsRef).then((res) => {
        res.forEach((result) => {
          const residentRef = doc(db, 'residents', result.data().id);
          updateDoc(residentRef, {
            facilityId: deleteField(),
            facilityName: deleteField()
          });
        });
      });
      const storage = getStorage();

      // Create a reference to the file to delete
      const desertRef = ref(storage, avatarUrl);

      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          Swal.fire('Deleted!', 'Facility has been deleted.', 'success');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

//* **************Delete Users From Firebase**********************
export const listUserDelete = (ids) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      for (let i = 0; i < ids.length; i += 1) {
        deleteDoc(doc(db, 'facilityMembers', ids[i]))
          .then((res) => {
            if (i === ids.length - 1) {
              window.location.reload(false);
            }
          })
          .catch((err) => console.log(err.message));
      }
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });
};

//* **************Update Users From Firebase**********************
export const updateUser = (id, name, contact, location, capacity, image) => {
  const storage = getStorage();
  const storageRef = ref(storage, `facilities/${id}/facilityImgUrl`);
  const metadata = {
    contentType: 'image/jpeg'
  };
  const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');
  if (RgExp.test(image)) {
    return setDoc(
      doc(db, 'facilites', id),
      {
        id,
        name,
        contact,
        location,
        capacity
      },
      { merge: true }
    ).then(() => {
      const membersRef = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
      const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
      getDocs(membersRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'facilityMembers', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      getDocs(residentsRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'residents', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      Swal.fire('Upated!', 'Facility has been updated.', 'success');
    });
  }
  const uploadTask = uploadBytesResumable(storageRef, image, metadata);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(`Upload is ${progress}% done`);
    },
    (error) => NotificationManager.error(error.message),
    async () => {
      const avatarUrl = await getDownloadURL(uploadTask.snapshot.ref);
      setDoc(
        doc(db, 'facilites', id),
        {
          id,
          name,
          contact,
          location,
          capacity,
          avatarUrl
        },
        { merge: true }
      ).then(() => {
        const membersRef = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
        const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
        getDocs(membersRef).then((res) => {
          res.forEach((result) => {
            setDoc(
              doc(db, 'facilityMembers', result.data().id),
              {
                facilityName: name
              },
              { merge: true }
            );
          });
        });
        getDocs(residentsRef).then((res) => {
          res.forEach((result) => {
            setDoc(
              doc(db, 'residents', result.data().id),
              {
                facilityName: name
              },
              { merge: true }
            );
          });
        });
        Swal.fire('Upated!', 'Facility has been updated.', 'success');
      });
    }
  );
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function FamilyMembers() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('date');
  const [filterName, setFilterName] = useState('');
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [NotFound, setNotFound] = useState(false);
  const [NotFoundClient, setNotFoundClient] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);
  const [ClientLIST, setClientLIST] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  // ------for lightbox
  const [image, setImage] = useState('');

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Users From Firebase**********************
  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'users'),
      where('role', '==', 'Family Member'),
      where('restrictAt', '!=', 4),
      where('facilityIds', 'array-contains-any', user?.facilityIds)
    );
  } else if (user?.role === 'admin') {
    userCollection = query(
      collection(db, 'users'),
      where('role', '==', 'Family Member'),
      where('restrictAt', '!=', 4)
    );
  } else if (user?.role === 'Family Member') {
    userCollection = query(
      collection(db, 'users'),
      where('role', '==', 'Family Member'),
      where('id', '==', user?.id)
      // where('deletedAt', '==', null)
    );
  }

  //* **************Get Facilites From Firebase**********************

  let users = [];
  const getUsers = async () => {
    if (!location.state) {
      // getDocs(userCollection).then((res) => {
      onSnapshot(userCollection, (snapshot) => {
        users = [];
        snapshot?.docs.forEach((result) => {
          users.push({
            id: result.data().id,
            avatarUrl: result.data().avatarUrl,
            firstname: result.data().firstname,
            email: result.data().email,
            facilityName: result.data().facilityName,
            facilityId: result.data().facilityIds,
            date: result.data().createdAt,
            lastname: result.data().lastname,
            residentIds: result.data()?.residentIds,
            role: result.data().role,
            status: result.data()?.status,
            restrictAt: result.data()?.restrictAt ?? 0,
            deletedAt: result.data()?.deletedAt ?? null,
            subRole: result.data()?.subRole,
            restrictTime: result.data()?.restrictTime ?? 0
          });
        });

        if (!users.length) {
          return setNotFound(true);
        }
        console.log(users)
        setUSERLIST(users);
      });
    } else {
      const userCollection = query(
        collection(db, 'users'),
        where('facilityId', '==', location.state.facilityId),
        where('role', '==', 'Family Member')
      );
      // console.log("facility id is : ",facilityId)
      let users = [];
      // getDocs(userCollection).then((res) => {
      onSnapshot(userCollection, (snapshot) => {
        users = [];
        snapshot.docs.forEach((result) => {
          if (result.data().email !== 'admin@admin.com') {
            users.push({
              id: result.data().id,
              avatarUrl: result.data().avatarUrl,
              firstname: result.data().firstname,
              email: result.data().email,
              facilityName: result.data().facilityName,
              facilityId: result.data().facilityId,
              date: result.data().createdAt,
              lastname: result.data().lastname,
              residentIds: result.data()?.residentIds,
              role: result.data().role,
              status: result.data()?.status,
              restrictAt: result.data()?.restrictAt ?? 0,
              deletedAt: result.data()?.deletedAt ?? null,
              subRole: result.data()?.subRole,
              restrictTime: result.data()?.restrictTime ?? 0
            });
          }
        });
        if (!users.length) {
          return setNotFound(true);
        }
        setUSERLIST(users);
      });
    }
  };

  // clients list

  let clientCollection;
  clientCollection = collection(db, 'residents');

  let clients = [];
  const getClients = async () => {
    onSnapshot(clientCollection, (snapshot) => {
      clients = [];
      snapshot.docs.forEach((result) => {
        users.push({
          id: result.data().id,
          profileImgUrl: result.data().profileImgUrl,
          firstName: result.data().firstName,
          lastName: result.data().lastName,
          dateOfBirth: result.data().dateOfBirth,
          createdAt: result.data().createdAt,
          includedInAdminStories: result.data().includedInAdminStories,
          capacity: result.data().capacity,
          facilityId: result.data().facilityId,
          facilityName: result.data().facilityName,
          roomId: result.data().roomId,
          roomName: result.data().roomName,
          deletedAt: result.data()?.deletedAt ?? null,
          likes: result.data()?.likes,
          dislikes: result.data()?.dislikes,
          aboutMe: result.data()?.aboutMe
        });
      });
      if (!users.length) {
        return setNotFoundClient(true);
      }
      setClientLIST(users);
    });
  };

  function getClientByID(id) {
    const users = [];
    let names = '';
    if (id) {
      id.map((itemID) => {
        ClientLIST.map((item) => {
          if (item.id === itemID) {
            users.push(item);
          }
        });
      });
    }

    if (users.length === 0) {
      return <p>Not Found</p>;
    }

    users.map((item) => {
      if (item.firstName) {
        if (names.length > 0) names = names + ' , ' + item.firstName + ' ' + item.lastName;
        if (names.length == 0) {
          names = names + item.firstName + ' ' + item.lastName;
        }
      }
    });

    return users.length > 1 ? (
      names.length > 22 ? (
        <Tooltip title={names} arrow placement="top">
          <p>{names.slice(0, 20)} ...</p>{' '}
        </Tooltip>
      ) : (
        <Tooltip title={names} arrow placement="top">
          <p>{names}</p>
        </Tooltip>
      )
    ) : (
      <Tooltip title={names} arrow placement="top">
        <p>{names}</p>
      </Tooltip>
    );
  }

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getClients();
    getUsers();
    setisLoading(false);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet) {
      setNotFound(true);
    }
  }, [Search]);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );
  return (
    <Page title="Family Members | Rel8">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {(user?.role == 'Family Member' && user.subRole != 0) ? "Connections" : "Family Members"}
          </Typography>
          {((user.role == 'Family Member' && user.subRole == 0) || (user.role != 'Family Member')) && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/administration/family-members/add-family-member"
              startIcon={<Icon icon={plusFill} />}
            >
              Add Family
            </Button>
          )}
        </Stack>

        <Card>
          <UserListToolbar
            USERLIST={[]}
            localuser={{}}
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            collect="users"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  deletedTab={localUser.role === 'Family Member' && true}
                // deletedTab={true}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        firstname,
                        facilityId,
                        location,
                        date,
                        contact,
                        avatarUrl,
                        facilityName,
                        email,
                        lastname,
                        residentIds,
                        role,
                        status,
                        restrictAt,
                        deletedAt,
                        subRole,
                        restrictTime
                      } = row;
                      if (row?.email == 'testmail@gmail.com') {
                        console.log("row data is : ", row)
                      }
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          location="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {localUser.role !== 'Family Member' && (
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="2px">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {avatarUrl ? (
                                // <LightBox src={avatarUrl} alt={firstname}>
                                <Avatar
                                  alt={firstname}
                                  src={avatarUrl}
                                  style={{ cursor: 'pointer', backgroundColor: '#9A76F5' }}
                                  onClick={() => {
                                    setImage(avatarUrl);
                                  }}
                                />
                              ) : (
                                // </LightBox>
                                <Avatar
                                  alt={firstname}
                                  src={avatarUrl}
                                  style={{ backgroundColor: '#9A76F5' }}
                                />
                              )}

                              {/* for more info  */}

                              <Typography
                                variant="subtitle2"
                                component={RouterLink}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                  color: '#212B36',
                                  fontWeight: 'bold'
                                }}
                                to="/administration/family-members/family-more-info"
                                onClick={() => {
                                  console.log(row)
                                }}
                                state={{ row, residents: [...row.residentIds] }}
                              >
                                {firstname?.slice(0, 13)}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{lastname?.slice(0, 13)}</TableCell>
                          <TableCell align="left">
                            {email?.length > 20 ? (
                              <Tooltip title={email} arrow placement="top">
                                <p>{email.slice(0, 17)}...</p>
                              </Tooltip>
                            ) : (
                              email
                            )}
                          </TableCell>

                          <TableCell align="left">
                            {subRole === 0 ? (
                              <Label variant="ghost" color="success">
                                Administrator
                              </Label>
                            ) : (
                              <Label variant="ghost" color="success">
                                Member
                              </Label>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            {moment(date?.toDate()).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell align="left">
                            {deletedAt != null ? (
                              <Label variant="ghost" color="error">
                                {DeletedTimeFamily(deletedAt, restrictAt)}-remaining
                              </Label>
                            ) : (
                              status == 'accepted' ?
                                <Label variant="ghost" color="success">
                                  Active
                                </Label>
                                :
                                <Label variant="ghost" >
                                  Pending
                                </Label>
                            )}
                          </TableCell>
                          <TableCell align="left">{getClientByID(residentIds)}</TableCell>

                          {/* {JSON.parse(localStorage.getItem('Rel8User')).role !==
                            'Family Member' && ( */}

                          {localUser?.role === 'admin' && (
                            <TableCell align="right">
                              {selected.length === 0 && (
                                <FamilyMoreMenu
                                  id={id}
                                  firstname={firstname}
                                  lastname={lastname}
                                  email={email}
                                  residentIds={residentIds ?? []}
                                  facilityId={facilityId ?? null}
                                  createdAt={date}
                                  avatarUrl={avatarUrl}
                                  facilityName={facilityName ?? null}
                                  role={role}
                                  status={status ?? null}
                                  restrictAt={restrictAt ?? 0}
                                  deletedAt={deletedAt}
                                  subRole={subRole}
                                  restrictTime={restrictTime}
                                />
                              )}
                            </TableCell>
                          )}
                          {localUser?.role === 'Facility Member' && localUser?.subRole === 0 && (
                            <TableCell align="right">
                              {selected.length === 0 && (
                                <FamilyMoreMenu
                                  id={id}
                                  firstname={firstname}
                                  lastname={lastname}
                                  email={email}
                                  residentIds={residentIds ?? []}
                                  facilityId={facilityId ?? null}
                                  createdAt={date}
                                  avatarUrl={avatarUrl}
                                  facilityName={facilityName ?? null}
                                  role={role}
                                  status={status ?? null}
                                  restrictAt={restrictAt ?? 0}
                                  deletedAt={deletedAt}
                                  subRole={subRole}
                                  restrictTime={restrictTime}
                                />
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound &&
                  (NotFound ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <UserNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Page>
  );
}
