import { Box, Card, FormGroup, FormControlLabel, Checkbox, Typography, Button, Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ShopProductCard from 'src/components/_dashboard/products/ProductCard';
import { styled } from '@mui/material/styles';
import { AccountCircle } from '@mui/icons-material';
import { db, localUser } from 'src/App';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import edit2Fill from '@iconify/icons-eva/edit-2-fill';
import copyOutline from '@iconify/icons-eva/copy-outline';
import Swal from 'sweetalert2';
import { arrayUnion, deleteDoc, doc, onSnapshot, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import repeatOutline from '@iconify/icons-eva/repeat-outline';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import useDownloader from 'react-use-downloader';
// import {Documen} from "@mui/icons-material"
function DeletedDocumentDetails() {
    const location = useLocation()
    const [residentsData, setResidentsData] = useState([]);
    const [downloadURL, setDownloadURL] = useState('');
    const { size, percentage, download, isInProgress } = useDownloader();

    const { id,
        date,
        views,
        medias,
        residentsId,
        authorName,
        visibleFlag,
        fileName,
        object } = location.state
    const timeStamp = new Timestamp(date.seconds, date.nanoseconds)
    console.log(timeStamp)
    console.log(location.state)
    console.log("object value is : ", object)
    const props = {
        id,
        medias,
        createdAt: date,
        visibileFlag: visibleFlag,
        views,
        residentsId,
        id,
        authorName,
    }

    const ProductFileStyle = styled('div')({
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
        cursor: 'pointer',
        backgroundColor: '#E4E4E4'
    });
    const images =
        medias.length != 0
            ? medias.filter((image) => {
                console.log('image :: ', image);
                const file =
                    (image.type != 'image' && image.type != 'video') ||
                    (image.name && image.name.endsWith('.avi')) ||
                    (image.name && image.name.endsWith('.mpeg')) ||
                    (image.name && image.name.endsWith('.heic'));
                const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
                if (type != 'file') {
                    return image;
                }
            })
            : [];
    const docs =
        medias.length != 0
            ? medias.filter((image) => {
                console.log('image :: ', image);
                const file =
                    (image.type != 'image' && image.type != 'video') ||
                    (image.name && image.name.endsWith('.avi')) ||
                    (image.name && image.name.endsWith('.mpeg')) ||
                    (image.name && image.name.endsWith('.heic'));
                const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
                if (type == 'file') {
                    return image;
                }
            })
            : [];

    function CheckFileType(image) {
        console.log('images :: ', image.index);
        const file =
            (image.image.type != 'image' && image.image.type != 'video') ||
            (image.image.name && image.image.name.endsWith('.avi')) ||
            (image.image.name && image.image.name.endsWith('.mpeg')) ||
            (image.image.name && image.image.name.endsWith('.heic'));
        const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
        const condition = image.isInProgress && image.image.downloadUrl === image.downloadURL;
        switch (type) {
            case 'image':
                return (
                    <div
                        key={image.image.id}
                        className={image.index == 0 && image.length > 7 ? 'firstIndex' : 'allIndex'}
                    >
                        <ShopProductCard img={image.image} Index={image.index} images={image.images} />
                    </div>
                );

            case 'video':
                return (
                    <div
                        key={image.image.id}
                        className={image.index == 0 && image.length > 7 ? 'firstIndex' : 'allIndex'}
                    >
                        <ShopProductCard img={image.image} Index={image.index} images={image.images} />
                    </div>
                );
            default:
                return <p></p>;
        }
    }

    function CheckFileTypeDocument(image, images, index, percentage, isInProgress, downloadURL) {
        const file =
            (image.image.type != 'image' && image.image.type != 'video') ||
            (image.image.name && image.image.name.endsWith('.avi')) ||
            (image.image.name && image.image.name.endsWith('.mpeg')) ||
            (image.image.name && image.image.name.endsWith('.heic'));
        const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
        console.log('image ::: ', image);
        const condition = image.isInProgress && image.image.downloadUrl === image.downloadURL;
        switch (type) {
            case 'file':
                return (
                    <button
                        key={image.image.id}
                        style={{
                            border: 'none',
                            cursor: 'pointer',
                            gap: '10px',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexDirection: 'row',
                            backgroundColor: condition ? '#F2EEFE' : 'transparent',
                            maxWidth: '250px'
                        }}
                        onClick={() => {
                            if (image.isInProgress) {
                                if (image.image.downloadUrl === image.downloadURL) {
                                    return;
                                }
                                return;
                            }
                            image.setDownloadURL(image.image.downloadUrl);
                            image.download(image.image.downloadUrl, image.image.name);
                        }}
                    >
                        <div
                            style={{
                                width: '50px',
                                minWidth: '50px',
                                maxWidth: '50px',
                                height: '60px',
                                minHeight: '60px',
                                maxHeight: '60px',
                                overflow: 'hidden',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <img
                                src={image.image.thumbnail}
                                style={{ objectFit: 'contain', maxHeight: '100%' }}
                            />
                        </div>
                        <div
                            style={{
                                fontSize: '12px',
                                fontSize: '12px',
                                textDecoration: 'none'
                            }}
                        >
                            <div style={{ textAlign: 'center', fontWeight: 600 }}>
                                {fileName
                                    ? fileName?.length > 25
                                        ? fileName?.slice(0, 25) + '...'
                                        : fileName
                                    : fileName}
                                {' ('}
                                {image.image.size ?? ''}
                                {' KB)'}
                            </div>
                        </div>
                    </button>
                );

            default:
                return <p></p>;
        }
    }


    useEffect(() => {
        if (residentsId?.length) {
            getResidents();
        }
    }, [residentsId]);

    const getResidents = () => {
        try {
            if (!residentsId?.length) {
                return;
            }

            const tempData = []; // Temporary array to collect data
            residentsId.forEach((resident) => {
                const residentRef = doc(db, "residents", resident);
                onSnapshot(residentRef, (doc) => {
                    const data = doc.data();
                    console.log("data is : ", data);
                    if (data) {
                        tempData.push(data); // Add to temporary array
                        // Update state with the new array
                        setResidentsData([...tempData]);
                    }
                });
            });
        } catch (error) {
            console.log("error is : ", error);
        }
    };


    const restoreDocument = (data) => {
        const clientsDocumentRefernce = doc(db, 'ClientDocuments', data.id)
        console.log(data.id);
        console.log(data);
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to be restore these Documents?',
            icon: 'warning',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, restore it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                // ----------------------------------------------Loading-------------
                let timerInterval;
                Swal.fire({
                    title: 'Processing...',
                    html: 'I will close in few<b></b> Moments.',
                    // timer: 2000,
                    allowOutsideClick: false,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => {
                            b.textContent = Swal.getTimerLeft();
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    /* Read more about handling dismissals below */
                    if (result.dismiss === Swal.DismissReason.timer) {
                        console.log('I was closed by the timer');
                    }
                });
                // ----------------------------------------------Loading---------------------
                await deleteDoc(doc(db, 'recycleBin', 'ClientDocuments', 'ClientDocuments', data.id));
                // .then(async (res) => {
                await setDoc(doc(db, 'ClientDocuments', data.id), {
                    ...data
                }).then(async () => {
                    await Promise.all(
                        data?.residentsId?.map(async (residentId) => {
                            const clientRef = doc(db, 'residents', residentId);
                            await updateDoc(clientRef, {
                                documentsRef: arrayUnion(clientsDocumentRefernce),
                            });
                        })
                    );
                })


                // .then(() => {
                //     residentsId.map(async (clientId) => {
                //         const clientRef = doc(db, 'residents', clientId)
                //         await updateDoc(clientRef, {
                //             documentsRef: arrayUnion(clientsDocumentRefernce)
                //         })
                //     })
                // })
                //   window.location.reload(false);
                // })
                // .catch((err) => console.log(err.message));
                await Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Document has been restored.',
                    showConfirmButton: false,
                    timer: 1500
                });
                window.location.replace("/content/documents");
            }
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                <Typography variant='h3' style={{ color: "#9A76F5", textTransform: "initial", fontWeight: '500' }}>
                    Document details
                </Typography>
            </Box>
            <Card
                sx={{
                    paddingY: '25px',
                    paddingX: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: '',
                        justifyContent: '',
                        marginRight: '',
                        gap: '6px'
                    }}
                >

                    <Box sx={{ fontSize: '16px', fontWeight: 400, marginLeft: '', color: '#9A76F5' }}>
                        {moment(new Date(date.seconds * 1000)).format('DD MMMM YYYY')}
                    </Box>
                </div>
                <div>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={visibleFlag} disabled />} label={'Visible to next of kin only'} />
                    </FormGroup>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    {residentsData?.map((resident, index) =>
                        <div key={index} style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "center" }}>
                            {resident.profileImageURL ?
                                <img style={{ width: "24px", height: "24px", borderRadius: "50%" }} src={resident.profileImageURL} />
                                :
                                <Avatar src="" sx={{ backgroundColor: '#9A76F5' }}>
                                    {resident?.firstName?.split(' ')[0]?.slice(0, 1)?.toUpperCase()}
                                    {resident?.lastName?.split(' ')[1]?.slice(0, 1)?.toUpperCase()}
                                </Avatar>
                                //  <AccountCircle style={{ width: "24px", height: "24px", borderRadius: "50%" }} />
                            }
                            {resident.firstName} {resident.lastName}
                        </div>)}
                    {residentsData.length == 0 &&
                        <p>Loading Client ...</p>
                    }
                </div>

                <div className="grid">
                    {images &&
                        images.slice(0, images.length).map((item, index) => {
                            const length = images.length;
                            return <CheckFileType image={item} index={index} length={length} images={images} />;
                        })}
                </div>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {docs &&
                        docs.map((item, index) => {
                            return (
                                <CheckFileTypeDocument
                                    image={item}
                                    index={index}
                                    images={docs}
                                    isInProgress={isInProgress}
                                    downloadURL={downloadURL}
                                    setDownloadURL={setDownloadURL}
                                    download={download}
                                />
                            );
                        })}
                </Box>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                        gap: '1px',
                        marginTop: '20px'
                    }}
                >
                    <Box variant="h6" sx={{ color: '#9A76F5', fontWeight: 500 }} style={{ paddingLeft: '0%' }}>
                        Shared By : {authorName}
                    </Box>
                    {/* <Box variant="h6" sx={{ color: '#9A76F5', fontWeight: 500 }} style={{ paddingLeft: '0%' }}>
                    {localUser?.organization}
                </Box> */}
                </div>
                <div
                    style={{ width: '100%', height: '2px', borderRadius: '2px', backgroundColor: '#9a76f5' }}
                ></div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                        marginTop: '15px',
                        width: '100%'
                    }}
                >
                    {JSON.parse(localStorage.getItem('Rel8User')).role == 'admin' &&
                        <LoadingButton
                            size="small"
                            variant="contained"
                            // loading={isLoading}
                            onClick={() => {
                                // restoreEvent(row);
                                restoreDocument({ ...object, createdAt: timeStamp })
                            }}
                            disabled={
                                JSON.parse(localStorage.getItem('Rel8User')).role ===
                                'Family Member' ?? false
                            }
                            sx={{ display: "flex", flexDirection: "row", gap: "7px" }}
                        >
                            Restore
                            <Icon icon={repeatOutline} style={{ fontSize: '20px' }} />
                        </LoadingButton>
                    }

                </div>


            </Card>
        </Box>
    );
}

export default DeletedDocumentDetails;
