import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import BackButtonImage from '../../../assest/backButton.svg';
// material
import {
  Stack,
  TextField,
  Button,
  Grid,
  Card,
  Box,
  styled,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ref, uploadBytesResumable, getStorage } from 'firebase/storage';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { db, localUser } from '../../../App';
import { uploadFile } from '../user/UploadFile';
import { deleteFolder } from '../user/deleteFiles';
import Happyhdpi from '../../../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../../../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../../../assest/Wellness-Sadhdpi.png';
import EventPreview from '../event/EventPreview';
import SortByTitle from '../event/SortByTitle';

// ----------------------------------------------------------------------
const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function UpdateWellbeingForm({ props, selectOption, setSelectOption }) {
  const navigate = useNavigate();
  const {
    id,
    description,
    commentsCount,
    createdAt,
    likes,
    medias,
    residentId,
    residentName,
    roomId,
    facilityId,
    facilityName,
    date
  } = props;
  const type = props?.type;

  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState();
  const [files, setFile] = useState(medias);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [moodError, setMoodError] = useState('');
  const [optionsInput, setOptionsInput] = useState([]);

  const [preview, setPreview] = useState(false);

  const images =
    files.length != 0
      ? files.filter((image) => {
        console.log('image :: ', image);
        const file =
          (image.type != 'image' && image.type != 'video') ||
          (image.name && image.name.endsWith('.avi')) ||
          (image.name && image.name.endsWith('.mpeg')) ||
          (image.name && image.name.endsWith('.heic'));
        const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
        if (type != 'file') {
          return image;
        }
      })
      : [];

  let mo;
  if (props.mood === 'happy') {
    mo = 1;
  } else if (props.mood === 'neutral') {
    mo = 2;
  } else if (props.mood === 'sad') {
    mo = 3;
  }
  //  select Mood
  const [selectedMood, setselectedMood] = useState(mo ?? 0);

  // ------------------------------------------Facility room resident----------------------------------------
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  let loading = open && optionsInput.length === 0;

  useEffect(async () => {
    if (options.length > 0) {
      return;
    }
    let active = true;

    if (!loading) {
      return undefined;
    }
    console.log('use effect');
    (async () => {
      // await sleep(1e3); // For demo purposes.
      let residentsCollection;
      // for user role
      if (localUser?.role === 'admin') {
        residentsCollection = collection(db, 'residents');
      } else if (localUser?.role === 'Facility Member') {
        residentsCollection = query(
          collection(db, 'residents'),
          where('facilityId', 'in', localUser?.facilityIds)
        );
      } else if (localUser?.role === 'Family Member') {
        residentsCollection = query(
          collection(db, 'residents'),
          where('id', 'in', localUser?.residentIds)
        );
      }
      // const residentsCollection = collection(db, 'residents');
      const residentsPromise = await getDocs(residentsCollection);
      const opt = [];
      residentsPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: `${result.data().firstName} ${result.data().lastName}`
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        setOptions([...optArr]);
        setOptionsInput([...optArr]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      // setOptions([]);
      setOptionsInput([])
    }
  }, [open]);

  const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');

  const RegisterSchema = Yup.object().shape({
    description: Yup.string().required('description is required'),
    // mood: Yup.string().required('Mood is required').nullable(),
    residentId: Yup.string().required('Client is required').nullable(),
    date: Yup.date().required('Date is required')
  });
  const RegisterSchemaDraft = Yup.object().shape({
    description: Yup.string().nullable(),
    // mood: Yup.string().required('Mood is required').nullable(),
    residentId: Yup.string().required('Client is required').nullable(),
    date: Yup.date().required('Date is required')
  });

  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e, downloadUrl, thumbnail) => {
    const s = files.filter((item, index) => index !== e);
    if (thumbnail && RgExp.test(thumbnail)) {
      setDeleteFiles([...deleteFiles, thumbnail, downloadUrl]);
    } else if (RgExp.test(downloadUrl)) setDeleteFiles([...deleteFiles, downloadUrl]);
    setFile(s);
  };

  const handleFileUpload = async (e) => {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      arr.push(e.target.files[i]);
    }
    if (arr.length > 30) {
      e.target.value = "";
      return NotificationManager.error('maximum 30 files to upload');
    }
    const obj = [];

    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (mediaTypeLocal === 'video') {
        thumbnail = await generateVideoThumbnail(arr[i]);
      }
      obj.push({
        file: ImagesArray,
        downloadUrl,
        thumbnail,
        size,
        type: mediaTypeLocal
      });
    }
    setFile([...files, ...obj]);
    e.target.value = "";
  };


  console.log("resident id : ", props.residentId)
  const formik = useFormik({
    initialValues: {
      description: props.description,
      photo: '',
      residentId: props.residentId,
      residentName: props.residentName,
      date: date ? new Date(date.seconds * 1000) : new Date()
    },
    validationSchema: draft ? RegisterSchemaDraft : RegisterSchema,
    onSubmit: async () => {
      if (selectOption == 'preview' || draft) {
        console.log('descriptionsssss ', getFieldProps('description').value);
        AddEventFunc();
      }
    }
  });

  const AddEventFunc = async () => {
    if (selectedMood === 0) {
      return NotificationManager.error('Please select mood');
    }
    if (files.length > 30) {
      return NotificationManager.error('maximum 30 files to upload');
    }
    if (draft) {
      setDraftLoading(true);
    } else {
      setIsLoading(true);
    }

    // const mood = getFieldProps('mood').value;
    const description = getFieldProps('description').value;
    const residentId = getFieldProps('residentId').value;
    const residentName = getFieldProps('residentName').value ?? '';
    const date = getFieldProps('date').value ?? new Date();
    const mediaId = uuidv4();

    let mood;
    if (selectedMood === 1) {
      mood = 'happy';
    } else if (selectedMood === 2) {
      mood = 'neutral';
    } else if (selectedMood === 3) {
      mood = 'sad';
    }

    const residentRef = await getDoc(doc(db, 'residents', residentId));

    if (!residentRef.data()) {
      setIsLoading(false);
      return Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please select another resident, this resident is deleted!'
      });
    }

    const medias = [];
    let itemsProcessed = 0;
    deleteFiles?.forEach((element) => {
      deleteFolder(element);
      const newFiles = files.filter((item) => item.downloadUrl === element);
      setFile(newFiles);
    });
    if (files.length > 0) {
      files.forEach(async (element, index, array) => {
        element.id = id;
        if (!RgExp.test(element.downloadUrl)) {
          if (element.type === 'video') {
            const downloadUrl = await uploadFile(
              'wellness',
              element,
              element.file,
              element.type,
              mediaId
            );
            const thumbnailId = uuidv4();
            const downloadUrlThumbnail = await uploadFile(
              'wellness',
              element,
              element.thumbnail,
              'thumbnail',
              thumbnailId
            );
            medias.push({
              downloadUrl,
              thumbnailId,
              thumbnail: downloadUrlThumbnail,
              name: element.file.name,
              type: element.type,
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
          if (element.type === 'image') {
            const downloadUrl = await uploadFile(
              'wellness',
              element,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl,
              thumbnailId: null,
              thumbnail: null,
              name: element.file.name,
              type: element.type,
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
          if (element.type === 'application') {
            const downloadUrl = await uploadFile(
              'wellness',
              element,
              element.file,
              element.type,
              mediaId
            );
            medias.push({
              downloadUrl,
              thumbnailId: null,
              thumbnail: null,
              name: element.file.name,
              type: 'file',
              size: Math.floor(element.size / 1024, 3),
              id: mediaId
            });
          }
        } else {
          medias.push(element);
        }
        itemsProcessed += 1;
        if (itemsProcessed === array.length) {
          // if (draft) {
          // const draftsRef = doc(db, 'drafts', id);
          // updateDoc(draftsRef, {
          //   description,
          //   medias,
          //   residentId,
          //   residentNmae,
          //   roomId,
          //   facilityId,
          //   facilityName
          // })
          //   .then((res) => {
          //     Swal.fire({
          //       position: 'center',
          //       icon: 'success',
          //       title: 'Draft Updated Successfully',
          //       showConfirmButton: false,
          //       timer: 2000
          //     });
          //     navigate('/home/drafts', { replace: true });
          //   })
          //   .catch((err) => console.log(err));
          // } else {
          const eventsRef = doc(db, 'wellness', id);

          setDoc(
            eventsRef,
            {
              description,
              medias,
              residentsId: [residentId],
              residentName,
              facilityId: residentRef.data().facilityId ?? null,
              facilityName: residentRef.data().facilityName ?? null,
              roomId: residentRef.data().roomId ?? null,
              mood,
              createdAt: new Date(),
              date
            },
            { merge: true }
          )
            .then((res) => {
              if (draft) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Draft Added Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/drafts', { replace: true });
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Wellness Added Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/wellness', { replace: true });
              }
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err.message
              });
            });
          // }
        }
      });
    } else {
      const eventsRef = doc(db, 'wellness', id);

      setDoc(
        eventsRef,
        {
          description,
          medias,
          residentsId: [residentId],
          residentName,
          facilityId: residentRef.data().facilityId ?? null,
          facilityName: residentRef.data().facilityName ?? null,
          roomId: residentRef.data().roomId ?? null,
          mood,
          createdAt: new Date(),
          date
        },
        { merge: true }
      )
        .then((res) => {
          if (draft) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Draft Added Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            navigate('/home/drafts', { replace: true });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Wellness Added Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            navigate('/home/wellness', { replace: true });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.message
          });
        });
    }
  };
  // });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldTouched,
    setFieldValue
  } = formik;

  return (
    <Box sx={{ width: '100%', minWidth: '100%' }}>
      {selectOption == 'preview' ? (
        <EventPreview
          file={files}
          title="Wellness Check"
          type={"wellness"}
          description={{ ...getFieldProps('description') }}
          setPreview={setPreview}
          AddSubmitFunc={AddEventFunc}
          draftLoading=""
          isLoading={isLoading}
          mood={selectedMood}
          setDraft={setDraft}
          head1="Update Wellness"
          setSelectOption={setSelectOption}
          residentName={residentName}
          date={getFieldProps('date').value}
        />
      ) : (
        <Card
          sx={{
            marginTop: '',
            width: '100%',
            minWidth: '100%',
            paddingX: '20px',
            paddingY: '20px'
          }}
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <NotificationContainer />
              <Stack spacing={3}>
                {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
                {/* -----------------------For Mood----------------------------------- */}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  {selectOption == 'add' && (
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Select Client
                      </div>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        onChange={(e, value) => {
                          setFieldValue('residentId', value?.value ?? '');
                          setFieldValue('residentName', value?.title ?? '');
                          console.log(value);
                        }}
                        defaultValue={{ title: props.residentName, value: props.residentId }}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        options={optionsInput}
                        // value={defaultOptions}
                        loading={loading}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            // label="Select Client"
                            onChange={(event) => {
                              setOptionsInput([])
                              loading = true;
                              const filterOptions = options.filter((item) =>
                                item.title.toLowerCase().includes(event.target.value.toLowerCase())
                              );
                              setTimeout(() => {
                                setOptionsInput(filterOptions);

                                loading = false;
                              }, 500)
                            }}
                            {...getFieldProps('resident')}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                            error={Boolean(touched.residentId && errors.residentId)}
                            helperText={touched.residentId && errors.residentId}
                          />
                        )}
                      />
                    </Box>
                  )}
                  {selectOption == 'schedule' && (
                    <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>
                      <Box
                        sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}
                      >
                        <div
                          onClick={() => {
                            setSelectOption('add');
                          }}
                        >
                          <img src={BackButtonImage} style={{ width: '35px', cursor: 'pointer' }} />
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            width: '100%'
                          }}
                        >
                          <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                            Date
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              format="dd/MM/yyyy"
                              inputFormat="dd/MM/yyyy"
                              // label="Date"
                              {...getFieldProps('date')}
                              onChange={(e, value) => setFieldValue('date', e || '')}
                              onChangeRaw={(e) => {
                                setFieldTouched('date', true, true);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  error={Boolean(touched.date && errors.date)}
                                  helperText={touched.date && errors.date}
                                />
                              )}
                              maxDate={new Date()}
                            />
                          </LocalizationProvider>
                        </div>
                      </Box>
                    </Stack>
                  )}
                </Stack>
                {/* {selectOption == 'schedule' && (
                  <Box
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#9A76F5',
                      paddingY: '25px',
                      textAlign: 'center'
                    }}
                  >
                    Schedule your content in advance - coming soon!
                  </Box>
                )} */}
                {/* ************************************************************************************************************************* */}

                {selectOption == 'add' && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      justifyContent: 'center',
                      gap: '12px'
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Current Mood
                    </div>
                    <div
                      fullWidth
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        gap: '60px'
                      }}
                    >
                      {/* -----------------------------------------Happy-------------------------------- */}
                      {selectedMood === 1 ? (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(0);
                          }}
                          style={{ position: 'relative' }}
                        >
                          <Icon
                            icon={checkmarkCircle2Fill}
                            width={25}
                            height={25}
                            style={{
                              color: '#9A76F5',
                              position: 'absolute',
                              marginLeft: '50px',
                              marginTop: '5px'
                            }}
                          />
                          <img src={Happyhdpi} alt="H" width="70" height="70" />
                        </div>
                      ) : (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(1);
                          }}
                        >
                          <img src={Happyhdpi} alt="H" width="70" height="70" />
                        </div>
                      )}
                      {/* -----------------------------------------Happy-------------------------------- */}
                      {/* -----------------------------------------Normal-------------------------------- */}
                      {selectedMood === 2 ? (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(0);
                          }}
                          style={{ position: 'relative' }}
                        >
                          <Icon
                            icon={checkmarkCircle2Fill}
                            width={25}
                            height={25}
                            style={{
                              color: '#9A76F5',
                              position: 'absolute',
                              marginLeft: '50px',
                              marginTop: '5px'
                            }}
                          />
                          <img src={Neutralhdpi} alt="H" width="70" height="70" />
                        </div>
                      ) : (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(2);
                          }}
                        >
                          <img src={Neutralhdpi} alt="H" width="70" height="70" />
                        </div>
                      )}
                      {/* -----------------------------------------Normal-------------------------------- */}
                      {/* -----------------------------------------Sad-------------------------------- */}
                      {selectedMood === 3 ? (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(0);
                          }}
                          style={{ position: 'relative' }}
                        >
                          <Icon
                            icon={checkmarkCircle2Fill}
                            width={25}
                            height={25}
                            style={{
                              color: '#9A76F5',
                              position: 'absolute',
                              marginLeft: '50px',
                              marginTop: '5px'
                            }}
                          />
                          <img src={Sadhdpi} alt="H" width="70" height="70" />
                        </div>
                      ) : (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(3);
                          }}
                        >
                          <img src={Sadhdpi} alt="H" width="70" height="70" />
                        </div>
                      )}
                      {/* -----------------------------------------Sad-------------------------------- */}
                    </div>
                    {moodError && <p style={{ color: 'red', fontSize: '12px' }}>{moodError}</p>}
                  </div>
                )}

                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Description
                    </div>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={20}
                      // label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Box>
                )}

                {selectOption == 'add' && (
                  <div className="grid">
                    {files.length > 0 &&
                      files.map((item, index) => {
                        // console.log('item ; ', item);
                        return (
                          <>
                            {!(
                              (item.type != 'image' && item.type != 'video') ||
                              item.name?.endsWith('.avi') ||
                              item.name?.endsWith('.mpeg')
                            ) && (
                                <div
                                  key={item.id}
                                  style={{ position: 'relative' }}
                                  className={
                                    item === images[0] && images.length > 7
                                      ? 'firstIndex'
                                      : 'allIndex'
                                  }
                                >
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '-3px',
                                      right: '-3px',
                                      zIndex: 100
                                    }}
                                  >
                                    <Icon
                                      icon="ci:close-big"
                                      onClick={() =>
                                        deleteFile(index, item.downloadUrl, item.thumbnail)
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        borderRadius: '100%',
                                        outline: '1px',
                                        outlineColor: '#9A76F5'
                                      }}
                                    />
                                  </div>
                                  <Card style={{}}>
                                    <Box sx={{ pt: '100%', position: 'relative' }}>
                                      <ProductImageStyle
                                        alt="Image"
                                        src={item.thumbnail ?? item.downloadUrl}
                                      />
                                    </Box>
                                  </Card>
                                </div>
                              )}
                          </>
                        );
                      })}
                  </div>
                )}

                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {files.length > 0 &&
                      files.map((item, index) => {
                        return (
                          <>
                            {((item.type != 'image' && item.type != 'video') ||
                              item.name?.endsWith('.avi') ||
                              item.name?.endsWith('.mpeg')) && (
                                <Box
                                  sx={{
                                    maxWidth: '250px',
                                    width: 'full',
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Icon
                                    icon="ci:close-big"
                                    onClick={() => deleteFile(index)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <Box
                                    sx={{
                                      maxWidth: '50px',
                                      maxHeight: '60px',
                                      minWidth: '50px',
                                      minHeight: '60px',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <img
                                      src={item.thumbnail}
                                      style={{ width: '100%', object: 'contain', height: '100%' }}
                                    />
                                  </Box>
                                  <div style={{ fontSize: '12px', fontWeight: 500 }}>
                                    {item.name && item.name.length > 25
                                      ? item.name.slice(0, 22) + '...'
                                      : item.name}
                                  </div>
                                </Box>
                              )}
                          </>
                        );
                      })}
                  </Box>
                )}

                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button variant="contained" component="label">
                      Add attachment
                      <input
                        type="file"
                        hidden
                        multiple
                        fullWidth
                        accept="image/*,video/*"
                        onChange={handleFileUpload}
                        error={Boolean(touched.photo && errors.photo)}
                        helperText={touched.photo && errors.photo}
                      />
                    </Button>
                  </Stack>
                )}

                {selectOption == 'add' ? (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      onClick={() => {
                        if (selectedMood == 0) {
                          setMoodError('Please Select Mood');
                          return;
                        } else {
                          setMoodError('');
                        }
                        console.log("draft running")
                        setDraft(true);

                      }}
                    >
                      Save draft
                    </LoadingButton>

                    <LoadingButton
                      size="large"
                      fullWidth
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        if (selectedMood == 0) {
                          setMoodError('Please Select Mood');
                          return;
                        } else {
                          setMoodError('');
                        }
                        if (
                          getFieldProps('description').value &&
                          getFieldProps('residentName').value &&
                          selectedMood != 0
                        ) {
                          setSelectOption('schedule');
                        }
                      }}
                    >
                      Schedule
                    </LoadingButton>
                  </Stack>
                ) : (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        console.log("draft running 2")
                        setDraft(true);

                      }}
                      loading={isLoading}
                    >
                      Save draft
                    </LoadingButton>

                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        if (
                          getFieldProps('description').value &&
                          getFieldProps('residentName').value &&
                          selectedMood != 0
                        ) {
                          setSelectOption('preview');
                        }
                      }}
                    >
                      Preview
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}
    </Box>
  );
}
