import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import { useNavigate } from 'react-router-dom';
import file from '../../../assest/file.png';
import filePNG from '../../../assest/file.png';
import AviThumbnail from '../../../assest/aviThumbnail.png';
import HEICThumbnail from '../../../assest/heicThumbnail.png';
import MPEGThumbnail from '../../../assest/mpegThumbnail.png';
import AudioThumbnail from '../../../assest/audioThumbnail.png';
import Textthumbnail from '../../../assest/textThumbnail.png';
import BackButtonImage from '../../../assest/backButton.svg';
import PdfThumbnail from '../../../assest/pdfThumbnail.webp';
// material
import {
  Stack,
  TextField,
  Button,
  Grid,
  // Icon,
  Card,
  Box,
  styled,
  Autocomplete,
  CircularProgress
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getStorage } from 'firebase/storage';
import { collection, setDoc, doc, getDocs, query, getDoc, where } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { uploadFile } from '../user/UploadFile';
import { db, localUser } from '../../../App';
import Happyhdpi from '../../../assest/Wellness-Happyhdpi.png';
import Neutralhdpi from '../../../assest/Wellness-Neutralhdpi.png';
import Sadhdpi from '../../../assest/Wellness-Sadhdpi.png';
import EventPreview from '../event/EventPreview';
import SortByTitle from '../event/SortByTitle';

import { useLocation } from 'react-router-dom';
import { filter } from 'lodash';
// ----------------------------------------------------------------------
const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

export default function WellbeingForm({ setSelectOption, selectOption }) {
  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [moodError, setMoodError] = useState('');
  const images =
    file.length != 0
      ? file.filter((image) => {
        console.log('image :: ', image);
        const file =
          (image.type != 'image' && image.type != 'video') ||
          (image.name && image.name.endsWith('.avi')) ||
          (image.name && image.name.endsWith('.mpeg')) ||
          (image.name && image.name.endsWith('.heic'));
        const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
        if (type != 'file') {
          return image;
        }
      })
      : [];
  const docs =
    file.length != 0
      ? file.filter((image) => {
        console.log('image :: ', image);
        const file =
          (image.type != 'image' && image.type != 'video') ||
          (image.name && image.name.endsWith('.avi')) ||
          (image.name && image.name.endsWith('.mpeg')) ||
          (image.name && image.name.endsWith('.heic'));
        const type = file ? 'file' : image.type === 'image' ? 'image' : 'video';
        if (type == 'file') {
          return image;
        }
      })
      : [];

  const [preview, setPreview] = useState(false);

  // select Mood
  const [selectedMood, setselectedMood] = useState(0);

  const location = useLocation();
  // ---------------------------for rooms and resident------------------------
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsInput, setOptionsInput] = useState([]);
  let loading = open && optionsInput.length === 0;

  const ProductDocumentStyle = styled('div')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
  });

  // -------------------------resident Autocomplete----------------------------
  useEffect(() => {
    if (options.length > 0) {
      return;
    }
    let active = true;

    if (!loading) {
      return;
    }
    console.log("use effect ", options.length);
    (async () => {
      // await sleep(1e3); // For demo purposes.
      let residentsCollection;
      // for user role
      if (localUser?.role === 'admin') {
        residentsCollection = collection(db, 'residents');
      } else if (localUser?.role === 'Facility Member') {
        residentsCollection = query(
          collection(db, 'residents'),
          where('facilityId', 'in', localUser?.facilityIds)
        );
      } else if (localUser?.role === 'Family Member') {
        residentsCollection = query(
          collection(db, 'residents'),
          where('id', 'in', localUser?.residentIds)
        );
      }

      // const residentsCollection = collection(db, 'residents');
      const residentsPromise = await getDocs(residentsCollection);
      const opt = [];
      residentsPromise.forEach((result) => {
        opt.push({
          value: result.data().id,
          title: `${result.data().firstName} ${result.data().lastName}`
        });
      });
      if (active) {
        const optArr = SortByTitle(opt);
        let uniqueOptions = [];
        optArr.forEach((item) => {
          if (uniqueOptions.indexOf(item) < 0) {
            uniqueOptions.push(item);
          }
        });
        console.log('optArr ', uniqueOptions);

        setOptions([...uniqueOptions]);
        setOptionsInput([...uniqueOptions]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);



  useEffect(() => {
    if (!open) {
      // setOptions([]);
      setOptionsInput([]);
    }
  }, [open]);

  //Duplicate
  //Duplicate
  //Duplicate
  //Duplicate

  useEffect(() => {
    if (location.state) {
      console.log('location :: ', location.state);
      setFieldValue(
        'description',
        location.state.props.description ? location.state.props.description : ''
      );
      setFieldValue(
        'residentName',
        location.state.props.residentName ? location.state.props.residentName : ''
      );
      setFieldValue(
        'residentId',
        location.state.props.residentId ? location.state.props.residentId : ''
      );
      setFile(location.state.props.medias ? location.state.props.medias : []);
      if (location.state.props.mood) {
        if (location.state.props.mood === 'happy') {
          setselectedMood(1);
        } else if (location.state.props.mood === 'neutral') {
          setselectedMood(2);
        } else if (location.state.props.mood === 'sad') {
          setselectedMood(3);
        }
      }
    }
  }, []);

  // ---------------------------for rooms and resident-------------------------------------------

  const phoneRegExp = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/;
  const RegisterSchema = Yup.object().shape({
    description: Yup.string().required('Description is required.').nullable(),
    // mood: Yup.string().required('Mood is required').nullable(),
    residentId: Yup.string().required('Client is required').nullable(),
    date: Yup.date().required('Date is required')
  });

  const RegisterSchemaDraft = Yup.object().shape({
    description: Yup.string().nullable(),
    // mood: Yup.string().required('Mood is required').nullable(),
    residentId: Yup.string().required('Client is required').nullable(),
    date: Yup.date().nullable()
  });
  const generateVideoThumbnail = (file) =>
    new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL('image/jpeg'));
      };
    });

  const deleteFile = (e) => {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  };

  const handleFileUpload = async (e) => {
    const arr = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      arr.push(e.target.files[i]);
    }
    const obj = [];

    for (let i = 0; i < arr.length; i += 1) {
      let thumbnail;
      const ImagesArray = arr[i];
      const { size } = arr[i];
      if (size / 1024 > 20480) {
        e.target.value = "";
        return NotificationManager.error('File size is greater than 20 mb');
      }
      if (file.length + arr.length > 30) {
        e.target.value = "";
        return NotificationManager.error('maximum 6 files to upload');
      }
      const downloadUrl = URL.createObjectURL(arr[i]);
      const mediaTypeLocal = arr[i].type.split('/')[0];
      if (mediaTypeLocal !== 'application' && !arr[i].name.endsWith('.mpeg')) {
        if (mediaTypeLocal === 'video' && !arr[i].name.endsWith('.avi')) {
          thumbnail = await generateVideoThumbnail(arr[i]);
        }
        if (arr[i].name.endsWith('.heic')) {
          thumbnail = HEICThumbnail;
        }
        if (arr[i].name.endsWith('.avi')) {
          thumbnail = AviThumbnail;
        }
        if (mediaTypeLocal === 'audio') {
          thumbnail = AudioThumbnail;
        }
        if (mediaTypeLocal === 'text') {
          console.log('run ', arr[i]);
          thumbnail = Textthumbnail;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          name: arr[i]?.name,
          size,
          type: mediaTypeLocal
        });
      } else {
        if (arr[i].name.endsWith('.pdf')) {
          thumbnail = PdfThumbnail;
        } else if (arr[i].name.endsWith('.mpeg')) {
          thumbnail = MPEGThumbnail;
        } else if (arr[i].name.endsWith('.heic')) {
          thumbnail = HEICThumbnail;
        } else {
          thumbnail = filePNG;
        }
        obj.push({
          file: ImagesArray,
          downloadUrl,
          thumbnail,
          size,
          name: arr[i]?.name,
          type: 'file'
        });
      }
    }

    setFile([...file, ...obj]);
    // console.log('working');
    e.target.value = "";
  };

  const saveDraft = () => { };

  const formik = useFormik({
    initialValues: {
      description: '',
      photo: '',
      residentId: '',
      date: new Date()
    },
    validationSchema: draft ? RegisterSchemaDraft : RegisterSchema,
    onSubmit: async () => {
      console.log('select mood in ', selectedMood);
      if (selectOption == 'preview' || draft) {
        AddEventFunc();
      }
    }
  });

  const AddEventFunc = async () => {
    console.log('select mood  inside ', selectedMood);
    if (selectedMood === 0) {
      setMoodError('Please select mood');
      console.log('select mood ', selectedMood);
      return NotificationManager.error('Please select mood');
    } else {
      setMoodError('');
    }
    if (selectOption == 'schedule') {
      return;
    }
    if (file.length > 30) {
      return NotificationManager.error('maximum 30 files to upload');
    }

    // const mood = getFieldProps('mood').value;
    const description = getFieldProps('description').value;
    const residentId = getFieldProps('residentId').value;
    const residentName = getFieldProps('residentName').value;
    const date = getFieldProps('date').value;

    console.log('date : ', date);
    let mood;
    if (selectedMood === 1) {
      mood = 'happy';
    } else if (selectedMood === 2) {
      mood = 'neutral';
    } else if (selectedMood === 3) {
      mood = 'sad';
    }

    const residentRef = await getDoc(doc(db, 'residents', residentId));
    console.log('residentRes ', residentRef.data());
    // const userRef = query(
    //   collection(db, 'users'),
    //   where('residentIds', 'array-contains', residentId)
    // );

    // const users = await getDocs(userRef);
    // const viewedByUserIds = [];
    // users?.forEach((user) => {
    //   viewedByUserIds.push(user.data()?.id);
    // });

    // console.log(residentRef.data());
    // console.log(residentRef.data().facilityId);
    // console.log(residentRef.data().facilityName);
    // console.log(residentRef.data().roomId);

    const storage = getStorage();
    let noticeboardsRef;
    if (draft) {
      console.log('drafts loading done ');
      noticeboardsRef = doc(collection(db, 'drafts'));
    } else {
      noticeboardsRef = doc(collection(db, 'wellness'));
    }

    const medias = [];
    let itemsProcessed = 0;

    // if (file.length === 0) {
    //   setIsLoading(false);
    //   return NotificationManager.error("You didn't select file");
    // }

    // if (draft) {
    //   setDraftLoading(true);
    // } else {

    // }
    setTimeout(() => {
      NotificationManager.warning('Please wait! File is being upload');
    }, 10000);

    // console.log(file.length);
    if (draft) {
      setDraftLoading(true);
    } else {
      setIsLoading(true);
    }
    if (file.length > 0) {
      console.log('inner file');
      file.forEach(async (element, index, array) => {
        console.log(noticeboardsRef);
        const mediaId = uuidv4();
        if (element.type === 'video') {
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile(
              'wellness',
              noticeboardsRef,
              element.file,
              element.file.type,
              mediaId
            );
          const thumbnailId = uuidv4();
          const downloadUrlThumbnail = location.state
            ? element.thumbnail
            : await uploadFile(
              'wellness',
              noticeboardsRef,
              element.thumbnail,
              'thumbnail',
              thumbnailId
            );
          medias.push({
            downloadUrl,
            thumbnailId,
            thumbnail: downloadUrlThumbnail,
            name: location.state ? element.name : element.file.name,
            type: element.type,
            size: Math.floor(element.size / 1024, 3),
            id: mediaId
          });
        }

        if (element.type === 'image') {
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile(
              'wellness',
              noticeboardsRef,
              element.file,
              element.file.type,
              mediaId
            );
          medias.push({
            downloadUrl,
            thumbnailId: null,
            thumbnail: null,
            name: location.state ? element.name : element.file.name,
            type: element.type,
            size: Math.floor(element.size / 1024, 3),
            id: mediaId
          });
        }

        if (element.type !== 'image' && element.type !== 'video') {
          console.log('element ', element);
          const downloadUrl = location.state
            ? element.downloadUrl
            : await uploadFile(
              'wellness',
              noticeboardsRef,
              element.file,
              element.file.type,
              mediaId
            );
          const thumbnailId = uuidv4();
          medias.push({
            downloadUrl,
            thumbnailId: thumbnailId,
            thumbnail: element.thumbnail,
            name: location.state ? element.name : element.file.name,
            type: 'file',
            size: Math.floor(element.size / 1024, 3),
            id: mediaId
          });
        }

        itemsProcessed += 1;
        if (itemsProcessed === array.length) {
          // if (draft) {
          //   setDoc(noticeboardsRef, {
          //     id: noticeboardsRef.id,
          //     type: 'noticeboard',
          //     createdAt: new Date(),
          //     creatorId: 'admin',
          //     object: {
          //       id: noticeboardsRef.id,
          //       description,
          //       medias,
          //       commentsCount: 0,
          //       likes: [],
          //       // uid: null,
          //       postedById: 'admin',
          //       createdAt: new Date(),
          //       roomsId,
          //       facilitiesId,
          //       facilityName,
          //       residentsId
          //     }
          //   })
          //     .then((res) => {
          //       Swal.fire({
          //         position: 'center',
          //         icon: 'success',
          //         title: 'Draft saved Successfully',
          //         showConfirmButton: false,
          //         timer: 2000
          //       });
          //       setDraft(false);
          //       setDraftLoading(false);
          //     })
          //     .catch((err) => console.log(err));
          // } else {
          console.log('noticeboardsRef :: ', noticeboardsRef);
          const object = {
            id: noticeboardsRef.id,
            description,
            medias,
            commentsCount: 0,
            likes: [],
            createdById: JSON.parse(localStorage.getItem('Rel8User')).id,
            authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
            createdAt: date,
            // residentId,
            residentsId: [residentId],
            residentName,
            mood,
            facilityId: residentRef.data().facilityId,
            facilityName: residentRef.data().facilityName,
            roomId: residentRef.data().roomId,
            type: 'wellness',
            scheduleAt: date
          };
          setDoc(noticeboardsRef, {
            id: noticeboardsRef.id,
            description,
            medias,
            commentsCount: 0,
            likes: [],
            createdById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
            authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
              }`,
            authorFirstName: JSON.parse(localStorage.getItem('Rel8User'))?.firstname,
            authorLastName: JSON.parse(localStorage.getItem('Rel8User'))?.lastname,
            authorProfile: JSON.parse(localStorage.getItem('Rel8User'))?.avatarUrl,
            createdAt: new Date(),
            date,
            residentsId: [residentId],
            residentName,
            mood,
            facilityId: residentRef.data().facilityId,
            facilityName: residentRef.data().facilityName,
            roomId: residentRef.data().roomId,
            creatorId: JSON.parse(localStorage.getItem('Rel8User'))?.id,
            type: 'wellness',
            object,
            title: mood,
            scheduleAt: date
          })
            .then((res) => {
              if (draft) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Draft Added Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/drafts', { replace: true });
              } else {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Wellness Added Successfully',
                  showConfirmButton: false,
                  timer: 2000
                });
                navigate('/home/wellness', { replace: true });
              }

            })
            .catch((err) => console.log(err));
          // }
        }
      });
      const object = {
        id: noticeboardsRef.id,
        description,
        medias,
        commentsCount: 0,
        likes: [],
        createdById: JSON.parse(localStorage.getItem('Rel8User')).id,
        authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
        createdAt: date,
        // residentId,
        residentsId: [residentId],
        residentName,
        mood,
        facilityId: residentRef.data().facilityId,
        facilityName: residentRef.data().facilityName,
        roomId: residentRef.data().roomId,
        type: 'wellness',
        scheduleAt: date
      };
      // -----------------------------------------Comment Notifications------------------------------------
      const commentNotifiRef = doc(collection(db, 'notifications'));
      await setDoc(commentNotifiRef, {
        id: commentNotifiRef.id,
        body: `${JSON.parse(localStorage.getItem('Rel8User')).firstname
          } has created a new wellness check`,
        commentId: noticeboardsRef.id,
        createdAt: date,
        profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
        title: 'A new wellness check',
        topic: `${residentRef.data()?.facilityId}_wellness`,
        viewedBy: [],
        senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
        receiverId: '',
        object,
        senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname}
           ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname}`,
        type: 'wellness'
      });
      // -----------------------------------------Comment Notifications------------------------------------
    } else {
      const object = {
        id: noticeboardsRef.id,
        description,
        medias,
        commentsCount: 0,
        likes: [],
        createdById: JSON.parse(localStorage.getItem('Rel8User')).id,
        authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
        createdAt: date,
        // residentId,
        residentsId: [residentId],
        residentName,
        mood,
        facilityId: residentRef.data().facilityId,
        facilityName: residentRef.data().facilityName,
        roomId: residentRef.data().roomId,
        scheduleAt: date
      };

      setDoc(noticeboardsRef, {
        id: noticeboardsRef.id,
        description,
        medias,
        commentsCount: 0,
        likes: [],
        createdById: JSON.parse(localStorage.getItem('Rel8User'))?.id,
        authorName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname} ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname
          }`,
        authorFirstName: JSON.parse(localStorage.getItem('Rel8User'))?.firstname,
        authorLastName: JSON.parse(localStorage.getItem('Rel8User'))?.lastname,
        authorProfile: JSON.parse(localStorage.getItem('Rel8User'))?.avatarUrl,
        createdAt: new Date(),
        date,
        residentsId: [residentId],
        residentName,
        mood,
        object,
        facilityId: residentRef.data().facilityId,
        facilityName: residentRef.data().facilityName,
        roomId: residentRef.data().roomId,
        creatorId: JSON.parse(localStorage.getItem('Rel8User'))?.id,
        type: 'wellness',
        title: mood,
        scheduleAt: date
      })
        .then((res) => {
          if (draft) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Draft Added Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            navigate('/home/drafts', { replace: true });
          } else {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Wellness Added Successfully',
              showConfirmButton: false,
              timer: 2000
            });
            navigate('/home/wellness', { replace: true });
          }
        })
        .catch((err) => console.log(err));

      // -----------------------------------------Comment Notifications------------------------------------
      const commentNotifiRef = doc(collection(db, 'notifications'));
      await setDoc(commentNotifiRef, {
        id: commentNotifiRef.id,
        body: `${JSON.parse(localStorage.getItem('Rel8User')).firstname
          } has created a new wellness check`,
        commentId: noticeboardsRef.id,
        createdAt: date,
        profileImgUrl: JSON.parse(localStorage.getItem('Rel8User')).avatarUrl ?? null,
        title: 'A new wellness check',
        topic: `${residentRef.data()?.facilityId}_wellness`,
        viewedBy: [],
        senderId: JSON.parse(localStorage.getItem('Rel8User')).id,
        receiverId: '',
        object,
        senderName: `${JSON.parse(localStorage.getItem('Rel8User'))?.firstname}
            ${JSON.parse(localStorage.getItem('Rel8User'))?.lastname}`,
        type: 'wellness'
      });
      // -----------------------------------------Comment Notifications------------------------------------
    }
  };
  // });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldTouched,
    setFieldValue
  } = formik;
  console.log('optionsInput ', optionsInput);
  return (
    <Box sx={{ width: '100%', minWidth: '100%' }}>
      {selectOption == 'preview' ? (
        <EventPreview
          type={"wellness"}
          file={file}
          title="Wellness Check"
          description={{ ...getFieldProps('description') }}
          setPreview={setPreview}
          AddSubmitFunc={AddEventFunc}
          draftLoading={draftLoading}
          isLoading={isLoading}
          mood={selectedMood}
          head1="Add Wellness"
          setSelectOption={setSelectOption}
          setDraft={setDraft}
          handleSubmit={handleSubmit}
          date={getFieldProps('date').value}
          residentName={getFieldProps('residentName').value}
        />
      ) : (
        <Card
          sx={{
            marginTop: '',
            width: '100%',
            minWidth: '100%',
            paddingX: '20px',
            paddingY: '20px'
          }}
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <NotificationContainer />
              <Stack spacing={3}>
                {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Title"
              {...getFieldProps('title')}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
          </Stack> */}

                {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
                {/* -----------------------For Mood----------------------------------- */}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  {selectOption == 'add' && (
                    <Box
                      sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}
                    >
                      <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                        Client
                      </div>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        onChange={(e, value) => {
                          console.log('value : ', value);
                          setFieldValue('residentId', value?.value ?? '');
                          setFieldValue('residentName', value?.title ?? '');
                        }}
                        defaultValue={{
                          title: location.state
                            ? location.state.props.residentName
                            : getFieldProps('residentName').value ?? '',
                          value: location.state
                            ? location.state.props.residentId
                            : getFieldProps('residentId').value ?? ''
                        }}
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        options={optionsInput}
                        loading={loading}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            // label="Select Client"
                            {...getFieldProps('resident')}
                            onChange={(event) => {
                              setOptionsInput([])
                              loading = true;
                              const filterOptions = options.filter((item) =>
                                item.title.toLowerCase().includes(event.target.value.toLowerCase())
                              );
                              setTimeout(() => {
                                setOptionsInput(filterOptions);
                                loading = false;
                              }, 500)
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                            error={Boolean(touched.residentId && errors.residentId)}
                            helperText={touched.residentId && errors.residentId}
                          />
                        )}
                      />
                    </Box>
                  )}
                  {selectOption == 'schedule' && (
                    <Stack sx={{ width: '100%', '& > *': { flex: 1 } }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '20px',
                          width: '100%'
                        }}
                      >
                        {selectOption == 'schedule' && (
                          <div
                            onClick={() => {
                              setSelectOption('add');
                            }}
                          >
                            <img
                              src={BackButtonImage}
                              style={{ width: '35px', cursor: 'pointer' }}
                            />
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            width: '100%'
                          }}
                        >
                          <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                            Date
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              format="dd/MM/yyyy"
                              inputFormat="dd/MM/yyyy"
                              // label="Date"
                              {...getFieldProps('date')}
                              onChange={(e, value) => setFieldValue('date', e || '')}
                              onChangeRaw={(e) => {
                                setFieldTouched('date', true, true);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  error={Boolean(touched.date && errors.date)}
                                  helperText={touched.date && errors.date}
                                />
                              )}
                              maxDate={new Date()}
                            />
                          </LocalizationProvider>
                        </div>
                      </Box>
                    </Stack>
                  )}
                </Stack>

                {/* {selectOption == 'schedule' && (
                  <Box
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#9A76F5',
                      paddingY: '25px',
                      textAlign: 'center'
                    }}
                  >
                    Schedule your content in advance - coming soon!
                  </Box>
                )} */}

                {selectOption == 'add' && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      justifyContent: 'center',
                      gap: '12px'
                    }}
                  >
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Current Mood
                    </div>
                    <div
                      fullWidth
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        gap: '7%'
                      }}
                    >
                      {/* -----------------------------------------Happy-------------------------------- */}
                      {selectedMood === 1 ? (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(0);
                          }}
                          style={{ position: 'relative' }}
                        >
                          <Icon
                            icon={checkmarkCircle2Fill}
                            width={25}
                            height={25}
                            style={{
                              color: '#9A76F5',
                              position: 'absolute',
                              marginLeft: '50px',
                              marginTop: '5px'
                            }}
                          />
                          <img src={Happyhdpi} alt="H" width="70" height="70" />
                        </div>
                      ) : (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(1);
                            setMoodError('');
                          }}
                        >
                          <img src={Happyhdpi} alt="H" width="70" height="70" />
                        </div>
                      )}
                      {/* -----------------------------------------Happy-------------------------------- */}
                      {/* -----------------------------------------Normal-------------------------------- */}
                      {selectedMood === 2 ? (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(0);
                          }}
                          style={{ position: 'relative' }}
                        >
                          <Icon
                            icon={checkmarkCircle2Fill}
                            width={25}
                            height={25}
                            style={{
                              color: '#9A76F5',
                              position: 'absolute',
                              marginLeft: '50px',
                              marginTop: '5px'
                            }}
                          />
                          <img src={Neutralhdpi} alt="H" width="70" height="70" />
                        </div>
                      ) : (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(2);
                            setMoodError('');
                          }}
                        >
                          <img src={Neutralhdpi} alt="H" width="70" height="70" />
                        </div>
                      )}
                      {/* -----------------------------------------Normal-------------------------------- */}
                      {/* -----------------------------------------Sad-------------------------------- */}
                      {selectedMood === 3 ? (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(0);
                          }}
                          style={{ position: 'relative' }}
                        >
                          <Icon
                            icon={checkmarkCircle2Fill}
                            width={25}
                            height={25}
                            style={{
                              color: '#9A76F5',
                              position: 'absolute',
                              marginLeft: '50px',
                              marginTop: '5px'
                            }}
                          />
                          <img src={Sadhdpi} alt="H" width="70" height="70" />
                        </div>
                      ) : (
                        <div
                          role="button"
                          tabIndex={0}
                          onMouseDown={() => {
                            setselectedMood(3);
                            setMoodError('');
                          }}
                        >
                          <img src={Sadhdpi} alt="H" width="70" height="70" />
                        </div>
                      )}
                      {/* -----------------------------------------Sad-------------------------------- */}
                    </div>
                    {moodError && <p style={{ color: 'red', fontSize: '12px' }}>{moodError}</p>}
                  </div>
                )}

                {/* ************************************************************************************************************************* */}
                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Description
                    </div>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      maxRows={20}
                      // label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Box>
                )}
                {selectOption == 'add' && (
                  <div className="grid">
                    {file.length > 0 &&
                      file.map((item, index) => {
                        return (
                          <>
                            {!(
                              (item.type != 'image' && item.type != 'video') ||
                              item.name.endsWith('.avi') ||
                              item.name.endsWith('.mpeg')
                            ) && (
                                <div
                                  key={item.id}
                                  style={{ position: 'relative' }}
                                  className={
                                    item === images[0] && images.length > 7
                                      ? 'firstIndex'
                                      : 'allIndex'
                                  }
                                >
                                  {' '}
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '-3px',
                                      right: '-3px',
                                      zIndex: 100
                                    }}
                                  >
                                    <Icon
                                      icon="ci:close-big"
                                      onClick={() => deleteFile(index)}
                                      style={{
                                        cursor: 'pointer',
                                        backgroundColor: 'white',
                                        borderRadius: '100%',
                                        outline: '1px',
                                        outlineColor: '#9A76F5'
                                      }}
                                    />
                                  </div>
                                  <Card>
                                    <Box sx={{ pt: '100%', position: 'relative', opacity: '1' }}>
                                      <ProductImageStyle
                                        alt="Image"
                                        src={item.thumbnail ?? item.downloadUrl}
                                      />
                                    </Box>
                                  </Card>
                                </div>
                              )}
                          </>
                        );
                      })}
                  </div>
                )}

                {selectOption == 'add' && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {file.length > 0 &&
                      file.map((item, index) => {
                        return (
                          <>
                            {((item.type != 'image' && item.type != 'video') ||
                              item.name.endsWith('.avi') ||
                              item.name.endsWith('.mpeg')) && (
                                <Box
                                  sx={{
                                    maxWidth: '250px',
                                    width: 'full',
                                    display: 'flex',
                                    gap: '5px',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Icon
                                    icon="ci:close-big"
                                    onClick={() => deleteFile(index)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <Box
                                    sx={{
                                      maxWidth: '50px',
                                      maxHeight: '60px',
                                      minWidth: '50px',
                                      minHeight: '60px',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <img
                                      src={item.thumbnail}
                                      style={{ width: '100%', object: 'contain', height: '100%' }}
                                    />
                                  </Box>
                                  <div style={{ fontSize: '12px', fontWeight: 500 }}>
                                    {item.name && item.name.length > 25
                                      ? item.name.slice(0, 22) + '...'
                                      : item.name}
                                    {' ('}
                                    {item.size ?? ''}
                                    {' KB)'}
                                  </div>
                                </Box>
                              )}
                          </>
                        );
                      })}
                  </Box>
                )}
                {selectOption == 'add' && (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button variant="contained" component="label">
                      Add attachment
                      <input
                        type="file"
                        hidden
                        multiple
                        fullWidth
                        accept="image/*,video/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.mp3, .heic"
                        onChange={handleFileUpload}
                        error={Boolean(touched.photo && errors.photo)}
                        helperText={touched.photo && errors.photo}
                      />
                    </Button>
                  </Stack>
                )}

                {selectOption == 'add' ? (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      onClick={() => {
                        setDraft(true);
                        if (selectedMood == 0) {
                          setMoodError('Please Select Mood');
                        } else {
                          setMoodError('');
                        }
                      }}
                      variant="contained"
                      loading={draftLoading}
                    >
                      Save draft
                    </LoadingButton>

                    <LoadingButton
                      fullWidth
                      size="large"
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        if (selectedMood == 0) {
                          setMoodError('Please Select Mood');
                          return;
                        } else {
                          setMoodError('');
                        }

                        if (
                          getFieldProps('description').value &&
                          getFieldProps('residentName').value
                        ) {
                          setSelectOption('schedule');
                        }
                      }}
                    >
                      Schedule
                    </LoadingButton>
                  </Stack>
                ) : (
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={draftLoading}
                      onClick={() => {
                        setDraft(true);
                      }}
                    >
                      Save draft
                    </LoadingButton>

                    <LoadingButton
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={() => {
                        setSelectOption('preview');
                      }}
                    >
                      Preview
                    </LoadingButton>
                  </Stack>
                )}

                {/* <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="outlined"
              loading={draftLoading}
              onClick={() => setDraft(true)}
            >
              Save Drafts
            </LoadingButton> */}
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      )}
    </Box>
  );
}
