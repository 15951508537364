import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import refreshOutline from '@iconify/icons-eva/refresh-outline';
import archiveOutline from '@iconify/icons-eva/archive-outline';
// import send from "@iconify/icons-eva/message-square-outline"
import send from "@iconify/icons-ant-design/send"

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import Swal from 'sweetalert2';
import flipFill from '@iconify/icons-eva/flip-fill';
import { restoreEvent } from '../../../pages/facilityMember/DeletedUsers';
import { db } from '../../../App';
import { cloudFuncBaseUrl } from 'src/utils/config';

// ----------------------------------------------------------------------
const localUser = JSON.parse(localStorage.getItem('Rel8User'));
//-------------------------------------------------------
export const deleteUserPermanently = (id, member) => {
  try {
    // ----------------------------------confirm box----------------------
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to delete this ${member}!`,
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        // //  --------------loading---------------------
        let timerInterval;
        Swal.fire({
          title: 'Processing...',
          html: 'I will close in few <b></b> Moments.',
          // timer: 2000,
          allowOutsideClick: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector('b');
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer');
          }
        });

        // // -------------------------- end loading-------------------------
        try {
          await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/adminDeleteFamilyAndFacilityMember`,
            data: {
              userId: id
            }
          });

          // -------------------
          await Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Family member has been deleted.',
            showConfirmButton: false,
            timer: 1500
          });
          window.location.replace('/administration/family-members');
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message
          });
        }
      }
    });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: error.message
    });
  }
};

export default function FamilyMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  // const { id, email, restrictAt, deletedAt } = props;
  // email,
  // residentIds,
  // firstname,
  // lastname,
  // residentsName,
  // image,
  // subRole,
  // facilityIds
  const {
    id,
    firstname,
    lastname,
    email,
    residentIds,
    facilityId,
    createdAt,
    avatarUrl,
    facilityName,
    role,
    status,
    restrictAt,
    deletedAt,
    subRole,
    restrictTime
  } = props;

  console.log("more menu props are : ", props)
  const user = JSON.parse(localStorage.getItem('Rel8User')).role;

  const reInvite = async () => {
    console.log("reInvite sent")
    const user = JSON.parse(localStorage.getItem('Rel8User'))
    const residentsName = residentIds.map((item) => item.title);
    const data = {
      email,
      residentIds,
      firstname,
      lastname,
      adminName: `${user?.firstname} ${user?.lastname}`,
      residentsName,
      status: 'pending',
      subRole,
      facilityIds: facilityId
    };
    console.log(data)
    try {
      const resp = await axios.post(`${cloudFuncBaseUrl}.cloudfunctions.net/addFamilyMember`, data);
      // console.log(resp?.data.data.id);
      console.log(resp);
      if (resp?.status == 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Re-invited Successfully',
          showConfirmButton: false,
          timer: 2000
        });

      }

    } catch (error) {
      console.log(error)
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error ?? 'Failed to re-invite',
        showConfirmButton: false,
        timer: 2000
      });
    } finally {
      setIsOpen(false)
    }
  }

  // console.log(id);

  const userDelete = () => {
    try {
      // ----------------------------------confirm box----------------------
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to restrict this family member!',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, restrict it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          // //  --------------loading---------------------
          let timerInterval;
          Swal.fire({
            title: 'Processing...',
            html: 'I will close in few <b></b> Moments.',
            // timer: 2000,
            allowOutsideClick: false,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector('b');
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer');
            }
          });

          // // -------------------------- end loading-------------------------
          try {
            if (restrictTime === 3) {
              await axios({
                method: 'post',
                url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteUserAfter3Restriction`,
                data: {
                  id,
                  action: 'delete'
                }
              });
            } else {
              await axios({
                method: 'post',
                url: `${cloudFuncBaseUrl}.cloudfunctions.net/deleteAllWithOutUser`,
                data: {
                  id,
                  collectionName: 'users',
                  role: user
                }
              });
            }

            // delete locally
            // const ArrayM = await GetMeditation.filter((i) => i.id !== id);
            // setGetMeditation(ArrayM);
            // -------------------
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Family member has been restricted.',
              showConfirmButton: false,
              timer: 1500
            });
            window.location.reload(false);
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.message
            });
          }
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message
      });
    }
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ zIndex: '1' }}
      >
        {/* http://rel8app-reset-password-test.web.app/?email=https://rel8app-services-test.firebaseapp.com/__/auth/action?mode=resetPassword&oobCode=OMHcmzof8kTePc4ERSpx0EqlNX4tAwZSNpm77HRCAsEAAAGUCBWhBw&apiKey=AIzaSyATKjX56VFlR8W_tQcTizBvGh7PbpvVS4Y&lang=en */}
        {/* http://rel8app-reset-password-test.web.app/?email=https://rel8app-services-test.firebaseapp.com/__/auth/action?mode=resetPassword&oobCode=mneuVaODRsJTnrLlGXLpDVG9MrpKSXsPtJ2vrtdoZp0AAAGUCBECcg&apiKey=AIzaSyATKjX56VFlR8W_tQcTizBvGh7PbpvVS4Y&lang=en */}
        {/* https://rel8app-services-test.firebaseapp.com/__/auth/action?mode=resetPassword&oobCode=vEyQsqt2oIIiEJaYa091XFXLQr3_9p5yvhtALul5_cAAAAGUCBGlMw&apiKey=AIzaSyATKjX56VFlR8W_tQcTizBvGh7PbpvVS4Y&lang=en */}
        {/* {status} */}
        {/* <MenuItem sx={{ color: 'text.secondary' }} onClick={userDelete}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
        {status === 'pending' && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => { reInvite() }}
          // onClick={() => deleteUserPermanently(id, 'family member')}
          >
            <ListItemIcon>
              <Icon icon={send} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Resend Invite" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        <MenuItem
          sx={{ color: 'text.secondary' }}
          // onClick={userDelete}
          onClick={() => (!deletedAt ? userDelete() : restoreEvent(props))}
        // disabled={deletedAt && true}
        >
          <ListItemIcon>
            {/* <Icon icon={trash2Outline} width={24} height={24} /> */}
            <Icon icon={!deletedAt ? archiveOutline : flipFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={!deletedAt ? 'Restrict' : 'Restore'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        {/* -------------------------------- for Delete -------------------------------- */}
        {localUser.role === 'admin' && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            onClick={() => deleteUserPermanently(id, 'family member')}
          >
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {/* -------------------------------- for Delete -------------------------------- */}

        <MenuItem
          sx={{ color: 'text.secondary' }}
          component={RouterLink}
          to="/administration/family-members/update-family-member"
          state={{
            id,
            firstname,
            lastname,
            email,
            residentIds,
            facilityId,
            createdAt,
            avatarUrl,
            facilityName,
            role,
            status,
            restrictAt,
            deletedAt,
            subRole
          }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* ********************Update password */}
        {localUser.role === 'admin' && (
          <MenuItem
            sx={{ color: 'text.secondary' }}
            component={RouterLink}
            to="/home/change-password"
            state={{ id, email, type: 'family' }}
          >
            <ListItemIcon>
              <Icon icon={refreshOutline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Update password" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
