import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '../../assest/addIcon.svg';
import StoryIcon from '../../assest/galleryIcon.svg';
import NoticeIcon from '../../assest/noticeIcon.svg';
import WellnessIcon from '../../assest/wallnessIcon.svg';
import EventIcon from '../../assest/calenderIcon.svg';
import MessageIcon from '../../assest/messageIcon.svg';
import { DescriptionOutlined } from '@mui/icons-material';
import { MenuItem, Box } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MHidden } from 'src/components/@material-extend';
import { localUser } from '../../App';
import { db } from '../../App';
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
const itemList = [
  {
    title: 'Story',
    icon: StoryIcon,
    link: '/content/stories/add-story'
  },
  {
    title: 'Notice',
    icon: NoticeIcon,
    link: '/content/noticeboard/add-notice-board'
  },
  {
    title: 'Event',
    icon: EventIcon,
    link: '/content/events/add-event'
  },
  {
    title: 'Wellness',
    icon: WellnessIcon,
    link: '/content/wellness/add-wellness'
  },
  {
    title: 'Message',
    icon: MessageIcon,
    link: '/home/messages'
  },
];
export default function CreatePopover({ setPopOpen }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chatDisable, setChatDisable] = React.useState(true)





  async function handleClick(event) {
    setPopOpen(true)
    setAnchorEl(event.currentTarget);
    if (localUser?.role != 'admin') {
      let userCollection;
      console.log('res.data() ', localUser?.facilityIds);
      userCollection = query(
        collection(db, 'facilites'),
        where('id', 'in', localUser?.facilityIds)
      );

      let users = [];
      const getUsers = async () => {
        onSnapshot(userCollection, (snapshot) => {
          users = [];
          snapshot.docs.forEach((result) => {
            users.push({
              name: result.data().name,
              chatDisable: result.data()?.chatDisable ?? false
            });
          });
          const a = [];
          console.log("uersss : ", users)
          users.map((item) => {
            if (item.chatDisable) {
              a.push(item);
            }
          });
          if (a.length > 0) {
            setChatDisable(true)
          } else {
            setChatDisable(false)
          }
        });
      };
      await getUsers();
    } else {
      setChatDisable(false)
    }

  };

  const handleClose = () => {
    setPopOpen(false)
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <MHidden width="lgDown">  <Button
        style={{ backgroundColor: '#9A76F5', color: 'white', paddingTop: '5px',textTransform:"initial" }}
        onClick={handleClick}
      >
        <img src={AddIcon} style={{ width: '25px', marginRight: '5px' }} />  <div style={{ fontSize: "16px" }}>Create</div>
      </Button></MHidden>
      <MHidden width="lgUp">  <Button
        style={{ backgroundColor: '#9A76F5', color: 'white', paddingTop: '5px',textTransform:"initial" }}
        onClick={handleClick}
      >
        <img src={AddIcon} style={{ width: '15px', marginRight: '3px' }} />  <div style={{ fontSize: "12px" }}>Create</div>
      </Button></MHidden>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ marginTop: '30px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            typography: 'body2',
            paddingX: '12px',
            minWidth: '160px',
            backgroundColor: '#9A76F5',
            paddingY: '10px',
            display: "flex"
          }}
        >
          <img
            src={AddIcon}
            style={{ width: 30, height: 30, minWidth: 30, minHeight: 30, marginRight: '8px' }}
          />
          <div style={{ color: 'white', fontSize: '18px', fontWeight: 600 }}>Create a new</div>
        </Box>

        <Box sx={{ paddingY: '6px' }}>
          {itemList.filter((item) => {
            if (chatDisable) {
              if (item.title != 'Message') {
                return item;
              }
            } else {
              return item;
            }
          }).map((data) => {
            return (
              <MenuItem
                key={data.title}
                to={data.link}
                component={RouterLink}
                onClick={handleClose}
                sx={{
                  typography: 'body2',
                  py: 1,
                  paddingX: '12px',
                  fontWeight: 'medium',
                  minWidth: '180px'
                }}
              >
                <img
                  src={data.icon}
                  style={{
                    width: 24,
                    height: 24,
                    minWidth: 24,
                    minHeight: 24,
                    marginRight: '10px'
                  }}
                />
                {data.title}
              </MenuItem>
            );
          })}
          {(localUser.role == 'admin' || localUser.role == "Facility Member") && <MenuItem
            to={'/content/document/add-document'}
            component={RouterLink}
            onClick={handleClose}
            sx={{
              typography: 'body2',
              py: 1,
              paddingX: '12px',
              fontWeight: 'medium',
              minWidth: '180px'
            }}
          >
            <DescriptionOutlined fontSize='26px' style={{
              fill: '#9a76f5',
              width: "24px",
              height: "24px",
              minWidth: 24,
              minHeight: 24,
              marginRight: '5px'
            }} />

            {"Document"}
          </MenuItem>}
        </Box>
      </Popover>
    </div>
  );
}
