import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  Button,
  Grid,
  Card,
  Box,
  styled,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moduleName from 'module';
import InputMask from 'react-input-mask';
import { updateUser } from '../../../pages/facilityMember/User';
import GooglePlacesAutocomplete, { usePlacesWidget } from 'react-google-autocomplete';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from 'src/App';
import { GOOGLE_PLACES_API_KEY } from 'src/utils/config';

// ----------------------------------------------------------------------

const ProductImageStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');
export default function UpdateForm({
  id,
  name,
  contact,
  location,
  capacity,
  avatarUrl,
  jobTitle,
  organization,
  type,
  organizationId,
  chatDisableUpdate,
  videoCallDisableUpdate
}) {
  // console.log("videoCallDisable : ",videoCallDisable);
  const navigate = useNavigate();
  const [image, setImage] = useState(avatarUrl);
  const [imageState, setImageState] = useState('');
  const [openOrg, setOpenOrg] = useState(false);
  const [optionOrg, setOptionOrg] = useState([]);
  const [isLoadingOrg, setisLoadingOrg] = useState(false);

  const [loading, setLoading] = useState();

  async function getOrganisation() {
    setisLoadingOrg(true);
    const orgRef = collection(db, 'organizations');
    onSnapshot(orgRef, (snapshot) => {
      const users = [];
      snapshot.docs.forEach((result) =>
        users.push({ title: result.data().organizationName, value: result.data().id })
      );
      setOptionOrg(users);
      setisLoadingOrg(false);
    });
  }

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name is required'),
    contact: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Contact is required'),
    // location: Yup.string().required('location is required'),
    capacity: Yup.number().required('Number of clients is required'),
    // organisation: Yup.string().required('Organisation is required'),
    Location: Yup.string().required('Address is required'),
    organisation: Yup.object()
      .nullable()
      .test('title', 'Organisation is required', function (value) {
        return value !== null;
      }),
    type: Yup.string().required('Type is required'),
    chatDisable: Yup.bool(),
    videoCallDisable: Yup.bool()
  });

  // const updateUser = (id, email, name, contact) => {

  // };
  const formik = useFormik({
    initialValues: {
      id,
      name,
      contact,
      Location: location,
      capacity,
      organisation: { title: organization, value: organizationId ?? '' },
      type: type ?? '',
      chatDisable: chatDisableUpdate ?? false,
      videoCallDisable: videoCallDisableUpdate ?? false
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      // console.log("runninggg ")
      const name = getFieldProps('name').value;
      const contact = getFieldProps('contact').value;
      const organization = getFieldProps('organisation').value?.title;
      const organizationId = getFieldProps('organisation').value?.value;
      const capacity = getFieldProps('capacity').value;
      const location = getFieldProps('Location').value;
      const type = getFieldProps('type').value;
      const chatDisable = getFieldProps('chatDisable').value;
      const videoCallDisable = getFieldProps('videoCallDisable').value

      // if (RgExp.test(image)) {
      // }
      setLoading(true);
      // const location = locations;
      // console.log(id);
      // console.log(name);
      // console.log(contact);
      // console.log(location);
      // console.log(capacity);
      // console.log(image);
      await updateUser(
        id,
        name,
        contact,
        location,
        capacity,
        imageState ?? image,
        organization,
        type,
        organizationId,
        chatDisable,
        videoCallDisable
      );
      setLoading(false);
      navigate('/home/groups', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  //------------------------------Google places---
  const { ref: materialRef } = usePlacesWidget({
    // apiKey: 'AIzaSyDWeMrcmMOtW-QlR2xJjwpdbWxyXkQamQY',
    apiKey: GOOGLE_PLACES_API_KEY,
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: 'Aus' }
    },
    onPlaceSelected: (place) => {
      setFieldValue('Location', place.formatted_address);
    }
  });
  //------------------------------Google places---


  // console.log("chatDisableUpdate : ", chatDisableUpdate)

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Full Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <InputMask
              mask="(+61) 999 999 999"
              {...getFieldProps('contact')}
              disabled={false}
              maskChar=" "
            >
              {() => (
                <TextField
                  fullWidth
                  label="Contact"
                  {...getFieldProps('contact')}
                  error={Boolean(touched.contact && errors.contact)}
                  helperText={touched.contact && errors.contact}
                />
              )}
            </InputMask>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Address"
              placeholder=" "
              {...getFieldProps('Location')}
              error={Boolean(touched.Location && errors.Location)}
              helperText={touched.Location && errors.Location}
              inputRef={materialRef}
            />
            {/* <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                // marginTop: '-10px',
                fontSize: '16px',
                color: '#212F3D'
              }}
            >
              {locations && <p style={{ marginLeft: '10px', fontSize: '12px' }}>Location</p>}
              <Autocomplete
                apiKey="AIzaSyDWeMrcmMOtW-QlR2xJjwpdbWxyXkQamQY"
                style={{
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '16px',
                  height: '55px',
                  color: '#212F3D',
                  border: '1px solid #BDC3C7',
                  paddingLeft: '10px'
                }}
                onPlaceSelected={(place) => {
                  console.log(place);
                  setLocation(place.formatted_address);
                  setLocationError(false);
                }}
                options={{
                  // types: ['(regions)'],
                  types: ['geocode', 'establishment'],
                  componentRestrictions: { country: 'Aus' }
                }}
                defaultValue={locations}
              />
              {locationError && (
                <p style={{ color: 'red', fontSize: '12px', marginLeft: '17px' }}>
                  Location is required
                </p>
              )}
            </span> */}

            {/* {locations && (
              <TextField
                type="number"
                style={{ marginTop: '17px' }}
                fullWidth
                label="Resident Capacity"
                {...getFieldProps('capacity')}
                error={Boolean(touched.capacity && errors.capacity)}
                helperText={touched.capacity && errors.capacity}
              />
            )}
            {!locations && ( */}
            <TextField
              type="number"
              fullWidth
              label="Number of Clients"
              {...getFieldProps('capacity')}
              error={Boolean(touched.capacity && errors.capacity)}
              helperText={touched.capacity && errors.capacity}
            />
            {/* )} */}
          </Stack>
          {/* -------------------------------------------------------------------------------------------- */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* {console.log(
              "getFieldProps('organisation').value?.value ::: ",
              getFieldProps('organisation').value
            )} */}
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              open={openOrg}
              onOpen={() => {
                getOrganisation();
                setOpenOrg(true);
              }}
              onClose={() => {
                setOpenOrg(false);
              }}
              onChange={(e, value) => {
                setFieldValue('organisation', value);
              }}
              defaultValue={{
                title: getFieldProps('organisation').value?.title ?? '',
                value: getFieldProps('organisation').value?.value ?? ''
              }}
              isOptionEqualToValue={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option?.title}
              options={optionOrg}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Organisation"
                  {...getFieldProps('organisation')}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingOrg ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  error={Boolean(touched.organisation && errors.organisation)}
                  helperText={touched.organisation && errors.organisation}
                />
              )}
            />
            {/* <TextField
              fullWidth
              label="Organisation"
              {...getFieldProps('organisation')}
              error={Boolean(touched.organisation && errors.organisation)}
              helperText={touched.organisation && errors.organisation}
            /> */}
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              onChange={(e, value) => {
                setFieldValue('type', value ?? '');
              }}
              defaultValue={getFieldProps('type').value ?? ''}
              getOptionLabel={(option) => option}
              options={[
                'Residential Aged Care',
                'Retirement Communities',
                'Home Care',
                'Disability Care'
              ]}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Type"
                  {...getFieldProps('type').value}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                />
              )}
            />
          </Stack>
          <FormControlLabel
            control={<Checkbox defaultChecked={chatDisableUpdate ?? false} />}
            label="Disable messaging"
            {...getFieldProps('chatDisable')}
            onChange={(e, value) => setFieldValue('chatDisable', value)}
          />
          <FormControlLabel
            style={{ marginTop: "2px" }}
            control={<Checkbox defaultChecked={videoCallDisableUpdate ?? false} />}
            label="Disable video calls"
            {...getFieldProps('videoCallDisable')}
            onChange={(e, value) => setFieldValue('videoCallDisable', value)}
          />
          {/* -------------------------------------------------------------------------------------------- */}
          <Grid container spacing={2}>
            {image && (
              <Grid item xs={4} sm={4} md={2}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImageStyle
                      alt="Image"
                      // src={image && RgExp.test(image) ? image : URL.createObjectURL(image)}
                      src={image && RgExp.test(image) ? image : URL.createObjectURL(imageState)}
                    />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button variant="contained" component="label">
              Upload Image
              <input
                fullWidth
                type="file"
                hidden
                onChange={(e) => {

                  // console.log(e.target.files);
                  setImage(e.target.files[0]);
                  setImageState(e.target.files[0]);
                }}
                accept="image/*"
                error={Boolean(touched.photo && errors.photo)}
                helperText={touched.photo && errors.photo}
              />
            </Button>
          </Stack>

          {/* <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          /> */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Update Group
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
