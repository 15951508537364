import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../App';
import gif from '../assest/dot.gif';
import bulbOutline from '@iconify/icons-eva/bulb-outline';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const [unReadContacts, setUnReadContacts] = useState(0);

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  // -------------------------------------get chat unRead---------------
  useEffect(async () => {
    const chatRef = query(collection(db, 'chat'), where('userIds', 'array-contains', user?.id));

    const unsubscribe = onSnapshot(chatRef, (querySnapshot) => {
      const messagess = [];
      querySnapshot.forEach((result) => {
        if (result.data().toReadId === user?.id) {
          messagess.push(result.data());
        }
      });
      setUnReadContacts(messagess.length);
    });
  }, []);
  // ---------------------------------------------------
  // ---------------------------------------------------
  // ---------------------------------------------------
  // ----------------------------------------------------------------------

  NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func
    // unReadContacts: PropTypes.number
  };

  function NavItem({ item, active }) {
    const theme = useTheme();
    const isActiveRoot = active(item.path);
    if (user.role == 'Family Member' || user.role == 'Family Admin') {
      if (item.title == 'Clients') {
        item.title = 'My Family';
      }
    }
    const { title, path, icon, info, children } = item;
    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = () => {
      setOpen((prev) => !prev);
    };

    const activeRootStyle = {
      color: 'primary.main',
      fontWeight: 'fontWeightMedium',
      bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      '&:before': { display: 'block' }
    };

    const activeSubStyle = {
      color: 'text.primary',
      fontWeight: 'fontWeightMedium'
    };

    if (children) {
      return (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ height: '1px', backgroundColor: '#E4E6E7', width: '80%' }}></div>
          </div>
          <ListItemStyle
            onClick={handleOpen}
            sx={{
              ...(isActiveRoot && activeRootStyle)
            }}
          >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText disableTypography primary={title} />
            {/* {info && info} */}
            <Box
              component={Icon}
              icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
              sx={{ width: 24, height: 24, ml: 1 }}
            />
          </ListItemStyle>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = active(path);

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main'
                          })
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        </>
      );
    }
    return (
      <ListItemStyle
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot && activeRootStyle)
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText
          disableTypography
          primary={
            title === 'Messages' ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {/* {unReadContacts > 0 ? (
                  <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <sup
                      style={{
                        color: 'red',
                        paddingBottom: '10px',
                        fontSize: '10px',
                        marginLeft: '-15px'
                      }}
                    >
                      {unReadContacts}
                    </sup> */}
                <>Messages</>
                {unReadContacts > 0 && (
                  <div
                    style={{
                      color: '#fff',
                      backgroundColor: '#9A76F5',
                      width: 20,
                      height: 20,
                      textAlign: 'center',
                      borderRadius: '50%',
                      marginLeft: 5,
                      fontFamily: 'arial',
                      fontSize: '13px',
                      paddingTop: '1px'
                    }}
                  >
                    {unReadContacts}
                  </div>
                )}
                {/* </span>
                ) : (
                  'Messages'
                )} */}
              </div>
            ) : (
              title
            )
          }
        />
        {info && info}
      </ListItemStyle>
    );
  }
  // ---------------------------------------------------
  // ---------------------------------------------------
  // ---------------------------------------------------

  function HelpSupport() {
    const [openHelp, setOpenHelp] = useState(false);
    const helpChildren = [
      { title: "Knowledge base", path: "https://rel8app.freshdesk.com/support/home" },
      { title: "Submit a ticket", path: "https://rel8app.freshdesk.com/support/tickets/new" },
    ]

    return <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ height: '1px', backgroundColor: '#E4E6E7', width: '80%' }}></div>
      </div>
      <ListItemStyle
        onClick={() => {
          setOpenHelp(!openHelp);
        }}
      >
        <ListItemIconStyle>{getIcon(bulbOutline) && getIcon(bulbOutline)}</ListItemIconStyle>
        <ListItemText disableTypography primary={'Help & support'} />
        <Box
          component={Icon}
          icon={openHelp ? arrowIosDownwardFill : arrowIosForwardFill}
          sx={{ width: 24, height: 24, ml: 1 }}
        />
      </ListItemStyle>
      <Collapse in={openHelp} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {helpChildren.map((item) => {
            const { title, path } = item;
            const isActiveSub = false;

            return (
              <ListItemStyle
                key={title}
                component={RouterLink}
                to={path}
                sx={{

                }}
              >
                <ListItemIconStyle>
                  <Box
                    component="span"
                    sx={{
                      width: 4,
                      height: 4,
                      display: 'flex',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'text.disabled',
                      transition: (theme) => theme.transitions.create('transform'),
                    }}
                  />
                </ListItemIconStyle>
                <ListItemText disableTypography primary={title} />
              </ListItemStyle>
            );
          })}
        </List>
      </Collapse>
    </>
  }



  return (
    <Box {...other} sx={{ marginTop: "20px" }}>
      <List disablePadding>
        {navConfig
          .filter((item) => {
            if (user.role != 'Family Member' && user.role != 'Family Admin') {
              return item;
            }
            if (
              item.title != 'Drafts' &&
              item.title != 'Organisations' &&
              item.title != 'Groups' &&
              item.title != 'Staff Members' &&
              item.title != 'Locations'
            ) {
              return item;
            }
          })
          .map((item) =>
          (
            <NavItem
              key={item.title}
              item={item}
              active={match}
              sx={{ backgroundColor: 'red' }}
            // unReadContacts={unReadContacts}
            />
          ))}
        <HelpSupport />

      </List>
    </Box>
  );
}
