import * as Yup from 'yup';
import { useRef, useState, useEffect } from 'react';
import { TextField, Avatar, Stack, Button, Card, Box, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useFormik, Form, FormikProvider } from 'formik';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import { setDoc, doc, query, collection, where, getDocs, updateDoc, onSnapshot } from 'firebase/firestore';
// import loadingImage from '../../../assest/loading.gif';
import { db, localUser } from '../App';
import '../components/_dashboard/Profile/style.css';
import { Link as RouterLink } from 'react-router-dom';
// import getAndUpdateDoc from '../FaciltiyMembers/getAndUpdateDoc';
// import UpdateNameInEveryCollection from './UpdateNameInEveryCollection';
import { cloudFuncBaseUrl } from 'src/utils/config';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Page from '../components/Page';
import ProfileIcon from '../assest/profile.svg';
import crossIcon from 'src/components/crossIcon';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Upload } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom/dist';
import Swal from 'sweetalert2';
import { subRoleFacility, subRoleFamily } from 'src/components/subRole/SubRole';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px',
  padding: theme.spacing(0, 0)
}));

function UpdateProfile() {
  const inputFile = useRef(null);
  const [refs, loaded, onLoad] = useImageLoaded();
  const user = JSON.parse(localStorage.getItem('Rel8User'));
  const profileAvatar = user.avatarUrl
  const [image, setImage] = useState();
  const [imageDel, setImageDel] = useState(false);
  const [facilityNames, setFacilityName] = useState([])
  const [previewImage, setPreviewImage] = useState(
    user.avatarUrl || '/static/mock-images/avatars/avatar_default.jpg'
  );
  // const [email, setEmail] = useState(user.email);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  const RegisterSchema = Yup.object().shape({
    firstname: Yup.string().trim().required('First name is required')
    // lastname: Yup.string().trim().required('Last name is required')
  });

  // console.log('image ::: ', image);
  const formik = useFormik({
    initialValues: {
      firstname: user?.firstname,
      lastname: user?.lastname,
      email: user?.email,
      organisation: user?.organization ?? '',
      jobTitle: user?.jobTitle ?? '',
      facilityIds: facilityNames
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      const { email, firstname, lastname, organisation, jobTitle } = values;
      if (!previewImage && !image) {
        return NotificationManager.error('Image is required');
      }

      try {
        setIsLoading(true);

        let data = {
          id: localUser?.id,
          email,
          firstname: values.firstname.trim(),
          lastname: values.lastname.trim(),
          role: localUser?.role
        };

        if (image) {
          // console.log('image !!!!!!!');
          const storage = getStorage();
          const storageRef = ref(storage, `users/${localUser.id}/profile`);
          const metadata = {
            contentType: 'image/jpeg'
          };
          const uploadTask = await uploadBytesResumable(storageRef, image, metadata);

          const avatarUrl = await getDownloadURL(uploadTask.ref);
          console.log('avatarUrl ', avatarUrl);
          if (localUser.role === 'Facility Member') {
            data.organisation = organisation.trim();
            data.jobTitle = jobTitle.trim();
            data.avatarUrl = avatarUrl;
            data.subRole = localUser?.subRole;
            data.residentIds = localUser?.residentIds;
            data.facilityIds = localUser?.facilityIds;
            //
            user.organisation = values.organisation.trim();
            user.jobTitle = values.jobTitle.trim();
            //
          } else if (localUser.role === 'Family Member') {
            data.jobTitle = '';
            data.avatarUrl = avatarUrl;
            data.subRole = localUser?.subRole;
            data.residentIds = localUser?.residentIds;
            data.facilityIds = localUser?.facilityIds;
          } else if (localUser.role === 'admin') {
            data.avatarUrl = avatarUrl;
            data.subRole = null;
          }
          const updatedUser = await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUserEveryWhere`,
            data
          });

          await setDoc(doc(db, 'users', user.id), data, { merge: true }).then(() => {
            user.email = email;
            user.firstname = values.firstname.trim();
            user.lastname = values.lastname.trim();
            user.avatarUrl = avatarUrl;
            // console.log('updated user is : ',user)
            localStorage.setItem('Rel8User', JSON.stringify(user));
          }).then(() => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Profile updated successfully',
              showConfirmButton: false,
              timer: 2000
            });
            setIsLoading(false);
            window.location.reload()
            return window.location.replace('/home/profile')
          })

          // console.log(updatedUser?.data);
          // window.location.href = '/';
        }
        console.log('no image');

        if (user.role === 'Facility Member') {
          data.organisation = organisation.trim();
          data.jobTitle = jobTitle.trim();
          data.avatarUrl = imageDel ? '' : localUser.avatarUrl ?? '';
          data.subRole = localUser?.subRole;
          data.residentIds = localUser?.residentIds;
          data.facilityIds = localUser?.facilityIds;
        } else if (user.role === 'Family Member') {
          data.jobTitle = '';
          data.avatarUrl = imageDel ? '' : localUser.avatarUrl ?? '';
          data.subRole = localUser?.subRole;
          data.residentIds = localUser?.residentIds;
          data.facilityIds = localUser?.facilityIds;
        } else if (user.role === 'admin') {
          data.avatarUrl = imageDel ? '' : profileAvatar ?? '';
          console.log(data.avatarUrl)
          data.subRole = null;
        }

        const updatedUser = await axios({
          method: 'post',
          url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUserEveryWhere`,
          data
        });

        console.log('response !! update user', updatedUser);
        await setDoc(doc(db, 'users', user.id), data, { merge: true }).then(() => {
          user.email = email;
          // user.avatarUrl =
          user.firstname = firstname;
          user.lastname = lastname;
          // user.avatarUrl = null;
          console.log("user object is : ", user)
          localStorage.setItem('Rel8User', JSON.stringify(user));
          setIsLoading(false);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Profile updated successfully',
            showConfirmButton: false,
            timer: 2000
          });
          window.location.reload()
          window.location.replace('/home/profile')

          // navigate('/home/profile')
        })
        // console.log("before console")
        // await UpdateNameInEveryCollection(`${firstname} ${lastname}`);
        // console.log("after console")
        // window.location.href = '/';
      } catch (error) {
        setIsLoading(false);
        return NotificationManager.error(`Error ! ${error.message}`);
      }
    }
  });

  const getFacilityName = () => {
    try {
      // Reference to the facilities collection with the query for facility IDs
      const docRef = query(
        collection(db, 'facilites'), // Corrected collection name
        where('id', 'in', user.facilityIds) // Ensure 'id' matches your Firestore schema
      );

      // Listen for real-time updates
      onSnapshot(docRef, (snapshot) => {
        if (snapshot.docs.length > 0) {
          // Map through the documents and extract facility names
          const facilities = snapshot.docs.map((doc) => doc.data().name);

          // Update the state with facility names
          setFacilityName(facilities);
          setFieldValue('facilityIds', [...facilities])
        } else {
          console.log('No matching facilities found.');
        }
      });
    } catch (error) {
      console.error('Error fetching facility names:', error.message);
    }
  };

  useEffect(() => {
    if (user?.facilityIds.length) {
      getFacilityName()
    }
  }, [])

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched
  } = formik;
  return (
    <RootStyle title="Profile | Rel8">
      <ContentStyle>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              color: '#9A76F5'
            }}
          >
            <img
              src={ProfileIcon}
              style={{ width: 35, height: 35, minWidth: 30, minHeight: 30, marginRight: '5px' }}
            />
            <p style={{ color: '#9A76F5', textAlign: 'start', fontSize: '24px', fontWeight: 500 }}>
              Edit profile
            </p>
          </Box>
        </Stack>

        <Card
          sx={{
            marginTop: '',
            width: '100%',
            minWidth: '100%',
            paddingX: '20px',
            paddingY: '30px'
          }}
        >
          <FormikProvider value={formik} s>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              {/* <NotificationContainer /> */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
                <Box sx={{ display: 'flex', gap: '15px', width: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      First Name
                    </div>
                    <TextField
                      fullWidth
                      //   label="First Name"
                      {...getFieldProps('firstname')}
                      error={Boolean(touched.firstname && errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Last Name
                    </div>
                    <TextField
                      sx={{ width: '100%' }}
                      //   label="Last Name"
                      {...getFieldProps('lastname')}
                      error={Boolean(touched.lastname && errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                    />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>Email</div>
                    <TextField
                      sx={{ width: '100%' }}
                      disabled
                      //   label="Email"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', gap: '15px', width: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Organisation
                    </div>
                    <TextField
                      disabled
                      fullWidth
                      //   label="Organisation"
                      {...getFieldProps('organisation')}
                      error={Boolean(touched.organisation && errors.organisation)}
                      helperText={touched.organisation && errors.organisation}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Group Names
                    </div>

                    <Select
                      disabled
                      multiple
                      renderValue={(selected) => selected.join(',  ')}
                      // limitTags={3} 
                      {...getFieldProps('facilityIds')}>
                      {facilityNames.map((name, index) => (
                        <option key={index} value={name} disabled>
                          {name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '15px', width: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Job Title
                    </div>
                    <TextField
                      disabled
                      fullWidth
                      //   label="Job Title"
                      {...getFieldProps('jobTitle')}
                      error={Boolean(touched.jobTitle && errors.jobTitle)}
                      helperText={touched.jobTitle && errors.jobTitle}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                    <div style={{ fontSize: '14px', fontWeight: 400, color: '#9A76F5' }}>
                      Role
                    </div>
                    <TextField
                      disabled
                      fullWidth
                      value={localUser.role == 'admin' ? localUser?.role : localUser?.role == 'Facility Member' ? subRoleFacility?.[localUser?.subRole]?.title ?? "Facility Member" : subRoleFamily?.[localUser?.subRole]?.title ?? 'Family Member'}
                      //   label="Organisation"
                      //   {...getFieldProps('organisation')}
                      error={Boolean(touched.organisation && errors.organisation)}
                      helperText={touched.organisation && errors.organisation}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', width: '100%', justifyContent: 'start', marginY: '10px' }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <Avatar
                      className=""
                      sx={{ height: '150px', width: '150px', cursor: 'pointer' }}
                      src={previewImage}
                      // ref={refs}
                      // onLoad={onLoad}
                      onClick={() => inputFile.current.click()}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: '20px',
                        cursor: 'pointer'
                      }}
                      onClick={() => { setImage(null); setPreviewImage('/static/mock-images/avatars/avatar_default.jpg'); user.avatarUrl = ''; setImageDel(true) }}
                    >
                      {previewImage !== '/static/mock-images/avatars/avatar_default.jpg' &&
                        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
                          <div
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              borderRadius: "100%",


                            }}
                          >
                            <CloseOutlinedIcon />
                          </div>
                        </Box>
                      }
                    </Box>
                    {previewImage == '/static/mock-images/avatars/avatar_default.jpg' &&
                      <Button
                        variant="contained" component="label"
                        type='button'

                        style={{ paddingX: "10px", display: "flex", flexDirection: 'row', gap: '5px', alignItems: 'center', marginTop: "5px" }}
                        onClick={() => inputFile.current.click()}
                      >
                        Upload Image <Upload />
                      </Button>
                    }
                  </Box>

                  <input
                    ref={inputFile}
                    accept="image/*"
                    type="file"
                    id="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setPreviewImage(URL.createObjectURL(e.target.files[0]));
                    }}
                    style={{ display: 'none' }}
                  />
                </Box>
                {/* <LoadingButton
                      // fullWidth
                      component={RouterLink}
                      to="/home/change-password"
                      state={{ id: user?.id, email: user?.email, type: 'admin' }}
                      sx={{ width: '90%' }}
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={loading}
                    >
                      Update Password
                    </LoadingButton> */}
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Update Profile
                </LoadingButton>
              </Box>
            </Form>
          </FormikProvider>
        </Card>
      </ContentStyle>
    </RootStyle>
  );
}

export default UpdateProfile;

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  const refs = useRef();

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  });

  return [refs, loaded, onLoad];
};
