import { filter, forEach, result } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useReducer } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip
} from '@mui/material';
// components

import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  where,
  query,
  deleteField,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import Spinner from 'react-spinner-material';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  getStorage,
  ref,
  deleteObject,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import moment from 'moment';
import DeletedTimeFamily from '../../components/_dashboard/FamilyMember/DeletedTimeFamily';
import MemberMoreMenu from '../../components/_dashboard/user/MemberMoreMenu';
import UserNotFound from '../../components/UserNotFound';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/_dashboard/user';
//
import { db } from '../../App';
// import { LightBox } from '../../components/_dashboard/user/LightBox';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'firstname', label: 'First Name', alignRight: false },
  { id: 'lastname', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'jobTitle', label: 'Job Title', alignRight: false },
  { id: 'facilityId', label: 'Group', alignRight: false },
  { id: 'organisation', label: 'Organisation', alignRight: false },
  { id: 'date', label: 'Date Added', alignRight: false },
  { id: 'restrictAt', label: 'Account Status', alignRight: false }
];

const localUser = JSON.parse(localStorage.getItem('Rel8User'));
// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//* **************Add Users From Firebase**********************
export const addUser = (name, email, contact, location, facilityName) => {
  const data = {
    name,
    email,
    contact,
    location,
    facilityName
  };
  return axios.post('https://rel8-cloud-functions.herokuapp.com/addFacility', data);
};

//* **************Delete Users From Firebase**********************
export const deleteUser = (id, avatarUrl) =>
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDoc(doc(db, 'facilites', id))
        .then((res) => window.location.reload(false))
        .catch((err) => console.log(err.message));

      const membersRef = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
      const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
      getDocs(membersRef).then((res) => {
        res.forEach((result) => {
          const facilityMemberRef = doc(db, 'facilityMembers', result.data().id);
          updateDoc(facilityMemberRef, {
            facilityId: deleteField(),
            facilityName: deleteField()
          });
        });
      });
      getDocs(residentsRef).then((res) => {
        res.forEach((result) => {
          const residentRef = doc(db, 'residents', result.data().id);
          updateDoc(residentRef, {
            facilityId: deleteField(),
            facilityName: deleteField()
          });
        });
      });
      const storage = getStorage();

      // Create a reference to the file to delete
      const desertRef = ref(storage, avatarUrl);

      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          Swal.fire('Deleted!', 'Facility has been deleted.', 'success');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

//* **************Delete Users From Firebase**********************
export const listUserDelete = (ids) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      for (let i = 0; i < ids.length; i += 1) {
        deleteDoc(doc(db, 'facilityMembers', ids[i]))
          .then((res) => {
            if (i === ids.length - 1) {
              window.location.reload(false);
            }
          })
          .catch((err) => console.log(err.message));
      }
      Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
    }
  });
};

//* **************Update Users From Firebase**********************
export const updateUser = async (
  id,
  name,
  contact,
  location,
  capacity,
  image,
  organization,
  type,
  organizationId,
  chatDisable,
  videoCallDisable
) => {
  const storage = getStorage();
  const storageRef = ref(storage, `facilities/${id}/facilityImgUrl`);
  const metadata = {
    contentType: 'image/jpeg'
  };
  console.log("chatDisable : ", chatDisable)
  const RgExp = new RegExp('^(?:[a-z]+:)?//', 'i');
  if (RgExp.test(image) || !image) {
    return setDoc(
      doc(db, 'facilites', id),
      {
        id,
        name,
        contact,
        location,
        capacity: `${capacity}`,
        organization,
        type,
        organizationId,
        chatDisable: chatDisable,
        videoCallDisable: videoCallDisable
      },
      { merge: true }
    ).then(() => {
      const membersRef = query(collection(db, 'users'), where('facilityId', '==', id));
      const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
      const roomRef = query(collection(db, 'rooms'), where('facilityId', '==', id));
      const noticeRef = query(collection(db, 'noticeboards'), where('facilitiesId', '==', id));
      const storyRef = query(collection(db, 'stories'), where('facilitiesId', '==', id));
      const eventRef = query(collection(db, 'events'), where('facilitiesId', '==', id));

      // Facility Member
      getDocs(membersRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'users', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      // Resident Update
      getDocs(residentsRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'residents', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      // room update
      getDocs(roomRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'rooms', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      // Noticeboard
      getDocs(noticeRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'noticeboards', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      // Story
      getDocs(storyRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'stories', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      // Events
      getDocs(eventRef).then((res) => {
        res.forEach((result) => {
          setDoc(
            doc(db, 'events', result.data().id),
            {
              facilityName: name
            },
            { merge: true }
          );
        });
      });
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Facility has been updated.',
        showConfirmButton: false,
        timer: 1500
      });
    });
  }
  const uploadTask = await uploadBytesResumable(storageRef, image, metadata);

  // uploadTask.on(
  //   'state_changed',
  //   (snapshot) => {
  //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //     console.log(`Upload is ${progress}% done`);
  //   },
  //   (error) => NotificationManager.error(error.message),
  //   async () => {
  const avatarUrl = await getDownloadURL(uploadTask.ref);
  // const avatarUrl = await getDownloadURL(uploadTask.snapshot.ref);
  setDoc(
    doc(db, 'facilites', id),
    {
      id,
      name,
      contact,
      location,
      capacity,
      avatarUrl,
      type,
      organizationId,
      chatDisable: chatDisable,
      videoCallDisable: videoCallDisable
    },
    { merge: true }
  ).then(async () => {
    const membersRef = query(collection(db, 'facilityMembers'), where('facilityId', '==', id));
    const residentsRef = query(collection(db, 'residents'), where('facilityId', '==', id));
    const res = await getDocs(membersRef);
    res.forEach((result) => {
      setDoc(
        doc(db, 'facilityMembers', result.data().id),
        {
          facilityName: name
        },
        { merge: true }
      );
      // });
    });
    const resp = await getDocs(residentsRef);
    resp.forEach((result) => {
      setDoc(
        doc(db, 'residents', result.data().id),
        {
          facilityName: name
        },
        { merge: true }
      );
      // });
    });
    await Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Facility has been updated.',
      showConfirmButton: false,
      timer: 1500
    });
  });
  //   }
  // );
};

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}



export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('date');
  const [filterName, setFilterName] = useState('');
  const [filterByGrp, setFilterByGrp] = useState('');
  const [Search, setSearch] = useState(false);
  const [SearchSet, setSearchSet] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setisLoading] = useState(true);
  const [NotFound, setNotFound] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [USERLIST, setUSERLIST] = useState([]);

  const [getFacility, setGetFacility] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  // ------for lightbox
  const [image, setImage] = useState('');

  const user = JSON.parse(localStorage.getItem('Rel8User'));
  //* **************Get Users From Firebase**********************
  let userCollection;
  if (user?.role === 'Facility Member') {
    userCollection = query(
      collection(db, 'users'),
      where('restrictAt', '!=', 4),
      where('role', '==', 'Facility Member'),
      where('facilityIds', 'array-contains-any', user?.facilityIds)
    );
  } else if (user?.role === 'admin') {
    userCollection = query(
      collection(db, 'users'),
      where('restrictAt', '!=', 4),
      where('role', '==', 'Facility Member')
    );
  } else if (user?.role === 'Family Member') {
    userCollection = query(
      collection(db, 'users'),
      where('restrictAt', '!=', 4),
      where('facilityId', 'in', user?.facilityIds)
    );
  }

  // const userCollection = query(
  //   collection(db, 'users'),
  //   where('role', '==', 'Facility Member')
  //   // where('deletedAt', '==', null)
  // );
  let users = [];
  const getUsers = async () => {
    if (!location.state) {
      // getDocs(userCollection).then((res) => {
      onSnapshot(userCollection, (snapshot) => {
        users = [];
        snapshot.docs.forEach((result) => {
          if (result.data().email !== 'admin@admin.com') {
            users.push({
              id: result.data().id,
              avatarUrl: result.data().avatarUrl,
              email: result.data().email,
              facilityName: result.data().facilityName,
              facilityId: result.data().facilityIds,
              residentIds: result.data().residentIds,
              date: result.data().createdAt,
              firstname: result.data().firstname,
              lastname: result.data().lastname,
              role: result.data().role,
              status: result.data().status,
              restrictAt: result.data()?.restrictAt ?? 0,
              jobTitle: result.data()?.jobTitle ?? '',
              organisation: result.data()?.organization ?? '',
              organisationId: result.data()?.organizationId ?? '',
              deletedAt: result.data()?.deletedAt,
              subRole: result.data()?.subRole,
              restrictTime: result.data()?.restrictTime ?? 0
            });
          }
        });
        if (!users.length) {
          return setNotFound(true);
        }
        setUSERLIST(users);
        // console.log("users" + users);
      });
    } else {
      const userCollection = query(
        collection(db, 'users'),
        where('facilityId', '==', location.state.facilityId),
        where('role', '==', 'Facility Member')
        // where('deletedAt', '==', null)
      );
      let users = [];
      // getDocs(userCollection).then((res) => {
      onSnapshot(userCollection, (snapshot) => {
        users = [];
        snapshot.docs.forEach((result) => {
          if (result.data().email !== 'admin@admin.com') {
            users.push({
              id: result.data().id,
              avatarUrl: result.data().avatarUrl,
              email: result.data().email,
              facilityName: result.data().facilityName,
              facilityId: result.data().facilityIds,
              residentIds: result.data().residentIds,
              date: result.data().createdAt,
              firstname: result.data().firstname,
              lastname: result.data().lastname,
              role: result.data().role,
              status: result.data().status,
              restrictAt: result.data()?.restrictAt ?? 0,
              jobTitle: result.data()?.jobTitle ?? '',
              organisation: result.data()?.organization ?? '',
              organisationId: result.data()?.organizationId ?? '',
              deletedAt: result.data()?.deletedAt,
              subRole: result.data()?.subRole,
              restrictTime: result.data()?.restrictTime ?? 0
            });
          }
        });
        if (!users.length) {
          return setNotFound(true);
        }
        setUSERLIST(users);
        console.log("users ", users)
      });
    }
  };
  console.log("userslist ", USERLIST)
  // -----------------------------Get all facilities-------------------------
  function getFAcilityFunc() {
    const getFacilitesRef = collection(db, 'facilites');
    onSnapshot(getFacilitesRef, (snapshot) => {
      const facilityArr = [];
      snapshot.docs?.forEach((res) => {
        facilityArr.push({ name: res.data()?.name, id: res.data()?.id });
      });
      setGetFacility(facilityArr);
    });
  }
  // -----------------------------End Get all facilities-------------------------

  //* *****************UseEffect for users get********************
  useEffect(() => {
    getUsers();
    setisLoading(false);
    getFAcilityFunc();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    if (Search) {
      setSearch(false);
    } else {
      setSearch(true);
    }
    setSearchSet(true);
    setFilterName(event.target.value);
  };

  useEffect(() => {
    if (filteredUsers.length === 0 && SearchSet || filteredUsers.length === 0 && filterByGrp) {
      setNotFound(true);
    }
  }, [Search, filterByGrp]);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName, filterByGrp);
  console.log("filter : ", filteredUsers);
  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Spinner size={120} spinnerColor="#333" spinnerWidth={2} visible />
      </div>
    );


  console.log('filteredUsers ', filteredUsers)

  // -------------------filter facility name-----------
  function facilityNameFunc(ids) {
    console.log("id : ", ids);
    let facilityName = [];
    ids?.forEach((id) => {
      let name = getFacility?.filter((e) => e?.id === id);
      facilityName.push(name[0]?.name);
    });
    return facilityName[0];
  }


  function applySortFilter(array, comparator, query, query2) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    const Query2 = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) =>
          _user.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }


    const ReturnGroups = query2 ? stabilizedThis.filter((a) => {

      if (query2 == facilityNameFunc(a[0].facilityId)) {
        return a[0];
      }
    }) : stabilizedThis;


    return ReturnGroups.map((el) => { return el[0] });
  }

  // -------------------filter facility name-----------

  if (filteredUsers.firstname != undefined)
    console.log("staff members " + filteredUsers[0].firstname);


  return (
    <Page title="Facility Members | Rel8">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Staff Members
          </Typography>
          {JSON.parse(localStorage.getItem('Rel8User')).role !== 'Family Member' && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/administration/staff-members/add-facility-member"
              startIcon={<Icon icon={plusFill} />}
            >
              Add Staff
            </Button>
          )}
        </Stack>

        <Card>
          <UserListToolbar
            USERLIST={[]}
            localuser={{}}
            selected={selected}
            numSelected={selected.length}
            filterName={filterName}
            filterByGrp={filterByGrp}
            setFilterByGrp={setFilterByGrp}
            onFilterName={handleFilterByName}
            collect="groups"

          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  deletedTab={localUser.role === 'Family Member' && true}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const {
                        id,
                        firstname,
                        facilityId,
                        residentIds,
                        date,
                        avatarUrl,
                        facilityName,
                        email,
                        lastname,
                        role,
                        status,
                        restrictAt,
                        jobTitle,
                        organisation,
                        organisationId,
                        deletedAt,
                        subRole,
                        restrictTime,
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      // console.log(facilityNameFunc(facilityId))
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          location="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {localUser.role !== 'Family Member' && (
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="2px">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {avatarUrl ? (
                                // <LightBox src={avatarUrl} alt={firstname}>
                                <Avatar
                                  alt={firstname}
                                  src={avatarUrl}
                                  style={{ cursor: 'pointer', backgroundColor: '#9A76F5' }}
                                  onClick={() => {
                                    setImage(avatarUrl);
                                  }}
                                />
                              ) : (
                                // </LightBox>
                                <Avatar
                                  alt={firstname}
                                  src={avatarUrl}
                                  style={{ backgroundColor: '#9A76F5' }}
                                />
                              )}
                              <Typography
                                variant="subtitle2"
                                component={RouterLink}
                                style={{
                                  cursor: 'pointer',
                                  textDecoration: 'none',
                                  color: '#212B36',
                                  fontWeight: 'bold'
                                }}
                                to="/administration/staff-members/facility-member-more-info"
                                state={{ row, residents: row?.residentIds }}
                              >
                                {firstname?.length > 13 ? (
                                  <Tooltip title={firstname} arrow placement="top">
                                    <p>{firstname.slice(0, 10)}...</p>
                                  </Tooltip>
                                ) : (
                                  firstname
                                )}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {lastname?.length > 13 ? (
                              <Tooltip title={lastname} arrow placement="top">
                                <p>{lastname.slice(0, 10)}...</p>
                              </Tooltip>
                            ) : (
                              lastname
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {/* {email?.length > 24 ? email?.split('@')[0] : email} */}
                            {email?.length > 20 ? (
                              <Tooltip title={email} arrow placement="top">
                                <p>{email.slice(0, 17)}...</p>
                              </Tooltip>
                            ) : (
                              email
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {jobTitle?.length > 13 ? (
                              <Tooltip title={jobTitle} arrow placement="top">
                                <p>{jobTitle.slice(0, 10)}...</p>
                              </Tooltip>
                            ) : (
                              jobTitle
                            )}
                          </TableCell>
                          <TableCell>
                            {facilityId?.length >= 1 ? (
                              facilityNameFunc(facilityId)?.length > 15 ? (
                                <Tooltip title={facilityNameFunc(facilityId)} arrow placement="top">
                                  <p>{facilityNameFunc(facilityId).length > 18 ? facilityNameFunc(facilityId).slice(0, 15) + '...' : facilityNameFunc(facilityId)}</p>
                                </Tooltip>
                              ) : (
                                facilityNameFunc(facilityId)
                              )
                            ) : (
                              <Label variant="ghost" color="error">
                                Unassigned
                              </Label>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {organisation?.length > 13 ? (
                              <Tooltip title={organisation} arrow placement="top">
                                <p>{organisation.slice(0, 10)}...</p>
                              </Tooltip>
                            ) : (
                              organisation
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {moment(date?.toDate()).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell align="left">
                            {deletedAt != null ? (
                              <Label variant="ghost" color="error">
                                {DeletedTimeFamily(deletedAt, restrictAt)}-remaining
                              </Label>
                            ) : (
                              status == 'accepted' ?
                                <Label variant="ghost" color="success">
                                  Active
                                </Label> :
                                <Label variant="ghost" >
                                  Pending
                                </Label>

                            )}
                          </TableCell>

                          {localUser?.role === 'admin' && (
                            <TableCell align="right">
                              {selected.length === 0 && (
                                <MemberMoreMenu
                                  id={id}
                                  facilityName={facilityName}
                                  facilityId={facilityId}
                                  email={email}
                                  firstname={firstname}
                                  lastname={lastname}
                                  createdAt={date}
                                  avatarUrl={avatarUrl ?? null}
                                  role={role}
                                  status={status ?? null}
                                  restrictAt={restrictAt ?? 0}
                                  jobTitle={jobTitle ?? ''}
                                  organisation={organisation ?? ''}
                                  organisationId={organisationId ?? ''}
                                  deletedAt={deletedAt ?? null}
                                  subRole={subRole}
                                  restrictTime={restrictTime}
                                  residentIds={residentIds ?? []}
                                  allFacilities={getFacility ?? []}
                                />
                              )}
                            </TableCell>
                          )}
                          {localUser?.role === 'Facility Member' && localUser?.subRole === 0 && (
                            <TableCell align="right">
                              {selected.length === 0 && (
                                <MemberMoreMenu
                                  id={id}
                                  facilityName={facilityName}
                                  facilityId={facilityId}
                                  email={email}
                                  firstname={firstname}
                                  lastname={lastname}
                                  createdAt={date}
                                  avatarUrl={avatarUrl ?? null}
                                  role={role}
                                  status={status ?? null}
                                  restrictAt={restrictAt ?? 0}
                                  jobTitle={jobTitle ?? ''}
                                  organisation={organisation ?? ''}
                                  organisationId={organisationId ?? ''}
                                  deletedAt={deletedAt ?? null}
                                  subRole={subRole}
                                  restrictTime={restrictTime}
                                />
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound &&
                  (NotFound ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                          <UserNotFound />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {image && <Lightbox mainSrc={image} onCloseRequest={() => setImage('')} />}
    </Page>
  );
}
